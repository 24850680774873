import React, { useEffect, useState } from 'react';
import { JobDetail } from '../types';
import { Box, Button } from '@mui/material';
import { CloseButton } from '../atoms';
import {
  EasyApplyEmail,
  EasyApplyExperience,
  EasyApplyContact,
  EasyApplyCustom,
  EasyApplyReview,
} from '../components';
import { motion } from 'framer-motion';

interface EasyApplyProps {
  job: JobDetail;
  collapsed: boolean;
  posY?: number;
}

export const EasyApply = (props: EasyApplyProps) => {
  const [current, setCurrent] = useState<number>(0);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(props.collapsed);
  const handleNext = () => {
    console.log('nxt');
    window.scrollTo(0, 0); // dont want to use window. Just want to scroll to top of component
    setCurrent(current + 1);
  };
  const steps = [
    <EasyApplyEmail onComplete={handleNext} />,
    <EasyApplyContact onComplete={handleNext} />,
    <EasyApplyExperience onComplete={handleNext} />,
    <EasyApplyCustom onComplete={handleNext} />,
    <EasyApplyReview />,
  ];

  useEffect(() => {
    console.log('use', props.collapsed);
    if (props.collapsed !== isCollapsed) {
      setIsCollapsed(false);
    }
  }, [props.collapsed]);

  return (
    <>
      <Box sx={{ width: '100%', margin: 'auto', mx: { xs: 0, lg: 10 } }}>
        <motion.div layout transition={{ layout: { type: 'ease' } }}>
          {!isCollapsed && (
            <Box sx={{ mx: { xs: 2, sm: 22 }, mt: 20 }}>
              <CloseButton onclick={() => setIsCollapsed(true)} />
            </Box>
          )}
          {isCollapsed && (
            <motion.div layout>
              <Button
                sx={{
                  borderRadius: 2,
                  minHeight: 30,
                  width: '90vw',
                  lineHeight: 2.5,
                  textAlign: 'center',
                  fontSize: 20,
                }}
                variant="outlined"
                onClick={() => setIsCollapsed(false)}
              >
                Rx Easy Apply
              </Button>
            </motion.div>
          )}
          {!isCollapsed && (
            <motion.div layout>
              <Box
                sx={{
                  borderRadius: '10px 10px 0px 0px',
                  backgroundColor: 'white',
                  border: '2px solid #78AA58',
                  maxHeight: '50vh',
                  mx: { xs: 2, sm: 20 },
                  pt: 3,
                  overflowY: 'scroll',
                }}
              >
                <Box
                  sx={{
                    justifyContent: 'center',
                    textAlign: 'center',
                    mx: 'auto',
                    maxWidth: '500px',
                  }}
                >
                  {steps[current]}
                </Box>
              </Box>
            </motion.div>
          )}
        </motion.div>
      </Box>
    </>
  );
};
