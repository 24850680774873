import React from "react";
import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import { CustomIcon } from "../atoms";

export interface AnalyticsCardProps {
  title: string;
  subtitle: string;
  value: number | undefined;
  description: string;
}

export const AnalyticsCard = (props: AnalyticsCardProps): JSX.Element => {
  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        justifyContent="start"
        sx={{ position: "relative", top: -40, left: -30 }}
      >
        <Tooltip title={props.description} placement="top-start">
          <span>
            <CustomIcon name="info" fontSize="small" />
          </span>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sx={{ position: "relative", top: -20 }}>
        <Stack>
          <Typography
            color="text.secondary"
            sx={{ fontSize: 16, fontWeight: "bold" }}
          >
            {props.title}
          </Typography>
          <Typography sx={{ fontSize: 36 }}>{props.value}</Typography>
          <Typography sx={{ fontSize: 16 }}>{props.subtitle}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
