import React from 'react';
import { Box, Typography } from '@mui/material';

export const Complete = () => {
  return (
    <Box sx={{ textAlign: 'center', pt: 10 }}>
      <Typography variant="h1" sx={{ fontSize: 28, fontWeight: 'bold', pb: 3 }}>
        You're all set!
      </Typography>
      <Typography variant="h2" sx={{ fontSize: 28, fontWeight: 'bold' }}>
        Thank you for sharing your experiences!
      </Typography>
    </Box>
  );
};
