import React, { useEffect, useState } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { KeyboardBackspace } from '@mui/icons-material';
import useAPI from '../../utils/useApi';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { APIRequests, ListResource } from '../../config/api';
import {
  collectionCardLayout,
  collectionNavItems,
} from '../../config/layouts/collectionSettings';
import { RequestInput } from '../../types/config';
import { Collection, CollectionLink } from '../../types/collection';
import {
  useGlobalState,
  useCurrentCompany,
} from '../../utils/GlobalStateProvider';
import {
  PageNavigation,
  PageNavItem,
  SettingsCard,
  SCardProps,
} from '../../components';
import {
  CustomIcon,
  Loading,
  JamyrCard,
  SelectOption,
  PopupSelectList,
  OptionToggles,
} from '../../atoms';
import DraggableList from '../../atoms/DraggableList';
import { toast } from 'react-toastify';
import { Testimonial } from '../../types/testimonial';

export const CollectionDetail = (): JSX.Element => {
  const collectionGet = useAPI(APIRequests.CollectionGet);
  const collectionUpdate = useAPI(APIRequests.CollectionUpdate);
  const testimonialsList = useAPI<ListResource>(APIRequests.TestimonialsList);
  const collectionLinkList = useAPI<ListResource>(
    APIRequests.CollectionLinksList
  );
  const collectionLinkUpdate = useAPI<ListResource>(
    APIRequests.CollectionLinkUpdate
  );
  const collectionLinkDelete = useAPI<CollectionLink | unknown>(
    APIRequests.CollectionLinkDelete
  );
  const collectionLinkCreate = useAPI(APIRequests.CollectionLinkCreate);
  const [collection, setCollection] = useState<Collection>();
  const [testimonials, setTestimonials] = useState<SelectOption[]>([]);
  const [collectionlinks, setCollectionLinks] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const context = useGlobalState(true);
  const company = useCurrentCompany();
  const { collectionId } = useParams();
  const navigate = useNavigate();

  const getVideos = () => {
    if (collectionId) {
      const videosreq: RequestInput = {
        pathParams: {
          company: company || context.state.company,
          collection_id: collectionId,
        },
      };
      collectionLinkList.execute(videosreq);
      const videoreqfull: RequestInput = {
        pathParams: {
          company: company || context.state.company,
        },
        queryParams: { status: 'approved' },
      };
      testimonialsList.execute(videoreqfull);
    }
  };

  useEffect(() => {
    if (context.state.company && collectionId) {
      const req: RequestInput = {
        pathParams: {
          company: company || context.state.company,
          id: collectionId,
        },
      };
      collectionGet.execute(req);
      getVideos();
    }
  }, [context.state.company]);

  useEffect(() => {
    if (collectionGet.data && !collection) {
      const c = collectionGet.data as Collection;
      setCollection(c);
    }
  }, [collectionGet.data]);

  useEffect(() => {
    if (testimonialsList.data) {
      let allVideos: SelectOption[] = [];
      const videos = testimonialsList.data.records as Testimonial[];
      // TODO: remove testimonials that are already linked to collection
      videos.forEach((v) => {
        allVideos.push({
          id: String(v.id),
          value: `${v.question_text} (${v.email})`,
        });
      });
      setTestimonials(allVideos);
      setLoading(false);
    }
  }, [testimonialsList.data]);

  useEffect(() => {
    if (collectionLinkList.data) {
      let allLinkData: SelectOption[] = [];
      const links = collectionLinkList.data.records as CollectionLink[];
      links.forEach((l) => {
        allLinkData.push({
          id: String(l.id),
          value: `${l.testimonial.question_text} (${l.testimonial.email})`,
        });
      });
      setCollectionLinks(allLinkData);
      setLoading(false);
    }
  }, [collectionLinkList.data]);

  const updateCollectionField = (field: string, value: string) => {
    let data = collection as any;
    data[field] = value;
    updateCollection(data);
  };

  const getTestimonial = (id: string) => {
    const link = collectionLinkList.data.records.find(
      (l: CollectionLink) => String(l.id) === id
    );

    if (link) {
      const testimonial = testimonialsList.data.records.find(
        (t: Testimonial) => t.id === link.testimonial_id
      );
      return testimonial;
    }

    return false;
  };

  const handleVideoEdit = (data: SelectOption) => {
    const testimonial = getTestimonial(data.id);
    navigate(`/dashboard/videos/${testimonial.external_id}`);
  };

  const updateCollection = (data: any) => {
    const req: RequestInput = {
      pathParams: {
        company: company || context.state.company,
        id: data.id,
      },
      body: data,
    };
    collectionUpdate.execute(req);
  };

  const createCollectionLink = (data: SelectOption[]) => {
    const customer_id = company || context.state.company;
    if (collectionId) {
      setLoading(true);
      data?.forEach((testimonial) => {
        const req: RequestInput = {
          pathParams: { company: customer_id, collection_id: collectionId },
          body: {
            customer_id: customer_id,
            collection_id: collectionId,
            testimonial_id: testimonial.id,
          },
        };
        collectionLinkCreate.execute(req);
      });
      // TODO: hack to avoid displaying multiple success messages for now.
      setTimeout(() => {
        toast('🦄 Questions Linked to Campaign!');
        getVideos();
      }, 3000);
    }
  };

  useEffect(() => {
    if (collectionUpdate.data) {
      toast('🦄 Collection Updated!');
    }
    if (collectionUpdate.error) {
      toast.error(String(collectionUpdate.error));
    }
  }, [collectionUpdate.data, collectionUpdate.error]);

  useEffect(() => {
    if (collectionLinkDelete.data) {
      getVideos();
      toast('🦄 Video Removed!');
    }
    if (collectionLinkDelete.error) {
      toast.error(String(collectionLinkDelete.error));
      setLoading(false);
    }
  }, [collectionLinkDelete.data, collectionLinkDelete.error]);

  useEffect(() => {
    if (collectionLinkUpdate.data) {
      toast('🦄 Video Order Updated!');
      setLoading(false);
    }
    if (collectionLinkUpdate.error) {
      toast.error(String(collectionLinkUpdate.error));
      setLoading(false);
    }
  }, [collectionLinkUpdate.data, collectionLinkUpdate.error]);

  const renderCard = (item: SCardProps) => {
    item.data = collection;
    item.handleUpdate = updateCollection;
    if (collection) {
      if (item.title === 'Videos') {
        const handleRemove = (recordid: number) => {
          if (collectionlinks) {
            const req: RequestInput = {
              pathParams: {
                company: collection.customer_id,
                campaign_id: collection.id,
                id: recordid,
              },
            };
            setLoading(true);
            collectionLinkDelete.execute(req);
          }
        };

        const handleResort = (data: SelectOption[] | any) => {
          const payload: { [name: string]: string | any }[] = [];
          data.forEach((item: SelectOption, idx: number) => {
            payload.push({ id: item.id, position: idx });
          });
          const req: RequestInput = {
            pathParams: {
              company: collection.customer_id,
              collection_id: collection.id,
            },
            body: payload,
          };
          setLoading(true);
          collectionLinkUpdate.execute(req);
        };

        const cardProps = {
          title: item.title,
          embedded: true,
          children: (
            <>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <PopupSelectList
                    options={testimonials}
                    onSelect={createCollectionLink}
                    title="Add Videos"
                    subtitle="Add videos to this collection"
                  />
                  <DraggableList
                    items={collectionlinks}
                    onDragEnd={(data: { [name: string]: string }[] | any) =>
                      handleResort(data)
                    }
                    onItemRemove={handleRemove}
                    onVideoEdit={handleVideoEdit}
                  />
                </>
              )}
            </>
          ),
        };
        return <JamyrCard {...cardProps} />;
      } else if (item.title === 'Page Format') {
        const onChange = (field: string, value: string) => {
          updateCollectionField(field, value);
        };
        const pageOptions: SelectOption[] = [
          { id: 'jamcoll', value: 'Tile' },
          { id: 'video-guide', value: 'Guide' },
        ];

        const selection = pageOptions.filter(
          (item) => item.id === collection.page_style
        )[0];

        return (
          <OptionToggles
            options={pageOptions}
            updatingField="page_style"
            currentvalue={selection?.id}
            changehandler={onChange}
          />
        );
      } else {
        return <SettingsCard {...item} />;
      }
    } else {
      return <></>;
    }
  };

  const embedCode = () => {
    if (collection) {
      return `
        <script async src="https://widget.jamyr.com/js/pub/application.js" id="jamyr-app" data-company="${collection.company_name}" data-dataid="${collection.external_id}" data-component="collection" data-containerid=""></script>
      `;
    } else {
      return '';
    }
  };

  const websiteEmbed = () => {
    if (collection) {
      return `
        <div id="jamyr_collection_embed" style="display: flex;justify-content: center;"><script async src="https://widget.jamyr.com/js/pub/application.js" id="jamyr-app" data-company="${collection.company_name}" data-dataid="${collection.external_id}" data-component="collection" data-containerid="jamyr_collection_embed"></script> </div>
      `;
    } else {
      return '';
    }
  };

  return (
    <>
      {collection && (
        <Grid container sx={{ ml: { xs: 0, sm: 5 }, mr: { xs: 0, sm: 5 } }}>
          <Grid
            item
            xs={6}
            sx={{
              pt: { xs: 5, sm: 10 },
              pl: { xs: 2, sm: 7 },
              position: 'relative',
            }}
          >
            <KeyboardBackspace
              sx={{ position: 'absolute', cursor: 'pointer' }}
              onClick={() => navigate(-1)}
            />
            <Typography
              component="span"
              onClick={() => navigate(-1)}
              sx={{ cursor: 'pointer', pl: 3 }}
            >
              Back to collections
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PageNavigation {...collectionNavItems} />
          </Grid>
          <Grid item xs={12} sx={{ pl: 5, pt: 5 }}>
            <Typography
              component="h1"
              sx={{ fontSize: 24, fontWeight: 'bold' }}
            >
              {collection.title}
              <CustomIcon
                name="preview"
                sx={{ position: 'relative', ml: 2, mt: 1, cursor: 'pointer' }}
                onClick={() =>
                  window.open(
                    `https://${collection.company_name}.jamyr.com/collection/${collection.external_id}`,
                    '_blank'
                  )
                }
              />
              <CustomIcon
                name="code"
                sx={{ position: 'relative', ml: 2, mt: 1, cursor: 'pointer' }}
                onClick={() => {
                  navigator.clipboard.writeText(embedCode());
                  toast(
                    '🦄 Code copied! Set the container id parameter to the id of the element you want to load the content under'
                  );
                }}
              />
              <CustomIcon
                name="codeblock"
                sx={{ position: 'relative', ml: 2, mt: 1, cursor: 'pointer' }}
                onClick={() => {
                  navigator.clipboard.writeText(websiteEmbed());
                  toast(
                    '🦄 Code copied! Paste it onto your website and you should be all set!'
                  );
                }}
              />
            </Typography>
            {collection.campaign_id && (
              <Link
                href={`/dashboard/campaigns/${collection.campaign_id}`}
                sx={{
                  textDecoration: 'none',
                  color: 'info.main',
                }}
              >
                View Campaign
              </Link>
            )}
          </Grid>
          {collectionNavItems.map((item: PageNavItem) => {
            return (
              <Grid
                item
                key={item.link}
                xs={12}
                id={item.link}
                sx={{ ml: { xs: 2, sm: 5 }, mt: 5, mr: { xs: 0, sm: 10 } }}
              >
                {renderCard(collectionCardLayout[item.title])}
              </Grid>
            );
          })}
        </Grid>
      )}
      {!collection && <Loading fullpage={true} />}
    </>
  );
};
