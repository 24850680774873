import React from 'react';
import { Autocomplete, TextField, TextFieldProps } from '@mui/material';

export type JSelectProps = TextFieldProps & {
  options?: { [label: string]: string }[];
  onChange?: (data: any) => void;
};

export const JamyrSelectField = (props: JSelectProps): JSX.Element => {
  const selectedValue = props.value as { [label: string]: string };
  const pOptions = props.options as { [label: string]: string }[];
  return (
    <>
      <Autocomplete
        id={props.name}
        onChange={(event, newValue) => {
          props.onChange && props.onChange(newValue);
        }}
        value={selectedValue}
        options={pOptions}
        getOptionLabel={(option) => option?.label}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        renderInput={(params) => <TextField {...params} label={props.label} />}
        disableClearable
        filterSelectedOptions
        sx={props.sx || { minWidth: 120 }}
      />
    </>
  );
};
