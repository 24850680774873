import React, { useEffect, useState } from 'react';
import { Loading } from '../atoms';
import { Testimonial, TranscriptionLine } from '../types/testimonial';
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { toast } from 'react-toastify';
import {
  useTranscriptionGet,
  useTranscriptionUpdate,
} from '../config/api/testimonials';
import { useCustomerSettingGet } from '../config/api/customer';
import { transcriptionLanguages } from '../config';

export type TranscriptionProps = {
  testimonial: Testimonial;
};

export const TranscriptionUpdate = (props: TranscriptionProps): JSX.Element => {
  const [transcription, setTranscription] = useState<TranscriptionLine[]>();
  const [language, setLanguage] = useState<string>('en-US');
  const settings = useCustomerSettingGet({ id: props.testimonial.customer_id });
  const transGet = useTranscriptionGet(
    {
      company: props.testimonial.customer_id,
      id: props.testimonial.id,
    },
    { language: language.split('-')[0] }
  );
  const transSet = useTranscriptionUpdate({
    company: props.testimonial.customer_id,
    id: props.testimonial.id,
    language: language.split('-')[0],
  });

  useEffect(() => {
    setTranscription(transGet.data);
  }, [transGet.data]);

  useEffect(() => {
    if (transSet.data) {
      toast('🦄 Transcription Updated');
    }
    if (transSet.error) {
      toast.error(String(transSet.error));
    }
  }, [transSet.data, transSet.error]);

  const updateTranscription = () => {
    if (transcription) {
      transSet.mutate(transcription);
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent,
    targetElem: HTMLInputElement,
    index: number
  ) => {
    // TODO: handle ArrowUp & ArrowDown to nav to next input block
    // TODO: handle ctrl + z
    e.preventDefault();
    // const isCtrlCmd = e.ctrlKey || e.metaKey; // ctrl or cmd for mac
    const position = targetElem.selectionStart;
    const nextPosition = position! + 1;
    const prevPosition = position !== 0 ? position! - 1 : 0;
    if (e.key === 'ArrowRight') {
      if (targetElem.selectionStart === targetElem.value.length) {
        console.log('cursor is at end of element');
      } else {
        targetElem.setSelectionRange(nextPosition, nextPosition);
      }
    } else if (e.key === 'ArrowLeft') {
      if (targetElem.selectionStart === 0) {
      } else {
        targetElem.setSelectionRange(prevPosition, prevPosition);
      }
    } else if (e.key === 'Backspace' && transcription) {
      transcription[index].text = [
        transcription[index].text.slice(
          0,
          targetElem.selectionStart !== 0 ? targetElem.selectionStart! - 1 : 0
        ),
        transcription[index].text.slice(targetElem.selectionEnd || 0),
      ].join('');
      targetElem.value = transcription[index].text;
      targetElem.setSelectionRange(prevPosition, prevPosition);
      setTranscription(transcription);
    } else if (e.key === 'Delete' && transcription) {
      transcription[index].text = [
        transcription[index].text.slice(0, targetElem.selectionStart || 0),
        transcription[index].text.slice(targetElem.selectionEnd! + 1 || 0),
      ].join('');
      targetElem.value = transcription[index].text;
      targetElem.setSelectionRange(position, position);
      setTranscription(transcription);
    } else if (transcription && e.key.length === 1) {
      transcription[index].text = [
        transcription[index].text.slice(0, targetElem.selectionStart || 0),
        e.key,
        transcription[index].text.slice(targetElem.selectionEnd || 0),
      ].join('');
      targetElem.value = transcription[index].text;
      targetElem.setSelectionRange(nextPosition, nextPosition);
      setTranscription(transcription);
    }
  };
  return (
    <>
      {settings && (
        <>
          <ToggleButtonGroup
            sx={{ px: 1 }}
            exclusive
            //@ts-ignore
            onChange={(e) => console.log(e.target.value)}
          >
            <ToggleButton
              sx={{ textTransform: 'none' }}
              value="en-US"
              selected={language === 'en-US'}
              onClick={() => setLanguage('en-US')}
            >
              English
            </ToggleButton>
            {settings.data?.languages &&
              settings.data?.languages.split(';').map((cl: string) => {
                const lang = transcriptionLanguages.filter((l) => l.id === cl);
                return (
                  <ToggleButton
                    sx={{ textTransform: 'none' }}
                    key={lang[0].id}
                    value={lang[0].id}
                    selected={language === lang[0].id}
                    onClick={() => setLanguage(lang[0].id)}
                  >
                    {lang[0].value}
                  </ToggleButton>
                );
              })}
          </ToggleButtonGroup>

          {transGet.isLoading ? (
            <Loading />
          ) : transcription && transcription.length > 0 ? (
            <>
              <div
                style={{
                  border: 'solid black 1px',
                  borderRadius: '5px',
                  padding: '5px',
                }}
              >
                {transcription.map((line: TranscriptionLine, i: number) => {
                  return (
                    <input
                      key={line.keyindex}
                      id={`${line.keyindex}`}
                      value={line.text}
                      style={{
                        border: 'none',
                        outline: 0,
                        cursor: 'text',
                        width: '100%',
                      }}
                      onKeyDown={(e) => handleKeyDown(e, e.currentTarget, i)}
                    />
                  );
                })}
              </div>
              <Button onClick={updateTranscription} sx={{ mt: 5 }}>
                Save
              </Button>
            </>
          ) : (
            <p> Transcription not available </p>
          )}
        </>
      )}
    </>
  );
};
