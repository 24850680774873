import React from "react";
import { SvgIcon } from "@mui/material";
import { IconProps } from "../CustomIcon";

export function FolderIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      width="8"
      height="8"
      viewBox="0 0 48 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7028 5.78745C21.4726 5.16109 21.0631 4.61638 20.5253 4.22128C19.9875 3.82618 19.3453 3.59818 18.6788 3.56574C14.7308 3.34659 10.7727 3.38439 6.82963 3.67888C6.02937 3.74138 5.27611 4.0816 4.70013 4.64067C4.12414 5.19975 3.76165 5.94255 3.67534 6.74059C2.51992 16.8103 2.13249 26.9143 3.33934 37.0012C3.75077 40.4297 6.55192 42.9155 9.90849 43.2823C19.3782 44.3109 28.7142 44.2869 38.1839 43.2549C39.7877 43.0765 41.2845 42.3625 42.4323 41.2284C43.5802 40.0942 44.312 38.6061 44.5096 37.0046C45.2571 30.7646 45.4525 24.3737 44.9896 16.5326C44.8976 14.9617 44.2418 13.4767 43.1427 12.3505C42.0436 11.2243 40.575 10.5325 39.0068 10.4023C34.3988 10.0183 31.1931 9.99774 25.5291 9.87774C24.8422 9.86315 24.1756 9.64212 23.616 9.24342C23.0565 8.84472 22.63 8.28681 22.3919 7.64231L21.7062 5.79088L21.7028 5.78745Z"
        fill={props.fill || "none"}
        stroke={props.hex || "white"}
        strokeWidth="5.14286"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export function EditIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      width="8"
      height="8"
      viewBox="0 0 48 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4286 3C30.1118 3 35.5622 5.25765 39.5809 9.27628C43.5995 13.2949 45.8571 18.7454 45.8571 24.4286C45.8571 30.1118 43.5995 35.5622 39.5809 39.5809C35.5622 43.5995 30.1118 45.8571 24.4286 45.8571C18.7454 45.8571 13.2949 43.5995 9.27628 39.5809C5.25765 35.5622 3 30.1118 3 24.4286C3 18.7454 5.25765 13.2949 9.27628 9.27628C13.2949 5.25765 18.7454 3 24.4286 3V3Z"
        fill={props.fill || "none"}
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5285 33.073L34.0806 22.1353C36.0003 20.3174 35.8521 17.0994 33.752 15.0351C31.7038 13.0207 28.5787 12.8814 26.7697 14.7261L15.5337 26.1961C15.5337 26.1961 18.023 27.1854 19.7731 28.9051C21.5231 30.6248 22.5285 33.073 22.5285 33.073Z"
        fill={props.fill || "none"}
        stroke={props.hex || "black"}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6298 35.1124L22.5228 33.082C22.5228 33.082 21.5175 30.6337 19.7692 28.9122C18.0209 27.1908 15.5334 26.1979 15.5334 26.1979L13.4583 33.9302C13.278 34.6052 13.953 35.2874 14.6298 35.1124Z"
        fill={props.fill || "none"}
        stroke={props.hex || "black"}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export function SettingIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      width="8"
      height="8"
      viewBox="0 0 48 47"
      sx={{ cursor: "pointer" }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.29153 35.2046C3.56381 37.6165 4.64517 39.865 6.3593 41.5835C8.07342 43.3019 10.3192 44.389 12.7304 44.6674C16.399 45.0788 20.1601 45.4286 24.0001 45.4286C27.8401 45.4286 31.6047 45.0788 35.2698 44.6674C37.681 44.389 39.9268 43.3019 41.6409 41.5835C43.355 39.865 44.4364 37.6165 44.7087 35.2046C45.0995 31.56 45.4287 27.816 45.4287 24C45.4287 20.184 45.0995 16.44 44.7087 12.792C44.4364 10.3801 43.355 8.13156 41.6409 6.41308C39.9268 4.69459 37.681 3.60752 35.2698 3.32913C31.6012 2.92455 27.8401 2.57141 24.0001 2.57141C20.1601 2.57141 16.3955 2.92113 12.7304 3.33255C10.3192 3.61095 8.07342 4.69802 6.3593 6.4165C4.64517 8.13499 3.56381 10.3835 3.29153 12.7954C2.90068 16.4366 2.57153 20.1806 2.57153 24C2.57153 27.8194 2.90068 31.56 3.29153 35.2046Z"
        fill="#D7E0FF"
        stroke="#4147D5"
        strokeWidth="5.14286"
      />
      <path
        d="M13.7144 15.4286H27.4286"
        stroke="#4147D5"
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.2858 32.5714H20.5715"
        stroke="#4147D5"
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.1429 20.5714C30.5068 20.5714 31.8149 20.0295 32.7794 19.0651C33.7439 18.1006 34.2857 16.7925 34.2857 15.4285C34.2857 14.0645 33.7439 12.7564 32.7794 11.792C31.8149 10.8275 30.5068 10.2856 29.1429 10.2856C27.7789 10.2856 26.4708 10.8275 25.5063 11.792C24.5418 12.7564 24 14.0645 24 15.4285C24 16.7925 24.5418 18.1006 25.5063 19.0651C26.4708 20.0295 27.7789 20.5714 29.1429 20.5714Z"
        fill="white"
        stroke="#4147D5"
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8572 37.7143C17.4932 37.7143 16.1851 37.1725 15.2207 36.208C14.2562 35.2435 13.7144 33.9354 13.7144 32.5714C13.7144 31.2075 14.2562 29.8994 15.2207 28.9349C16.1851 27.9704 17.4932 27.4286 18.8572 27.4286C20.2212 27.4286 21.5293 27.9704 22.4938 28.9349C23.4582 29.8994 24.0001 31.2075 24.0001 32.5714C24.0001 33.9354 23.4582 35.2435 22.4938 36.208C21.5293 37.1725 20.2212 37.7143 18.8572 37.7143Z"
        fill="white"
        stroke="#4147D5"
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export function PlayIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 48 48"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0001 2.57141C29.6833 2.57141 35.1338 4.82906 39.1524 8.84769C43.171 12.8663 45.4287 18.3168 45.4287 24C45.4287 29.6832 43.171 35.1336 39.1524 39.1523C35.1338 43.1709 29.6833 45.4286 24.0001 45.4286C18.3169 45.4286 12.8665 43.1709 8.84782 39.1523C4.82918 35.1336 2.57153 29.6832 2.57153 24C2.57153 18.3168 4.82918 12.8663 8.84782 8.84769C12.8665 4.82906 18.3169 2.57141 24.0001 2.57141V2.57141Z"
        stroke={props.hex || "black"}
        fill={props.fill || "none"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1138 35.7488C25.4274 32.9426 35.6787 27.0643 35.6787 23.5C35.6787 19.9357 25.4274 14.0574 20.1138 11.2512C18.6811 10.4931 17 11.5435 17 13.163V33.8348C17 35.4565 18.6811 36.5069 20.1138 35.7488Z"
        stroke={props.hex || "black"}
        fill={props.fill || "none"}
        strokeWidth="5.14286"
      />
    </SvgIcon>
  );
}

export function AnalyticsIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 48 48"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.29153 35.2046C3.56381 37.6165 4.64517 39.865 6.3593 41.5835C8.07342 43.3019 10.3192 44.389 12.7304 44.6674C16.399 45.0788 20.1601 45.4286 24.0001 45.4286C27.8401 45.4286 31.6047 45.0788 35.2698 44.6674C37.681 44.389 39.9268 43.3019 41.6409 41.5835C43.355 39.865 44.4364 37.6165 44.7087 35.2046C45.0995 31.56 45.4287 27.816 45.4287 24C45.4287 20.184 45.0995 16.44 44.7087 12.792C44.4364 10.3801 43.355 8.13156 41.6409 6.41308C39.9268 4.69459 37.681 3.60752 35.2698 3.32913C31.6012 2.92455 27.8401 2.57141 24.0001 2.57141C20.1601 2.57141 16.3955 2.92113 12.7304 3.33255C10.3192 3.61095 8.07342 4.69802 6.3593 6.4165C4.64517 8.13499 3.56381 10.3835 3.29153 12.7954C2.90068 16.4366 2.57153 20.1806 2.57153 24C2.57153 27.8194 2.90068 31.56 3.29153 35.2046Z"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        fill={props.fill || "none"}
      />
      <path
        d="M35 17L25.0981 26.9019L21.3756 23.1794L13 31.555"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
    </SvgIcon>
  );
}

export function CollectionIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 48 48"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.29129 35.2046C3.56356 37.6165 4.64493 39.865 6.35905 41.5835C8.07318 43.3019 10.3189 44.389 12.7301 44.6674C16.3987 45.0788 20.1599 45.4286 23.9999 45.4286C27.8399 45.4286 31.6044 45.0788 35.2696 44.6674C37.6808 44.389 39.9265 43.3019 41.6407 41.5835C43.3548 39.865 44.4362 37.6165 44.7084 35.2046C45.0993 31.56 45.4284 27.816 45.4284 24C45.4284 20.184 45.0993 16.44 44.7084 12.792C44.4362 10.3801 43.3548 8.13156 41.6407 6.41308C39.9265 4.69459 37.6808 3.60752 35.2696 3.32913C31.601 2.92455 27.8399 2.57141 23.9999 2.57141C20.1599 2.57141 16.3953 2.92113 12.7301 3.33255C10.3189 3.61095 8.07318 4.69802 6.35905 6.4165C4.64493 8.13499 3.56356 10.3835 3.29129 12.7954C2.90043 16.4366 2.57129 20.1806 2.57129 24C2.57129 27.8194 2.90043 31.56 3.29129 35.2046Z"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        fill={props.fill || "none"}
      />
      <path
        d="M31 34V27"
        stroke={props.hex || "black"}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M13.1348 19.0898C13.2346 20.0133 13.975 20.7537 14.8968 20.8569C15.5807 20.9334 16.2829 21 17 21C17.7155 21 18.4193 20.9334 19.1032 20.8569C20.025 20.7554 20.7671 20.0133 20.8669 19.0915C20.9384 18.4093 21 17.7105 21 17C21 16.2879 20.9384 15.589 20.8669 14.9085C20.7671 13.9867 20.025 13.2446 19.1032 13.1414C18.4053 13.0553 17.7031 13.0081 17 13C16.2845 13 15.5807 13.0666 14.8968 13.1414C13.975 13.2446 13.2329 13.9867 13.1331 14.9085C13.0616 15.589 13 16.2879 13 17C13 17.7121 13.0616 18.411 13.1331 19.0915L13.1348 19.0898Z"
        stroke={props.hex || "black"}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M27.1348 19.0898C27.2346 20.0133 27.975 20.7537 28.8968 20.8569C29.5807 20.9334 30.2829 21 31 21C31.7155 21 32.4193 20.9334 33.1032 20.8569C34.025 20.7554 34.7671 20.0133 34.8669 19.0915C34.9384 18.4093 35 17.7105 35 17C35 16.2879 34.9384 15.589 34.8669 14.9085C34.7671 13.9867 34.025 13.2446 33.1032 13.1414C32.4053 13.0553 31.7031 13.0081 31 13C30.2845 13 29.5807 13.0666 28.8968 13.1414C27.975 13.2446 27.2329 13.9867 27.1331 14.9085C27.0616 15.589 27 16.2879 27 17C27 17.7121 27.0616 18.411 27.1331 19.0915L27.1348 19.0898Z"
        stroke={props.hex || "black"}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M13.1348 33.0898C13.2346 34.0133 13.975 34.7537 14.8968 34.8569C15.5807 34.9334 16.2829 35 17 35C17.7155 35 18.4193 34.9334 19.1032 34.8569C20.025 34.7554 20.7671 34.0133 20.8669 33.0915C20.9384 32.4093 21 31.7105 21 31C21 30.2879 20.9384 29.589 20.8669 28.9085C20.7671 27.9867 20.025 27.2446 19.1032 27.1414C18.4053 27.0553 17.7031 27.0081 17 27C16.2845 27 15.5807 27.0666 14.8968 27.1414C13.975 27.2446 13.2329 27.9867 13.1331 28.9085C13.0616 29.589 13 30.2879 13 31C13 31.7121 13.0616 32.411 13.1331 33.0915L13.1348 33.0898Z"
        stroke={props.hex || "black"}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M27.5 30.5L34.5 30.5"
        stroke={props.hex || "black"}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
    </SvgIcon>
  );
}

export function LinkIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 48 48"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9999 45.4286C29.6831 45.4286 35.1335 43.1709 39.1521 39.1523C43.1708 35.1336 45.4284 29.6832 45.4284 24C45.4284 18.3168 43.1708 12.8663 39.1521 8.84769C35.1335 4.82906 29.6831 2.57141 23.9999 2.57141C18.3167 2.57141 12.8662 4.82906 8.84757 8.84769C4.82894 12.8663 2.57129 18.3168 2.57129 24C2.57129 29.6832 4.82894 35.1336 8.84757 39.1523C12.8662 43.1709 18.3167 45.4286 23.9999 45.4286V45.4286Z"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M20.751 23.7066C20.2632 23.219 19.6185 22.9198 18.9312 22.862C18.2438 22.8041 17.5582 22.9915 16.9958 23.3907L16.4914 23.7497C15.4088 24.5177 14.4603 25.459 13.684 26.5356V26.5356C13.1809 27.2334 12.9426 28.0874 13.0117 28.9448C13.0808 29.8022 13.4527 30.607 14.0609 31.2153L16.784 33.9365C18.0405 35.1931 20.0222 35.3546 21.4636 34.3135C22.5406 33.5398 23.4812 32.5903 24.2495 31.5078L24.6444 30.9532"
        stroke={props.hex || "black"}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M13.684 26.5356C13.1809 27.2333 12.9426 28.0873 13.0117 28.9447C13.0808 29.8021 13.4527 30.607 14.0609 31.2152L16.784 33.9365C18.0405 35.193 20.0222 35.3546 21.4636 34.3134C22.5406 33.5398 23.4812 32.5902 24.2495 31.5078L24.6444 30.9531C25.0335 30.4045 25.216 29.736 25.1595 29.0658C25.1029 28.3956 24.8111 27.767 24.3357 27.2913V27.2913L20.751 23.7048C20.2632 23.2172 19.6185 22.918 18.9312 22.8601C18.2438 22.8023 17.5582 22.9896 16.9958 23.3889L16.4914 23.7479C15.4088 24.5159 14.4603 25.4571 13.684 26.5338V26.5338V26.5356Z"
        stroke={props.hex || "black"}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M33.9376 16.7832L31.2164 14.0619C29.9598 12.8054 27.9781 12.6438 26.5367 13.6849C25.4597 14.4586 24.5191 15.4082 23.7508 16.4906L23.3918 16.9932C22.9921 17.5558 22.8045 18.2418 22.8623 18.9295C22.9202 19.6172 23.2197 20.2623 23.7078 20.7502L27.2924 24.3349C28.2743 25.3168 29.8234 25.4478 30.9543 24.6436L31.5108 24.2487C32.5927 23.4806 33.5406 22.5393 34.3164 21.4628C34.8194 20.7651 35.0577 19.9111 34.9886 19.0537C34.9196 18.1963 34.5477 17.3914 33.9394 16.7832H33.9376Z"
        stroke={props.hex || "black"}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M27.3424 20.6569L20.6738 27.3254"
        stroke={props.hex || "black"}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
    </SvgIcon>
  );
}

export function DeleteIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 48 48"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.29129 35.2046C3.56356 37.6165 4.64493 39.865 6.35905 41.5835C8.07318 43.3019 10.3189 44.389 12.7301 44.6674C16.3987 45.0788 20.1599 45.4286 23.9999 45.4286C27.8399 45.4286 31.6044 45.0788 35.2696 44.6674C37.6808 44.389 39.9265 43.3019 41.6407 41.5835C43.3548 39.865 44.4362 37.6165 44.7084 35.2046C45.0993 31.56 45.4284 27.816 45.4284 24C45.4284 20.184 45.0993 16.44 44.7084 12.792C44.4362 10.3801 43.3548 8.13156 41.6407 6.41308C39.9265 4.69459 37.6808 3.60752 35.2696 3.32913C31.601 2.92455 27.8399 2.57141 23.9999 2.57141C20.1599 2.57141 16.3953 2.92113 12.7301 3.33255C10.3189 3.61095 8.07318 4.69802 6.35905 6.4165C4.64493 8.13499 3.56356 10.3835 3.29129 12.7954C2.90043 16.4366 2.57129 20.1806 2.57129 24C2.57129 27.8194 2.90043 31.56 3.29129 35.2046Z"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        fill={props.fill || "none"}
      />
      <path
        d="M32.5711 24H15.4282"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        fill={props.fill || "none"}
      />
    </SvgIcon>
  );
}

export function AddIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 48 48"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.29129 35.2046C3.56356 37.6165 4.64493 39.865 6.35905 41.5835C8.07318 43.3019 10.3189 44.389 12.7301 44.6674C16.3987 45.0788 20.1599 45.4286 23.9999 45.4286C27.8399 45.4286 31.6044 45.0788 35.2696 44.6674C37.6808 44.389 39.9265 43.3019 41.6407 41.5835C43.3548 39.865 44.4362 37.6165 44.7084 35.2046C45.0993 31.56 45.4284 27.816 45.4284 24C45.4284 20.184 45.0993 16.44 44.7084 12.792C44.4362 10.3801 43.3548 8.13156 41.6407 6.41308C39.9265 4.69459 37.6808 3.60752 35.2696 3.32913C31.601 2.92455 27.8399 2.57141 23.9999 2.57141C20.1599 2.57141 16.3953 2.92113 12.7301 3.33255C10.3189 3.61095 8.07318 4.69802 6.35905 6.4165C4.64493 8.13499 3.56356 10.3835 3.29129 12.7954C2.90043 16.4366 2.57129 20.1806 2.57129 24C2.57129 27.8194 2.90043 31.56 3.29129 35.2046Z"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        fill={props.fill || "none"}
      />
      <path
        d="M24 15.4286V32.5714"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        fill={props.fill || "none"}
      />
      <path
        d="M32.5711 24H15.4282"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        fill={props.fill || "none"}
      />
    </SvgIcon>
  );
}

export function DownloadIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 48 48"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.2753 32.7875C39.2844 32.0126 41.0467 31.0183 42.4387 29.7943C44.0779 28.3679 45.1248 26.3804 45.3741 24.2218C45.6234 22.0632 45.057 19.8894 43.7861 18.1269C42.503 16.3486 40.6164 15.0982 38.4787 14.6092C38.3315 14.5658 38.1996 14.4815 38.0983 14.3662C37.9969 14.251 37.9303 14.1093 37.9061 13.9578C37.6348 11.0944 36.3781 8.41389 34.3507 6.37375C32.2908 4.31583 29.5989 3.01005 26.7075 2.66619C23.8161 2.32233 20.893 2.96035 18.4079 4.47775C15.9416 5.982 14.0686 8.28966 13.1039 11.0126C13.0404 11.1407 12.9413 11.2477 12.8184 11.3207C12.6955 11.3937 12.5541 11.4296 12.4113 11.424C11.0296 11.5166 9.67872 11.8732 8.43758 12.4766C7.2016 13.0767 6.09507 13.913 5.18043 14.9383C4.27174 15.9522 3.57493 17.1376 3.13098 18.4247C2.68703 19.7118 2.5049 21.0747 2.59529 22.4332C2.78386 25.1692 4.06958 27.72 6.17815 29.5269C7.29243 30.4835 8.65358 31.3132 10.193 32.016"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M11.9209 11.4343C15.9975 11.4343 19.642 14.1223 20.794 16.5772"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M24 26.8938V45.3327"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M16.9609 39.2229C19.1004 41.9555 20.5575 43.2275 22.7278 44.8903C23.0902 45.1762 23.5382 45.3317 23.9998 45.3317C24.4613 45.3317 24.9094 45.1762 25.2718 44.8903C27.4421 43.2275 28.8992 41.9555 31.0352 39.2229"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
    </SvgIcon>
  );
}

export function UploadIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 48 48"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.2536 31.9577C40.4536 31.3509 41.5233 30.6446 42.4387 29.8423C44.0783 28.4153 45.1252 26.4269 45.3739 24.2675C45.6225 22.1081 45.055 19.9338 43.7827 18.1715C42.4996 16.3932 40.613 15.1428 38.4753 14.6537C38.3281 14.6103 38.1962 14.5261 38.0948 14.4108C37.9935 14.2955 37.9269 14.1539 37.9027 14.0023C37.6314 11.1389 36.3747 8.45844 34.3473 6.41831C32.2873 4.36038 29.5955 3.05461 26.704 2.71075C23.8126 2.36689 20.8896 3.00491 18.4044 4.52231C15.9382 6.02656 14.0652 8.33422 13.1004 11.0572C13.037 11.1853 12.9379 11.2923 12.815 11.3653C12.6921 11.4383 12.5507 11.4742 12.4079 11.4686C11.0262 11.5612 9.6753 11.9177 8.43416 12.5212C7.19818 13.1213 6.09165 13.9576 5.17701 14.9829C4.26832 15.9968 3.57151 17.1821 3.12756 18.4692C2.68361 19.7563 2.50148 21.1192 2.59187 22.4777C2.78044 25.2172 4.06616 27.768 6.17473 29.5715C7.13035 30.3756 8.17623 31.066 9.2913 31.6286"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M11.9209 11.4857C15.9975 11.4857 19.642 14.1703 20.794 16.6286"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M24 45.3806V26.9418"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M31.0386 33.0514C28.8992 30.3188 27.4421 29.0468 25.2718 27.384C24.9094 27.0981 24.4613 26.9426 23.9998 26.9426C23.5382 26.9426 23.0901 27.0981 22.7278 27.384C20.5575 29.0468 19.1004 30.3188 16.9644 33.0514"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
    </SvgIcon>
  );
}

export function QuestionIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 48 48"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.29129 35.2046C3.56356 37.6165 4.64493 39.865 6.35905 41.5835C8.07318 43.3019 10.3189 44.389 12.7301 44.6674C16.3987 45.0788 20.1599 45.4286 23.9999 45.4286C27.8399 45.4286 31.6044 45.0788 35.2696 44.6674C37.6808 44.389 39.9265 43.3019 41.6407 41.5835C43.3548 39.865 44.4362 37.6165 44.7084 35.2046C45.0993 31.56 45.4284 27.816 45.4284 24C45.4284 20.184 45.0993 16.44 44.7084 12.792C44.4362 10.3801 43.3548 8.13156 41.6407 6.41308C39.9265 4.69459 37.6808 3.60752 35.2696 3.32913C31.601 2.92455 27.8399 2.57141 23.9999 2.57141C20.1599 2.57141 16.3953 2.92113 12.7301 3.33255C10.3189 3.61095 8.07318 4.69802 6.35905 6.4165C4.64493 8.13499 3.56356 10.3835 3.29129 12.7954C2.90043 16.4366 2.57129 20.1806 2.57129 24C2.57129 27.8194 2.90043 31.56 3.29129 35.2046Z"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        fill={props.fill || "none"}
      />
      <path
        d="M23.9896 26.2594C23.9896 25.0217 24.9976 24.2605 26.7736 23.0708C28.4193 21.9737 29.2902 20.544 28.8993 18.6C28.6952 17.6336 28.2149 16.7473 27.5168 16.0487C26.8188 15.35 25.9329 14.869 24.9668 14.664C24.2414 14.5289 23.4953 14.5541 22.7808 14.7378C22.0662 14.9215 21.4004 15.2592 20.8301 15.7273C20.2598 16.1954 19.7988 16.7825 19.4793 17.4475C19.1597 18.1125 18.9895 18.8394 18.9805 19.5771"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M23.9862 33.3874C23.8725 33.3874 23.7635 33.3423 23.6831 33.2619C23.6028 33.1815 23.5576 33.0725 23.5576 32.9588C23.5576 32.8452 23.6028 32.7362 23.6831 32.6558C23.7635 32.5754 23.8725 32.5303 23.9862 32.5303"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        fill={props.fill || "none"}
      />
      <path
        d="M23.9863 33.3874C24.1 33.3874 24.209 33.3423 24.2894 33.2619C24.3697 33.1815 24.4149 33.0725 24.4149 32.9588C24.4149 32.8452 24.3697 32.7362 24.2894 32.6558C24.209 32.5754 24.1 32.5303 23.9863 32.5303"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        fill={props.fill || "none"}
      />
    </SvgIcon>
  );
}

export function SearchIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 48 48"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9999 45.4286C35.8345 45.4286 45.4284 35.8347 45.4284 24C45.4284 12.1653 35.8345 2.57141 23.9999 2.57141C12.1652 2.57141 2.57129 12.1653 2.57129 24C2.57129 35.8347 12.1652 45.4286 23.9999 45.4286Z"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        fill={props.fill || "none"}
      />
      <path
        d="M22.4912 30.6274C23.5731 30.6589 24.6504 30.473 25.6592 30.0808C26.668 29.6885 27.5879 29.0978 28.3643 28.3436C29.1407 27.5894 29.758 26.6872 30.1795 25.6902C30.6009 24.6932 30.8181 23.6218 30.8181 22.5394C30.8181 21.457 30.6009 20.3856 30.1795 19.3886C29.758 18.3917 29.1407 17.4894 28.3643 16.7352C27.5879 15.9811 26.668 15.3904 25.6592 14.9981C24.6504 14.6058 23.5731 14.4199 22.4912 14.4514C20.3869 14.5127 18.3893 15.3917 16.9225 16.9018C15.4557 18.4119 14.6353 20.4342 14.6353 22.5394C14.6353 24.6446 15.4557 26.6669 16.9225 28.177C18.3893 29.6872 20.3869 30.5661 22.4912 30.6274V30.6274Z"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M33.5997 33.5486L28.2168 28.2651"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
    </SvgIcon>
  );
}

export function CloseIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 48 48"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.29129 35.2046C3.56356 37.6165 4.64493 39.865 6.35905 41.5835C8.07318 43.3019 10.3189 44.389 12.7301 44.6674C16.3987 45.0788 20.1599 45.4286 23.9999 45.4286C27.8399 45.4286 31.6044 45.0788 35.2696 44.6674C37.6808 44.389 39.9265 43.3019 41.6407 41.5835C43.3548 39.865 44.4362 37.6165 44.7084 35.2046C45.0993 31.56 45.4284 27.816 45.4284 24C45.4284 20.184 45.0993 16.44 44.7084 12.792C44.4362 10.3801 43.3548 8.13156 41.6407 6.41308C39.9265 4.69459 37.6808 3.60752 35.2696 3.32913C31.601 2.92455 27.8399 2.57141 23.9999 2.57141C20.1599 2.57141 16.3953 2.92113 12.7301 3.33255C10.3189 3.61095 8.07318 4.69802 6.35905 6.4165C4.64493 8.13499 3.56356 10.3835 3.29129 12.7954C2.90043 16.4366 2.57129 20.1806 2.57129 24C2.57129 27.8194 2.90043 31.56 3.29129 35.2046Z"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        fill={props.fill || "none"}
      />
      <path
        d="M17.938 17.9382L30.0614 30.0617"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        fill={props.fill || "none"}
      />
      <path
        d="M30.0614 17.9382L17.938 30.0617"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        fill={props.fill || "none"}
      />
    </SvgIcon>
  );
}

export function HeartIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 48 48"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9999 10.9131C13.6456 -1.78977 2.71529 7.23766 2.57129 16.9679C2.57129 31.4468 19.9027 43.3371 23.9999 43.3371C28.097 43.3371 45.4284 31.4468 45.4284 16.9645C45.2844 7.23423 34.3541 -1.78977 23.9999 10.9131Z"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
    </SvgIcon>
  );
}
export function ShieldIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 48 48"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.9714 31.5017C40.6719 33.1234 39.7979 34.5829 38.5097 35.6126L28.2857 43.7931C27.07 44.7651 25.5599 45.2947 24.0034 45.2947C22.4469 45.2947 20.9368 44.7651 19.7211 43.7931L9.49369 35.6126C8.20478 34.582 7.33074 33.1212 7.03197 31.4983L3.20569 10.752C3.0231 9.76271 3.06042 8.74538 3.31499 7.77214C3.56957 6.7989 4.03517 5.89359 4.67876 5.12043C5.32236 4.34726 6.12819 3.72517 7.0391 3.29828C7.95002 2.87139 8.9437 2.65016 9.94969 2.65027H38.0503C39.0559 2.65025 40.0492 2.87143 40.9598 3.29812C41.8705 3.72481 42.6761 4.34657 43.3196 5.11933C43.9632 5.8921 44.4289 6.79695 44.6837 7.76976C44.9385 8.74257 44.9763 9.75953 44.7943 10.7486L40.9714 31.5017Z"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M17.2744 22.7045L23.5076 29.1331C26.4424 20.7057 28.8836 17.0097 34.4173 11.9902"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
    </SvgIcon>
  );
}

export function InfoIntrIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 48 48"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9999 45.4286C29.6831 45.4286 35.1335 43.1709 39.1521 39.1523C43.1708 35.1336 45.4284 29.6832 45.4284 24C45.4284 18.3168 43.1708 12.8663 39.1521 8.84769C35.1335 4.82906 29.6831 2.57141 23.9999 2.57141C18.3167 2.57141 12.8662 4.82906 8.84757 8.84769C4.82894 12.8663 2.57129 18.3168 2.57129 24C2.57129 29.6832 4.82894 35.1336 8.84757 39.1523C12.8662 43.1709 18.3167 45.4286 23.9999 45.4286V45.4286Z"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
      <path
        d="M24 36V24"
        stroke={props.hex || "black"}
        strokeWidth="5.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.fill || "none"}
      />
    </SvgIcon>
  );
}
