import React from 'react';
import { Box, Typography } from '@mui/material';
export const EasyApplyReview = () => {
  return (
    <>
      <Box sx={{ margin: 'auto', textAlign: 'center', minHeight: '400px' }}>
        <Typography>Thanks for applying</Typography>
        <Typography>
          maybe we display review info before clicking submit by why? Less
          clicks == better
        </Typography>
      </Box>
    </>
  );
};
