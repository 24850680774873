import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { EasyApply } from '../components';
import { useJobDetailGet } from '../config/api/jobs';
import { useParams } from 'react-router-dom';
import { isMobile } from '../utils/global';

export const JobPage = () => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { location, title } = useParams();
  const jobdata = useJobDetailGet({ link: `/job/${location}/${title}` || '' });

  return (
    <>
      {jobdata.data && (
        <div className="card-container">
          <Box py={4} sx={{ px: { xs: 0, xl: 50 } }} m="auto">
            <Grid item sx={{ textAlign: 'center', mb: 5 }}>
              <img
                src={jobdata.data.settings.default_company_logo}
                alt="company logo"
                crossOrigin="anonymous"
                width={isMobile() ? '200px' : '400px'}
              />
              <Typography variant="h4">{jobdata.data.data.title}</Typography>
              <Typography>{jobdata.data.data.location}</Typography>
              <Button
                sx={{
                  mt: 4,
                  borderRadius: 10,
                  minWidth: { xs: 50, lg: 150 },
                  lineHeight: 2.5,
                }}
                variant="outlined"
                onClick={() => setCollapsed(!collapsed)}
              >
                Apply
              </Button>
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{ mb: 5, px: { xs: 2, sm: 20 } }}
            >
              <Grid item>
                <p
                  dangerouslySetInnerHTML={{
                    __html: jobdata.data.data.jobDescription,
                  }}
                ></p>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              width: '100%',
              margin: 'auto',
              position: 'fixed',
              bottom: 10,
              p: 0,
            }}
          >
            <EasyApply job={jobdata.data.data} collapsed={collapsed} />
          </Box>
        </div>
      )}
    </>
  );
};
