import { Campaign } from "./campaign";

export interface Question {
  id: number;
  external_id: number;
  customer_id: number;
  text: string;
  created_at: string;
  updated_at: string;
  prompt: string;
}

export interface QuestionIn {
  customer_id: string;
  text: string;
  prompt?: string;
}

export interface QuestionLink {
  campaign_id: number;
  external_id: string;
  is_deleted: boolean;
  position: number;
  question: Question;
  question_id: number;
  text: string;
  prompt: string;
}

export interface QuestionLinkIn {
  customer_id: number;
  campaign_id: number;
  question_id: string;
  position?: number;
  text?: string;
  prompt?: string;
}

export interface TestimonialIn {
  customer_id: number;
  campaign_id: number;
  video: string;
  question_id: number;
  question_text: string;
  email: string;
  first_name: string;
  last_name: string;
  title: string;
}

export interface Testimonial {
  id: number;
  customer_id: number;
  company_name: string;
  company_slug: string;
  external_id: string;
  email: string;
  campaign_name?: string;
  campaign_id: string;
  question_id: string;
  question_text: string;
  video: string;
  last_video_version?: string;
  final_video: string;
  preview: string;
  thumbnail: string;
  first_name: string;
  last_name: string;
  title: string;
  headshot: string;
  created_at: string;
  updated_at: string;
  status: string;
  url_tags: string;
  category_tags: string;
  cta_link: string;
  cta_btn_text: string;
  editing_instructions: string;
  page_style: string;
  department_filter: string;
  landing_text: string;
  transcription: string;
  video_edit_status: string;
  parent?: string;
  label?: string;
  format?: string;
}

export enum LandingPageFormat {
  JamOne = "jamone",
  JamATS = "jamats",
  JamLand = "jamland",
  JamTrans = "jamtrans"
}

export interface TestimonialInitResponse {
  campaign: Campaign;
  questions: Question[];
}

export interface EditNote {
  id: number;
  testimonial_id: string;
  created_by: string;
  recommendation: string;
  note: string;
  created_at: string;
}

export interface TranscriptionLine {
  keyindex: number;
  timestamp: string;
  text: string;
}

export interface VideoSnip {
  start: number;
  stop: number;
}

export interface TextBranding {
  line1?: string;
  line2?: string;
}

export interface BrandingOptions {
  logo?: string;
  text?: TextBranding;
}

export interface VideoEditRequest {
  external_id: string;
  video: string;
  transitions: boolean;
  transcribe?: string[];
  trim?: VideoSnip[];
  stitch?: string[]; // list of videos to stitch in order
  branding_options?: BrandingOptions;
  saveAsNewVersion?: boolean;
  format?: string; // string ref to facebook, instagram, etc
  label?: string;
}