import { SCardProps, PageNavItem } from '../../components';

export const videoNavItems: PageNavItem[] = [
  { title: 'Preview', link: 'Preview', size: 6 },
  { title: 'Sharing', link: 'sharing', size: 6 },
  { title: 'Versions', link: 'versions' },
  { title: 'Transcription', link: 'transcription' },
  { title: 'URLs', link: 'urls' },
  { title: 'Category Tags', link: 'tags' },
  { title: 'Page Format', link: 'format' },
  { title: 'Landing Page Settings', link: 'settings' },
  { title: 'Collections', link: 'collections' },
  { title: 'Feedback', link: 'feedback' },
  { title: 'Analytics', link: 'analytics' },
];

export const videoCardLayout: { [name: string]: SCardProps } = {
  Preview: {
    title: 'Preview',
    colors: [],
    uploads: [],
    inputs: [],
  },
  Versions: {
    title: 'Versions',
    colors: [],
    uploads: [],
    inputs: [],
  },
  Transcription: {
    title: 'Transcription',
    colors: [],
    uploads: [],
    inputs: [],
  },
  Sharing: {
    title: 'Sharing',
    colors: [],
    uploads: [],
    inputs: [],
  },
  URLs: {
    title: 'URLs',
    colors: [],
    uploads: [],
    inputs: [],
  },
  'Category Tags': {
    title: 'Category Tags',
    colors: [],
    uploads: [],
    inputs: [],
  },
  'Page Format': {
    title: 'Page Format',
    colors: [],
    uploads: [],
    inputs: [],
  },
  'Landing Page Settings': {
    title: 'Landing Page Settings',
    colors: [],
    uploads: [],
    inputs: [
      {
        label: 'Button Text',
        code: 'cta_btn_text',
        characterLimit: 32,
      },
      {
        label: 'Button Link',
        code: 'cta_link',
      },
      {
        label: 'Landing Page Description',
        code: 'landing_text',
        type: 'longtext',
      },
    ],
  },
  Collections: {
    title: 'Collections',
    colors: [],
    uploads: [],
    inputs: [],
  },
  Feedback: {
    title: 'Feedback',
    colors: [],
    uploads: [],
    inputs: [],
  },
  Analytics: {
    title: 'Analytics',
    colors: [],
    uploads: [],
    inputs: [],
  },
};
