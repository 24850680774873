import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  DirectUpload,
  TableCard,
  VideoPlayer,
  WarningPopup,
} from '../../components';
import { EditRequest } from '../../components/Forms';
import {
  ColumnSort,
  JamyrTable,
  TableProps,
  TableAction,
  PopupModal,
  JamyrTextField,
} from '../../atoms';
import useAPI from '../../utils/useApi';
import { APIRequests, ListResource } from '../../config/api';
import { RequestInput, DataType } from '../../types/config';
import { Testimonial } from '../../types/testimonial';
import {
  useGlobalState,
  useCurrentCompany,
} from '../../utils/GlobalStateProvider';
import { Grid, Tabs, Tab, Typography } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { toast } from 'react-toastify';
import { TestimonialStatus, StatusTab } from '../../config/layouts/selectLists';
import { isMobile } from '../../utils/global';

export interface VideoDisplayProps {
  campaign?: number;
  embedded?: boolean;
}

const emptyStateMessage: { [name: string]: string } = {
  new: "Great job, you're all caught up!",
  'pending edit': 'No videos waiting to be processed',
  'pending approval': 'No videos pending approval',
  approved: 'No active videos',
  rejected: 'No rejected videos',
};

export const Videos = (props: VideoDisplayProps): JSX.Element => {
  const context = useGlobalState();
  const company = useCurrentCompany();
  const { showModal } = useModal();
  const [tableData, setTableData] = useState<{ [name: string]: string }[]>();
  const [value, setValue] = useState<number>(0);
  const [fetchData, setFetchData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sortValue, setSortValue] = useState<ColumnSort>();
  const [searchInput, setSearchInput] = useState<string | undefined>();
  const [searchError, setSearchError] = useState<string>('');
  const [filter, setFilter] = useState<string>(TestimonialStatus[value].name);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    window.history.pushState(null, '', `#${TestimonialStatus[newValue].label}`);
    setValue(newValue);
    setFilter(TestimonialStatus[newValue].name);
  };

  const testimonials = useAPI<ListResource>(APIRequests.TestimonialsList);
  const testimonialUpdate = useAPI<Testimonial>(APIRequests.TestimonialUpdate);
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    let filterStatus = filter;
    if (location.hash && !tableData) {
      // location.hash is only reliable on the first load of the page
      const status: string = location.hash.replace('#', '');
      const selectedStatus = TestimonialStatus.filter(
        (val) => val.label === status
      );
      if (selectedStatus) {
        filterStatus = selectedStatus[0].name;
        setValue(TestimonialStatus.indexOf(selectedStatus[0]));
        setFilter(filterStatus);
      }
    }
    let req: RequestInput = {
      pathParams: { company: company || context.state.company },
      queryParams: { status: `${filterStatus}`, q: `status=${filterStatus}` }, // q is for search
    };
    if (props.campaign && req.queryParams) {
      req.queryParams.campaign = props.campaign;
    }
    if (sortValue && req.queryParams) {
      req.queryParams.sort = sortValue.field;
      req.queryParams.sortDir = sortValue.direction;
    }
    if (sortValue && req.queryParams) {
      req.queryParams.sort = sortValue.field;
      req.queryParams.sortDir = sortValue.direction;
    }
    if (searchInput && req.queryParams) {
      req.queryParams.search = searchInput;
    }

    setFetchData(false);
    testimonials.execute(req);
  }, [filter, fetchData, company, sortValue, searchInput]);

  useEffect(() => {
    if (testimonials.data) {
      setTableData(
        (testimonials.data.records as { [name: string]: string }[]) || []
      );
      setIsLoading(false);
    }
  }, [testimonials.data]);

  useEffect(() => {
    if (testimonialUpdate.data) {
      toast('🦄 Video Updated!');
      setFetchData(true);
    }
    if (testimonialUpdate.error) {
      toast.error(String(testimonialUpdate.error));
    }
  }, [testimonialUpdate.data, testimonialUpdate.error]);

  const handleEditClick = (idx: number) => {
    if (tableData) {
      const t: Testimonial = tableData[idx] as unknown as Testimonial;
      navigate(`/dashboard/videos/${t.external_id}`);
    }
  };

  const handlePlayClick = (idx: number) => {
    if (tableData) {
      const testimonial = tableData[idx] as unknown as Testimonial;
      const link = testimonial.final_video || testimonial.video;
      showModal(VideoPlayer, { link: link, testimonial: testimonial });
    }
  };

  const handleDirectUpload = () => {
    const modal = showModal(PopupModal, {
      children: (
        <DirectUpload
          onComplete={() => {
            modal.destroy();
            setFetchData(true);
          }}
        />
      ),
    });
  };

  const handleStatusChange = (idx: number, status: StatusTab) => {
    if (tableData) {
      const t = tableData[idx];
      if (status.name === 'pending edit') {
        const modal = showModal(PopupModal, {
          children: (
            <EditRequest
              data={t}
              onComplete={() => {
                modal.destroy();
                setFetchData(true);
              }}
            />
          ),
        });
      } else {
        const t = tableData[idx];
        t.status = status.name;
        const req: RequestInput = {
          pathParams: {
            company: t.customer_id,
            id: t.id,
          },
          body: t,
        };
        testimonialUpdate.execute(req);
      }
    }
  };

  const handleSearch = (_: string, value: string) => {
    if (value && value.length <= 3) {
      setSearchError('enter at least 4 characters');
    } else {
      setSearchError('');
      setSearchInput(value.replaceAll("'", ''));
    }
  };

  const actions: TableAction[] = [
    {
      header: 'Preview',
      element: 'player',
      onClick: handlePlayClick,
      order: -1,
    },
    {
      header: 'Customize',
      icon: 'edit',
      onClick: handleEditClick,
    },
  ];

  if (!isMobile()) {
    actions.push({
      header: 'Status',
      element: 'select',
      onClick: handleStatusChange,
      selectOptions: TestimonialStatus,
      value: 'status',
    });
  }

  const mobileColumns = [
    {
      name: 'question_text',
      label: 'Question',
    },
  ];

  const desktopColumns = [
    {
      name: 'first_name',
      label: 'First name',
      sorted:
        sortValue && sortValue.field === 'first_name'
          ? sortValue.direction
          : undefined,
    },
    {
      name: 'last_name',
      label: 'Last name',
      sorted:
        sortValue && sortValue.field === 'last_name'
          ? sortValue.direction
          : undefined,
    },
    {
      name: 'email',
      label: 'Email',
      sorted:
        sortValue && sortValue.field === 'email'
          ? sortValue.direction
          : undefined,
    },
    {
      name: 'question_text',
      label: 'Question',
      sorted:
        sortValue && sortValue.field === 'question_text'
          ? sortValue.direction
          : undefined,
    },
    {
      name: 'created_at',
      label: 'Date',
      type: DataType.Date,
      sorted:
        sortValue && sortValue.field === 'created_at'
          ? sortValue.direction
          : undefined,
    },
    {
      name: 'campaign_name',
      label: 'Campaign',
    },
  ];

  const tableProps: TableProps = {
    columns: isMobile() ? mobileColumns : desktopColumns,
    rows: tableData,
    actions: actions,
    sortData: (data: ColumnSort) => setSortValue(data),
    emptyMessage: (
      <Typography sx={{ fontSize: 18, textAlign: 'center' }}>
        {emptyStateMessage[filter]}
      </Typography>
    ),
    isLoading: isLoading,
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={6}
          sx={{
            ml: props.embedded || isMobile() ? 2 : 10,
            mt: props.embedded ? 0 : 10,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            Videos
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mx: props.embedded || isMobile() ? 2 : 10, mt: 5 }}
        >
          <JamyrTextField
            name="search"
            placeholder="Search"
            fullWidth
            changehandler={handleSearch}
          />
          {searchError && (
            <Typography sx={{ color: 'red' }}>{searchError}</Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            ml: { xs: 4, sm: props.embedded ? 5 : 15 },
            mt: { xs: props.embedded ? 0 : 5 },
            mb: props.embedded ? 10 : 0,
          }}
        >
          <Tabs
            value={value}
            onChange={handleTabChange}
            variant={isMobile() ? 'scrollable' : 'standard'}
            scrollButtons={isMobile()}
            aria-label="testimonial status tabs"
            sx={{
              ml: -5,
            }}
          >
            {TestimonialStatus.map((tab) => {
              return (
                <Tab
                  key={tab.name}
                  label={tab.label}
                  id={tab.name}
                  aria-controls={`testimonial-tabpanel-${tab.name}`}
                  sx={{
                    textTransform: 'none',
                  }}
                />
              );
            })}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          {props.embedded ? (
            <JamyrTable {...tableProps} />
          ) : (
            <TableCard
              title=""
              actionIcon="add"
              actionLabel="Upload Video"
              actionClick={handleDirectUpload}
              paperMargin={5}
            >
              <JamyrTable {...tableProps} />
            </TableCard>
          )}
        </Grid>
      </Grid>
    </>
  );
};
