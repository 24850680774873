import * as React from 'react';
import DraggableListItem from './DraggableListItem';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { SelectOption } from '../atoms';

export type DraggableListProps = {
  items: SelectOption[];
  onDragEnd: (data: SelectOption[]) => void;
  onItemRemove: (record: number) => void;
  onItemEdit?: (data: SelectOption) => void;
  onVideoEdit?: (data: SelectOption) => void;
};

const DraggableList = React.memo(
  ({
    items,
    onDragEnd,
    onItemRemove,
    onItemEdit,
    onVideoEdit,
  }: DraggableListProps) => {
    const reorder = (
      startIndex: number,
      endIndex: number
    ): { [name: string]: string }[] | any => {
      const [removed] = items.splice(startIndex, 1);
      items.splice(endIndex, 0, removed);

      return items;
    };
    const handleDragEnd = ({ destination, source }: DropResult) => {
      // dropped outside the list
      if (!destination) return;

      const newItems = reorder(source.index, destination.index);
      onDragEnd(newItems);
    };

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item: SelectOption, index: number) => (
                <DraggableListItem
                  item={item}
                  index={index}
                  key={`${item.id}-${index}`}
                  onItemRemove={onItemRemove}
                  onItemEdit={onItemEdit}
                  onVideoEdit={onVideoEdit}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export default DraggableList;
