import React, { useState } from 'react';
import { Testimonial } from '../types/testimonial';
import { Box, Typography } from '@mui/material';
import PlayCircle from '@mui/icons-material/PlayCircle';
import CSS from 'csstype';
import { isMobile } from '../utils/global';

const cardStyle: CSS.Properties = {
  background: 'transparent',
  position: 'relative',
  textDecoration: 'none',
  width: '200px',
  height: '325px',
  maxHeight: '325px',
  borderRadius: '10px',
  boxShadow: 'none',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  margin: isMobile() ? 'auto' : '0px 4px 0px 4px',
  marginBottom: isMobile() ? '40px' : 'auto',
};

export interface GalleryCardProps {
  testimonial: Testimonial;
  hideQuestionText: boolean;
  onclick: () => void;
  onclose?: () => void;
}

export const GalleryCard = (props: GalleryCardProps) => {
  const [imageUrl, setImageUrl] = useState(props.testimonial.thumbnail);
  return (
    <>
      <Box
        sx={cardStyle}
        onClick={() => props.onclick()}
        onMouseEnter={() => setImageUrl(props.testimonial.preview)}
        onMouseLeave={() => setImageUrl(props.testimonial.thumbnail)}
      >
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: 'transparent',
            borderRadius: '10px 10px 0px 0px',
            minHeight: 50,
            display: props.hideQuestionText ? 'none' : 'inherit',
            width: '200px',
            backgroundImage:
              'linear-gradient(180deg, #fff, hsla(0, 0%, 100%, 0.95) 0%, hsla(0, 0%, 100%, 0.74) 57%, hsla(0, 0%, 100%, 0));',
            boxShadow: '0 -6px 12px 0 rgba(0, 0, 0, 0.12)',
          }}
        >
          <Typography
            sx={{
              color: 'black',
              fontSize: 12,
              pl: 1,
            }}
          >
            {props.testimonial.question_text}
          </Typography>
        </Box>
        <img
          style={{
            width: 200,
            height: 325,
            objectFit: 'cover',
            maxWidth: 200,
            maxHeight: 325,
            borderRadius: 10,
          }}
          crossOrigin="anonymous"
          src={imageUrl}
          alt={props.testimonial.question_text}
        />
        <Box
          sx={{
            position: 'absolute',
            borderRadius: '0px 0px 10px 10px',
            pt: 1,
            pb: 2,
            height: 25,
            width: '200px',
            backgroundColor: 'transparent',
            backgroundImage: 'linear-gradient(0deg, #000, transparent)',
            bottom: -1,
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontSize: 12,
              pl: 1,
              fontWeight: 'bold',
              textAlign: 'left',
            }}
          >
            {props.testimonial.first_name}
          </Typography>
          <Typography
            sx={{
              color: 'white',
              fontSize: 12,
              pl: 1,
              fontWeight: 'bold',
              textAlign: 'left',
            }}
          >
            {props.testimonial.title}
          </Typography>
          <PlayCircle
            sx={{
              position: 'absolute',
              right: '4%',
              bottom: '25%',
              width: 25,
              opacity: 0.7,
              color: 'white',
              cursor: 'pointer',
            }}
          />
        </Box>
      </Box>
    </>
  );
};
