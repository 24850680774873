import React, { useState, useEffect } from 'react';
import useAPI from '../../utils/useApi';
import {
  useGlobalState,
  useCurrentCompany,
} from '../../utils/GlobalStateProvider';
import { APIRequests } from '../../config/api';
import { Form, FieldConfig } from '../../atoms/Form';
import { RequestInput, CreateFormProps } from '../../types/config';
import { toast } from 'react-toastify';

export const EditRequest = (props: CreateFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const context = useGlobalState();
  const company = useCurrentCompany();
  const fields: FieldConfig[] = [
    {
      name: 'editing_instructions',
      label: 'Instructions',
      type: 'longtext',
      placeholder: 'eg. Start video at 4 seconds',
      columnSize: 12,
    },
  ];
  const testimonialUpdate = useAPI(APIRequests.TestimonialUpdate);

  useEffect(() => {
    if (testimonialUpdate.data) {
      toast('🦄 Video Submitted!');
      props.onComplete();
      setLoading(false);
    }
    if (testimonialUpdate.error) {
      toast.error(String(testimonialUpdate.error));
      setLoading(false);
    }
  }, [testimonialUpdate.data, testimonialUpdate.error]);

  const handleSubmit = (data: any) => {
    setLoading(true);
    props.data.status = 'pending edit';
    props.data.editing_instructions = data.editing_instructions;
    const req: RequestInput = {
      pathParams: {
        company: company || context.state.company,
        id: props.data.id,
      },
      body: props.data,
    };
    testimonialUpdate.execute(req);
  };

  return (
    <Form
      title="Edit Request"
      submitLabel="Send"
      fields={fields}
      callback={handleSubmit}
      isLoading={loading}
    />
  );
};
