import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { CustomIcon, Loading } from "../atoms";
import { JamyrSelectField } from "./JamyrSelectField";
import { VideoPlayer } from "../components";
import { format } from "date-fns";
import { DataType } from "../types/config";

export type ColumnSort = {
  field: string;
  direction: "asc" | "desc";
};

export interface TableAction {
  header: string;
  onClick: (i: number, data: any | undefined) => void;
  element?: string;
  icon?: string;
  iconColor?: string;
  selectOptions?: { [name: string]: string }[];
  value?: string | number;
  order?: number;
}

export interface Column {
  name: string;
  label: string;
  type?: DataType;
  sorted?: "asc" | "desc";
}

export interface TableProps {
  columns: Column[];
  rows: { [name: string]: string }[] | any;
  maxHeight?: number;
  actions?: TableAction[];
  sortData?: (data: ColumnSort) => void;
  emptyMessage?: JSX.Element;
  isLoading?: boolean;
  pagination?: boolean;
}

export const JamyrTable = (props: TableProps): JSX.Element => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleClick = (idx: number) => {};
  const prefixActions =
    props.actions &&
    Object.values(props.actions).filter(
      (action) => action.order && action.order < 0
    );
  const suffixActions =
    props.actions &&
    Object.values(props.actions).filter((action) => !action.order);

  const renderAction = (action: TableAction, idx: number): JSX.Element => {
    return (
      <CustomIcon
        name={action.icon || ""}
        onClick={() => action.onClick(idx, undefined)}
        sx={{ cursor: "pointer" }}
        fontSize="medium"
        htmlColor={action.iconColor}
      />
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const renderElement = (action: TableAction, idx: number): JSX.Element => {
    if (action.element === "player") {
      return <VideoPlayer onClick={() => action.onClick(idx, undefined)} />;
    } else if (action.element === "select") {
      let opt: { [name: string]: string } = {};
      if (action.value && action.selectOptions) {
        const filtVals = action.selectOptions.filter(
          (option) =>
            action.value && option.name === props.rows[idx][action.value]
        );
        opt = filtVals[0];
      }
      return (
        <JamyrSelectField
          name={`testimonial status ${idx}`}
          options={action.selectOptions}
          onChange={(res: any) => action.onClick(idx, res)}
          value={opt}
        />
      );
    } else {
      console.error("Unsupported element render failed: ", action.element);
      return <></>;
    }
  };

  const handleHeaderClick = (coldata: Column | undefined) => {
    if (coldata) {
      const direction =
        coldata.sorted && coldata.sorted === "desc" ? "asc" : "desc";
      if (props.sortData) {
        const d: ColumnSort = {
          field: coldata.name,
          direction: direction,
        };
        props.sortData(d);
      }
    }
  };

  const renderHeader = (
    key: string,
    column: string,
    alignment: "left" | "center" | "right",
    sortable: boolean,
    coldata?: Column
  ) => {
    let clickHandler = !sortable
      ? () => {
          return;
        }
      : () => handleHeaderClick(coldata);

    return (
      <TableCell
        key={key}
        onClick={clickHandler}
        sx={{ cursor: sortable ? "pointer" : "inherit" }}
      >
        <Typography
          color="text.secondary"
          sx={{ fontSize: 16, fontWeight: 500 }}
          align={alignment}
        >
          {column}
          {coldata && coldata.sorted === "asc" && <ArrowDropUp />}
          {coldata && coldata.sorted === "desc" && <ArrowDropDown />}
        </Typography>
      </TableCell>
    );
  };

  const renderField = (field: string, columnIdx: number): JSX.Element => {
    const coltype = props.columns[columnIdx];
    if (coltype.type) {
      switch (coltype?.type) {
        case DataType.Date:
          return (
            <Typography>{format(Date.parse(field), "MM/dd/yyyy")} </Typography>
          );
        default:
          return <Typography> {field} </Typography>;
      }
    } else {
      return <Typography> {field} </Typography>;
    }
  };

  return (
    <>
      {props.rows && !props.isLoading && (
        <>
          <TableContainer
            sx={{
              position: "relative",
              top: -20,
              overflow: "scroll-y",
              maxHeight: props.maxHeight,
              "&::-webkit-scrollbar": { width: 5 },
              "&::-webkit-scrollbar-track": { background: "#F8F8F8" },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: 5,
              },
            }}
          >
            <Table aria-label="Data table" size="small">
              <TableHead>
                <TableRow>
                  {prefixActions &&
                    Object.values(prefixActions).map(
                      (action: TableAction, i: number) =>
                        renderHeader(
                          `action-${i}`,
                          action.header,
                          "left",
                          false
                        )
                    )}
                  {props.columns.map((column: Column, i: number) =>
                    renderHeader(
                      `data-${i}`,
                      column.label,
                      "left",
                      "sorted" in column ? true : false,
                      column
                    )
                  )}
                  {suffixActions &&
                    Object.values(suffixActions).map(
                      (action: TableAction, i: number) =>
                        renderHeader(
                          `action-${i}`,
                          action.header,
                          "center",
                          false
                        )
                    )}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "& .MuiTableRow-root:hover": {
                    background:
                      "linear-gradient(146deg, rgba(249, 225, 255, 0.4) 17%, rgba(225, 244, 255, 0.5))",
                  },
                }}
              >
                {props.rows &&
                  props.rows
                    .slice(
                      page * rowsPerPage,
                      "pagination" in props
                        ? page * rowsPerPage + rowsPerPage
                        : props.rows.length
                    )
                    .map((row: { [name: string]: string }, i: number) => {
                      return (
                        <TableRow
                          key={i}
                          hover
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          // TODO: disabling for now, there is a conflict
                          // with the actions potentially
                          // onClick={e => handleClick(i)}
                        >
                          {prefixActions &&
                            Object.values(prefixActions).map(
                              (action: TableAction, idx: number) => {
                                return (
                                  <TableCell
                                    key={`${idx}-row-${action.header}`}
                                    component="th"
                                    scope="row"
                                  >
                                    {action.icon && renderAction(action, i)}
                                    {action.element && renderElement(action, i)}
                                  </TableCell>
                                );
                              }
                            )}
                          {props.columns.map((field: Column, id: number) => {
                            return (
                              <TableCell
                                key={`${id}-` + field}
                                scope="row"
                                sx={{
                                  // whiteSpace: "normal",
                                  maxWidth: 200,
                                  overflowWrap: "break-word",
                                }}
                              >
                                {renderField(row[field.name], id)}
                              </TableCell>
                            );
                          })}
                          {suffixActions &&
                            Object.values(suffixActions).map(
                              (action: TableAction, idx: number) => {
                                return (
                                  <TableCell
                                    key={`${idx}-row-${action.header}`}
                                    component="th"
                                    scope="row"
                                    align="center"
                                  >
                                    {action.icon && renderAction(action, i)}

                                    {action.element && renderElement(action, i)}
                                  </TableCell>
                                );
                              }
                            )}
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {(!props.rows || props.isLoading) && <Loading />}
      {props.rows && props.rows.length === 0 && (
        <Grid container justifyContent="center" alignItems="center">
          {props.emptyMessage}
        </Grid>
      )}
      {"pagination" in props && props.rows && props.rows.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage=""
        />
      )}
    </>
  );
};
