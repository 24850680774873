import React, { useState, useEffect } from 'react';
import useAPI from '../../utils/useApi';
import { APIRequests } from '../../config/api';
import { Form, FieldConfig } from '../../atoms/Form';
import { Customer } from '../../types/user';
import { RequestInput, CreateFormProps } from '../../types/config';
import { toast } from 'react-toastify';

export const CustomerCreate = (props: CreateFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);

  const fields: FieldConfig[] = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'eg. Jamyr',
      validation: { required: true, errormessage: 'Name is required' },
      columnSize: 12,
    },
  ];
  const customer = useAPI(APIRequests.CustomerCreate);

  useEffect(() => {
    if (customer.data) {
      toast('🦄 Customer Created!');
      props.onComplete();
      setLoading(false);
    }
    if (customer.error) {
      toast.error(String(customer.error));
      setLoading(false);
    }
  }, [customer.data, customer.error]);

  const handleSubmit = (data: any) => {
    setLoading(true);
    const customerdata = data as Customer;
    const req: RequestInput = {
      body: customerdata,
    };
    customer.execute(req);
  };

  return (
    <Form
      title="Create Customer"
      submitLabel="Create"
      fields={fields}
      callback={handleSubmit}
      isLoading={loading}
    />
  );
};
