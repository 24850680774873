import React, { useState } from 'react';
import { JamyrTextField } from './JamyrTextField';
import { Button, Grid, Typography } from '@mui/material';
export interface WidgetLocationProps {
  onSave: (data: any) => void;
  current?: string;
}

export const WidgetLocation = (props: WidgetLocationProps): JSX.Element => {
  const defaultLocation = 'auto 15px 50px auto';
  const [current, setCurrent] = useState(props.current || defaultLocation); // top, right, bottom, left

  const handleChange = (field: string, value: string) => {
    let c = current.split(' ');
    c[parseInt(field) - 1] = value;
    setCurrent(c.join(' '));
  };

  const handleReset = () => {
    setCurrent(defaultLocation);
    props.onSave(defaultLocation);
  }

  const handleSave = () => {
    props.onSave(current);
  }

  return <>
    <Typography sx={{ fontWeight: 'bold' }}>Widget Location</Typography>
    <Grid container>
      <Grid item xs={12} sm={6}>
        <JamyrTextField
          name="1"
          label="Top"
          currentvalue={current.split(' ')[0]}
          type="text"
          sx={{ width: { xs: '100%', sm: '70%' } }}
          changehandler={handleChange}
          helpText='Changes the distance from the top of the page. Most common value is "auto"'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <JamyrTextField
          name="2"
          label="Right"
          currentvalue={current.split(' ')[1]}
          type="text"
          sx={{ width: { xs: '100%', sm: '70%' } }}
          changehandler={handleChange}
          helpText='Changes the distance from the right of the page. To display the widget on the left side of the page, use "auto"'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <JamyrTextField
          name="3"
          label="Bottom"
          currentvalue={current.split(' ')[2]}
          type="text"
          sx={{ width: { xs: '100%', sm: '70%' } }}
          changehandler={handleChange}
          helpText='Changes the distance from the bottom of the page.'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <JamyrTextField
          name="4"
          label="Left"
          currentvalue={current.split(' ')[3]}
          type="text"
          sx={{ width: { xs: '100%', sm: '70%' } }}
          changehandler={handleChange}
          helpText='Changes the distance from the left of the page. To display the widget on the right side of the page, set this to "auto"'
        />
      </Grid>
      <Grid item xs={12} sx={{ pt: 1 }}>
        <Button onClick={handleSave}>Save</Button>
        <Button sx={{ ml: 1, backgroundColor: 'white', color: 'black', border: '1px solid black' }} onClick={handleReset}>Reset Defaults</Button>
      </Grid>
    </Grid>

  </>
}