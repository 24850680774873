import React, { useState } from 'react';
import { Form, FieldConfig } from '../../atoms/Form';
import { Customer } from '../../types/user';
import { RequestInput, CreateFormProps } from '../../types/config';
import { toast } from 'react-toastify';

export const EasyApplyEmail = (props: CreateFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);

  const fields: FieldConfig[] = [
    {
      name: 'email',
      label: 'Email',
      type: 'text',
      placeholder: 'Your email address',
      validation: { required: true, errormessage: 'Email is required' },
      columnSize: 12,
    },
    {
      name: 'optin',
      label: 'I want to get job alerts and I agree to the T&C',
      type: 'checkbox',
      columnSize: 12,
    },
  ];

  const handleSubmit = (data: any) => {
    setLoading(true);
    props.onComplete();
  };

  return (
    <Form
      title="Apply with just your email address"
      submitLabel="Submit"
      fields={fields}
      callback={handleSubmit}
      isLoading={loading}
    />
  );
};
