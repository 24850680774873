import { getCookie, setCookie, eraseCookie } from "./global";
import { JamyrCookies } from "../types/config";

export const isAuthenticated = (): boolean => {
  return getCookie(JamyrCookies.SITE) !== undefined;
};

/**
 * Login to backend and store JSON web token on success
 *
 * @param email
 * @param password
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const login = async (email: string, password: string) => {
  // Assert email or password is not empty
  if (!(email.length > 0) || !(password.length > 0)) {
    throw new Error('Email or password was not provided');
  }
  const formData = new FormData();
  // OAuth2 expects form data, not JSON data
  formData.append('username', email);
  formData.append('password', password);

  const request = new Request(`${process.env.REACT_APP_API_URL}/token`, {
    method: 'POST',
    body: formData,
  });

  const response = await fetch(request, {
    credentials: "include",
  });

  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw new Error(data.detail);
    }
    throw new Error(data);
  }
  if ("access_token" in data) {
    setCookie(JamyrCookies.SITE, data["access_token"], 1);
  }
  return data;
};


export const logout = () => {
  localStorage.removeItem('token');
  eraseCookie(JamyrCookies.SITE);
  window.location.href = "/login";
};

