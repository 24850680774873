import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { isMobile } from "../utils/global";

export interface PageNavItem {
  title: string;
  link: string;
  size?: number;
}

export const PageNavigation = (props: PageNavItem[]): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<Number>(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  // TODO: fix accessibility of the tabs
  return (
    <Tabs
      value={selectedTab}
      variant={isMobile() ? "scrollable" : "standard"}
      scrollButtons={isMobile()}
      onChange={handleChange}
      TabIndicatorProps={{
        style: {
          display: "none"
        }
      }}
      sx={{
        pt: { xs: 5, sm: 10 },
        pl: { xs: 0, sm: 3 },
        "&& .MuiTab-root": {
          backgroundColor: "white",
          color: "info.main",
          borderRadius: 2,
          ml: 2,
          opacity: 1
        }
      }}
      aria-label="Page navigation"
    >
      {Object.values(props).map((item: PageNavItem) => {
        return (
          <HashLink
            key={item.link}
            to={`#${item.link}`}
            smooth
            style={{ textDecoration: "none" }}
          >
            <Tab
              component="p"
              label={item.title}
              sx={{
                textTransform: "none"
              }}
            />
          </HashLink>
        );
      })}
    </Tabs>
  );
};
