import React, { useState, useEffect } from "react";
import useAPI from "../../utils/useApi";
import {
  useGlobalState,
  useCurrentCompany
} from "../../utils/GlobalStateProvider";
import { APIRequests } from "../../config/api";
import { Form, FieldConfig } from "../../atoms/Form";
import { Collection } from "../../types/collection";
import { RequestInput, CreateFormProps } from "../../types/config";
import { toast } from "react-toastify";

export const CollectionCreate = (props: CreateFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const context = useGlobalState();
  const company = useCurrentCompany();
  const fields: FieldConfig[] = [
    {
      name: "title",
      label: "Name",
      type: "text",
      placeholder: "eg. Onboarding videos",
      validation: { required: true, errormessage: "Name is required" },
      columnSize: 12
    },
    {
      name: "heading_text",
      label: "Description",
      type: "longtext",
      placeholder: "eg. Collection of videos for onboarding",
      validation: { required: true, errormessage: "Description is required" },
      columnSize: 12
    }
  ];
  const collection = useAPI(APIRequests.CollectionCreate);

  useEffect(() => {
    if (collection.data) {
      toast("🦄 Collection Created!");
      props.onComplete();
      setLoading(false);
    }
    if (collection.error) {
      toast.error(String(collection.error));
      setLoading(false);
    }
  }, [collection.data, collection.error]);

  const handleSubmit = (data: any) => {
    setLoading(true);
    data.customer_id = company || context.state.company;
    const collectiondata = data as Collection;
    const req: RequestInput = {
      pathParams: { company: company || context.state.company },
      body: collectiondata
    };
    collection.execute(req);
  };

  return (
    <Form
      title="Create Collection"
      submitLabel="Create"
      fields={fields}
      callback={handleSubmit}
      isLoading={loading}
    />
  );
};
