import { useCallback, useState } from 'react';
import { JamyrCookies, RequestInput } from '../types/config';
import { APIRequests, MakeRequest } from '../config/api';
import { eraseCookie } from './global';

type ErrorMessage = {
  loc: string[];
  msg: string;
  type: string;
};

type ErrorResponse = {
  detail: ErrorMessage[];
};

export default function useAPI<T>(
  request: APIRequests,
  external?: boolean
): {
  data: T;
  error: string[] | undefined;
  execute: (input: RequestInput) => Promise<void>;
} {
  let temp: unknown;
  const [data, setData] = useState<T>(temp as T);
  const [error, setError] = useState<string[]>();

  const execute = useCallback(async (input: RequestInput) => {
    const req = MakeRequest(request, input);
    return fetch(req, {
      credentials: !external ? 'include' : 'omit',
    })
      .then(async (res) => {
        if (!res.ok) {
          if (res.status === 401) {
            eraseCookie(JamyrCookies.SITE);
          }
          const err = await res.json();
          console.error(err);
          let errorList: string[] = [];
          errorList.push(err.detail);
          errorList.push('An API error has occured');
          setError(errorList);
          throw err;
        }
        return res;
      })
      .then((res) => {
        return res
          .json()
          .then((json) => json)
          .catch(() => {
            return {};
          });
      })
      .then((result) => {
        setData(result || 'ok');
      })
      .catch((err: ErrorResponse) => {
        console.error(err);
      });
  }, []);

  return { data, execute, error };
}
