import React, { useState, useEffect } from 'react';
import useAPI from '../../utils/useApi';
import {
  useGlobalState,
  useCurrentCompany,
} from '../../utils/GlobalStateProvider';
import { APIRequests } from '../../config/api';
import { Form, FieldConfig } from '../../atoms/Form';
import { Question } from '../../types/testimonial';
import { RequestInput, CreateFormProps } from '../../types/config';
import { toast } from 'react-toastify';

export const QuestionCreate = (props: CreateFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const isUpdating = props.data ? true : false;
  const context = useGlobalState();
  const company = useCurrentCompany();
  const fields: FieldConfig[] = [
    {
      name: 'text',
      label: 'Question',
      type: 'text',
      placeholder: 'eg. Describe a day in the life at our company',
      validation: { required: true, errormessage: 'Name is required' },
      columnSize: 12,
    },
    {
      name: 'prompt',
      label: 'Prompt',
      type: 'text',
      placeholder: 'eg. Talk about what your day is like',
      columnSize: 12,
    },
  ];
  const question = useAPI(
    isUpdating ? APIRequests.QuestionEdit : APIRequests.QuestionCreate
  );

  useEffect(() => {
    if (question.data) {
      const message = isUpdating
        ? '🦄 Question Updated!'
        : '🦄 Question Created!';
      toast(message);
      props.onComplete();
      setLoading(false);
    }
    if (question.error) {
      toast.error(String(question.error));
      setLoading(false);
    }
  }, [question.data, question.error]);

  const handleSubmit = (data: any) => {
    setLoading(true);
    const questiondata = data as Question;
    questiondata.customer_id = company || context.state.company;
    const req: RequestInput = {
      pathParams: { company: company || context.state.company },
      body: questiondata,
    };
    if (isUpdating && req.pathParams) {
      req.pathParams.id = questiondata.id;
    }
    question.execute(req);
  };

  return (
    <Form
      title={isUpdating ? 'Update Question' : 'Create Question'}
      submitLabel={isUpdating ? 'Update' : 'Create'}
      fields={fields}
      callback={handleSubmit}
      isLoading={loading}
      currentData={props.data as { [name: string]: string }}
    />
  );
};
