import React from 'react';
import { AppBar, Container, Toolbar } from '@mui/material';
import { CustomerSetting } from '../types/user';
export interface PublicHeaderProps {
  settings: CustomerSetting;
}

export const PublicHeader = (props: PublicHeaderProps): JSX.Element => {
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: props.settings.landing_nav_color }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src={
              props.settings.landing_company_logo ||
              props.settings.default_company_logo ||
              '../images/jamyr-logo-wide.png'
            }
            crossOrigin="anonymous"
            alt="Company Logo"
            style={{
              maxWidth: '200px',
              margin: 'auto',
              display: 'block',
            }}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
