import React, { useState } from 'react';
import { Divider, List, ListItem, ListItemText } from '@mui/material';
import { CustomIcon } from '../atoms/CustomIcon';

export interface Action {
  icon: string | React.ReactElement;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
  popupElement?: JSX.Element;
}

export interface Option {
  title: string;
  description?: string;
  actions?: Action[];
}

export const OptionsList = (props: Option[]): JSX.Element => {
  const [expanded, setExpanded] = useState<string>();
  return (
    <>
      <List>
        {Object.values(props).map((option: Option) => {
          return (
            <div key={option.title}>
              <ListItem>
                <ListItemText
                  primary={option.title}
                  secondary={option.description}
                  sx={{ pr: 5 }}
                />
                {option.actions &&
                  option.actions.length > 0 &&
                  Object.values(option.actions).map(
                    (action: Action, idx: number) => {
                      if (typeof action.icon === 'string') {
                        return (
                          <div key={`${option.title}-${idx}`}>
                            <CustomIcon
                              name={action.icon}
                              onClick={(e) =>
                                action.popupElement
                                  ? setExpanded(`${option.title}-${idx}`)
                                  : action.onClick && action.onClick(e)
                              }
                              sx={{ cursor: 'pointer', ml: 1 }}
                            />
                            {expanded === `${option.title}-${idx}` && (
                              <div style={{ position: 'relative' }}>
                                <CustomIcon
                                  name="close"
                                  sx={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: -300,
                                    left: 100,
                                  }}
                                  onClick={() => setExpanded(undefined)}
                                />
                                {action.popupElement}
                              </div>
                            )}
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={`${option.title}-${idx}`}
                            style={{
                              position: 'relative',
                              paddingTop: 8,
                              paddingLeft: 5,
                            }}
                          >
                            {action.icon}
                          </div>
                        );
                      }
                    }
                  )}
              </ListItem>
              <Divider />
            </div>
          );
        })}
      </List>
    </>
  );
};
