import React from "react";
import { SvgIcon } from "@mui/material";
import { IconProps } from "../../CustomIcon";

export function GearDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 88 88"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5917 16.2387C30.614 16.5648 23.7698 10.6982 22.7921 10.0464C21.8144 9.39455 10.7333 20.4758 10.7333 21.1274C10.7333 21.779 17.5775 28.6236 17.2516 29.2752C16.9257 29.9269 15.622 33.186 15.2961 33.838C14.9702 34.49 4.86711 34.4896 4.21508 35.1417C3.56304 35.7937 4.54075 52.0893 5.19281 52.415C5.84488 52.7407 13.6663 52.415 14.3184 52.415C14.9704 52.415 16.9259 55.674 16.5998 56.6519C16.2736 57.6298 11.059 64.7999 10.7333 65.7774C10.4077 66.755 21.8141 77.1844 22.7921 77.1844C23.7701 77.1844 29.3101 70.6663 29.9622 70.6661C30.6142 70.6659 33.2215 72.9473 33.8731 73.5993C34.5247 74.2514 35.5029 82.3988 35.8286 83.3767C36.1543 84.3547 51.4722 84.0287 51.7983 83.3767C52.1245 82.7247 52.7758 74.2514 53.4279 73.5993C54.08 72.9473 57.3391 71.3178 57.9907 71.6439C58.6423 71.97 65.161 76.8583 66.1385 77.1844C67.116 77.5105 76.8939 67.7329 76.8936 66.7552C76.8934 65.7774 71.6788 58.6076 71.679 57.9555C71.6793 57.3035 72.657 54.3703 73.3086 54.0446C73.9601 53.7189 81.7825 53.3926 82.76 53.0669C83.7375 52.7411 84.3894 37.7491 83.7378 36.7712C83.0862 35.7933 73.9601 35.7937 73.3086 35.1417C72.657 34.4896 71.679 32.2086 71.679 31.5566C71.679 30.9046 77.8713 23.4086 77.8713 22.4311C77.8713 21.4535 67.4421 11.0243 66.7903 11.0241C66.1385 11.0239 59.6202 17.5426 58.9684 17.2165C58.3199 16.892 55.4132 14.9548 54.4207 14.2933L54.4056 14.2832C53.4279 13.6316 54.4056 5.48341 53.4279 5.15769C52.4502 4.83197 37.4582 3.5282 36.8063 4.17995C36.1545 4.8317 36.8063 13.9575 35.8286 14.2832C34.8509 14.609 32.5695 15.9126 31.5917 16.2387Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M54.9832 44.7182C55.324 38.521 50.4489 33.1403 44.2439 33.0026C37.9084 32.8621 32.6682 38.3908 33.0164 44.7182C33.3462 50.7111 38.2434 55.3467 44.2439 55.2134C50.1066 55.0833 54.6611 50.5735 54.9832 44.7182Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
    </SvgIcon>
  );
}

export function CloseDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 98 101"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.1294 8.75117C67.0251 5.60457 56.1294 4.25118 56.1294 4.25118C56.1294 4.25118 35.5844 2.39953 25.1294 8.75117C15.6158 14.5309 11.7453 20.9084 7.62943 31.2512C4.34148 39.5135 3.67023 44.8706 4.12943 53.7512C4.678 64.3604 5.65128 71.3315 12.1294 79.7512C17.417 86.6235 30.1294 92.7512 30.1294 92.7512C30.1294 92.7512 43.4942 98.0501 52.1294 96.7512C59.8167 95.5949 63.6243 92.5074 70.1294 88.2512C77.6474 83.3323 82.4346 80.4111 87.1294 72.7512C92.277 64.3526 92.6627 58.0907 93.1294 48.2512C93.5586 39.2032 94.1615 33.3624 90.1294 25.2512C86.0111 16.9665 81.353 12.9902 73.1294 8.75117Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M31.3791 37.7059C32.6089 38.8503 64.1526 61.93 63.9975 63.332"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M61.6555 36.2908C60.4403 37.3946 35.443 66.3028 34.0747 66.0165"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
    </SvgIcon>
  );
}

export function MenuDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 63 66"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.55364 15.039C6.76666 14.7592 57.1723 2.98388 58.4602 4.15259"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M58.5778 28.0248C56.3584 28.2481 5.66881 38.734 4.41113 37.5328"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M4.55364 61.7105C6.76666 61.4307 57.1723 49.6554 58.4602 50.8241"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
    </SvgIcon>
  );
}

export function LightningDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 88 108"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.3514 4.00491C55.2745 4.38948 28.7395 3.23581 25.6633 5.15861C22.5872 7.08141 2.20477 53.6138 4.1277 53.6138C6.05063 53.6138 30.2778 50.9219 32.5855 50.9218C34.8933 50.9218 15.2797 104.761 20.664 103.992C26.0483 103.223 84.8867 32.8473 83.3481 30.5399C81.8094 28.2325 40.2772 35.1547 41.4305 30.5399C42.5839 25.9251 61.4282 3.62035 58.3514 4.00491Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
    </SvgIcon>
  );
}

export function FolderDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 88 77"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.05763 71.9568C3.85066 71.1551 3.45786 5.40318 5.05763 4.60141C6.6574 3.79963 23.5041 3.79943 25.5048 4.60141C27.5055 5.40339 31.9157 13.4218 34.3251 13.4218C36.7346 13.4218 82.4322 11.4173 83.238 13.4218C84.0438 15.4263 84.4447 70.7539 83.238 71.9568C82.0313 73.1597 6.2646 72.7585 5.05763 71.9568Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M5.06201 25.8503H83.6433"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
    </SvgIcon>
  );
}

export function PeopleDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 99 90"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.4133 30.0747C69.4133 30.0747 69.2367 30.1948 69.1236 30.2717M69.1236 30.2717C67.2021 31.0627 66.1656 31.7676 64.7236 33.2637C62.4263 35.6473 62.2423 37.8266 61.1594 40.9548C60.0765 44.0831 58.6362 53.0748 59.6587 54.8364C60.6812 56.5979 61.8912 57.2773 63.7856 58.0254C65.68 58.7734 76.6797 57.3053 81.6065 55.3991C86.5333 53.4929 94.3627 49.0211 93.9873 46.0197C93.6119 43.0183 87.7019 31.8781 84.7955 30.0747C81.8891 28.2714 79.5823 28.397 76.1664 28.574C73.3411 28.7205 71.7054 29.1147 69.1236 30.2717Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M86.213 19.8747C85.4954 22.3537 84.4419 23.6604 82.4612 25.3148C80.2685 27.1463 78.5422 27.7677 75.7081 28.1286C73.3724 28.426 71.956 28.2572 69.7053 27.5658C66.9067 26.7063 64.9093 26.0898 63.3273 23.6265C61.7453 21.1632 61.7531 14.248 64.828 9.74498C66.2897 7.60433 67.3721 6.37221 69.7053 5.24287C72.2326 4.01957 74.0955 3.68351 76.8336 4.30493C78.4004 4.66052 79.2471 5.10419 80.5854 5.99322C83.1489 7.69624 84.3228 9.3248 85.4627 12.1836C86.5804 14.9868 87.0522 16.9759 86.213 19.8747Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M14.1042 42.0041C14.1042 42.0041 13.9277 42.1241 13.8145 42.201M13.8145 42.201C11.893 42.992 10.8565 43.6969 9.41448 45.1931C7.11723 47.5766 6.93321 49.7559 5.85031 52.8842C4.76741 56.0124 3.32713 65.0041 4.34961 66.7657C5.37209 68.5272 6.58212 69.2066 8.47654 69.9547C10.371 70.7028 21.3706 69.2346 26.2974 67.3284C31.2242 65.4222 39.0536 60.9505 38.6782 57.949C38.3028 54.9476 32.3928 43.8074 29.4864 42.0041C26.58 40.2007 24.2732 40.3263 20.8573 40.5034C18.032 40.6498 16.3963 41.0441 13.8145 42.201Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M30.9037 31.8041C30.1861 34.283 29.1326 35.5897 27.1519 37.2441C24.9591 39.0756 23.2329 39.6971 20.3988 40.0579C18.0631 40.3553 16.6467 40.1865 14.3959 39.4952C11.5974 38.6356 9.59996 38.0191 8.01795 35.5558C6.43593 33.0925 6.4438 26.1773 9.51865 21.6743C10.9804 19.5336 12.0628 18.3015 14.3959 17.1722C16.9232 15.9489 18.7861 15.6128 21.5243 16.2343C23.0911 16.5898 23.9378 17.0335 25.276 17.9225C27.8396 19.6256 29.0135 21.2541 30.1533 24.1129C31.271 26.9162 31.7428 28.9053 30.9037 31.8041Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M42.3012 57.1868C42.3012 57.1868 42.1247 57.3068 42.0116 57.3838M42.0116 57.3838C40.09 58.1748 39.0535 58.8796 37.6115 60.3758C35.3142 62.7594 35.1302 64.9386 34.0473 68.0669C32.9644 71.1952 31.5242 80.1869 32.5466 81.9484C33.5691 83.7099 34.7791 84.3893 36.6736 85.1374C38.568 85.8855 49.5676 84.4174 54.4944 82.5112C59.4212 80.605 67.2507 76.1332 66.8752 73.1318C66.4998 70.1304 60.5898 58.9902 57.6834 57.1868C54.777 55.3834 52.4702 55.5091 49.0544 55.6861C46.229 55.8325 44.5933 56.2268 42.0116 57.3838Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M59.1007 46.9868C58.3831 49.4657 57.3296 50.7725 55.3489 52.4268C53.1562 54.2584 51.4299 54.8798 48.5958 55.2407C46.2601 55.5381 44.8437 55.3692 42.593 54.6779C39.7944 53.8183 37.797 53.2018 36.215 50.7386C34.6329 48.2753 34.6408 41.36 37.7157 36.857C39.1774 34.7164 40.2598 33.4843 42.593 32.3549C45.1202 31.1316 46.9831 30.7956 49.7213 31.417C51.2881 31.7726 52.1348 32.2163 53.4731 33.1053C56.0366 34.8083 57.2105 36.4369 58.3503 39.2957C59.468 42.0989 59.9399 44.088 59.1007 46.9868Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <rect
        x="56.0552"
        y="52.8019"
        width="2.16897"
        height="2.71121"
        fill="black"
      />
      <path
        d="M32.2559 38.9639C32.4869 38.2708 32.922 37.602 32.922 36.8823"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M32.0894 41.0457C32.0894 40.1618 31.9229 39.3102 31.9229 38.4274C31.9229 38.1761 31.9229 37.9248 31.9229 37.6734C31.9229 37.0394 32.8958 37.0047 33.3522 36.8454C33.7138 36.7193 34.0728 36.5193 33.9027 37.0675C33.7484 37.5646 33.7914 38.0489 33.6066 38.5292C33.3738 39.1346 32.9677 37.9251 32.9405 37.7891C32.8156 37.1643 32.7665 36.5388 32.6075 35.9203C32.573 35.7863 32.6078 35.9155 32.5057 35.9665"
        stroke={props.hex || "black"}
        strokeWidth="3"
        strokeLinecap="round"
        fill="none"
      />
    </SvgIcon>
  );
}

export function PersonDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 66 98"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7707 47.2782C20.7707 47.2782 20.4777 47.4774 20.2899 47.6051M20.2899 47.6051C17.1006 48.918 15.3802 50.0879 12.9868 52.5712C9.17389 56.5274 8.86847 60.1445 7.0711 65.3367C5.27373 70.5289 2.88319 85.4531 4.58027 88.3769C6.27735 91.3006 8.28574 92.4282 11.43 93.6699C14.5744 94.9115 32.8313 92.4748 41.0086 89.3109C49.186 86.1471 62.1811 78.7249 61.558 73.7432C60.9348 68.7616 51.1256 50.2714 46.3016 47.2782C41.4776 44.285 37.6489 44.4935 31.9794 44.7874C27.2899 45.0304 24.5751 45.6848 20.2899 47.6051Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M48.6541 30.3485C47.463 34.4629 45.7145 36.6318 42.427 39.3777C38.7875 42.4176 35.9224 43.4491 31.2183 44.048C27.3416 44.5416 24.9907 44.2614 21.255 43.1139C16.6101 41.6873 13.2948 40.664 10.669 36.5755C8.04317 32.487 8.05624 21.0093 13.1598 13.5354C15.5859 9.98237 17.3825 7.93734 21.255 6.06288C25.4497 4.03248 28.5417 3.47469 33.0864 4.50612C35.687 5.09632 37.0922 5.8327 39.3135 7.3083C43.5684 10.1349 45.5167 12.838 47.4087 17.583C49.2638 22.2357 50.0469 25.5371 48.6541 30.3485Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
    </SvgIcon>
  );
}

export function AddDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 98 101"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.1294 8.75117C67.0251 5.60457 56.1294 4.25118 56.1294 4.25118C56.1294 4.25118 35.5844 2.39953 25.1294 8.75117C15.6158 14.5309 11.7453 20.9084 7.62943 31.2512C4.34148 39.5135 3.67023 44.8706 4.12943 53.7512C4.678 64.3604 5.65128 71.3315 12.1294 79.7512C17.417 86.6235 30.1294 92.7512 30.1294 92.7512C30.1294 92.7512 43.4942 98.0501 52.1294 96.7512C59.8167 95.5949 63.6243 92.5074 70.1294 88.2512C77.6474 83.3323 82.4346 80.4111 87.1294 72.7512C92.277 64.3526 92.6627 58.0907 93.1294 48.2512C93.5586 39.2032 94.1615 33.3624 90.1294 25.2512C86.0111 16.9665 81.353 12.9902 73.1294 8.75117Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M27.4745 54.9457C29.1401 54.7268 67.026 45.1182 68.0079 46.1309"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M49.7007 30.9998C49.4114 32.6158 47.2446 70.7715 45.9858 71.3795"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
    </SvgIcon>
  );
}
export function ChartDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 97 91"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M91.1546 5.05877C86.2658 4.61441 8.02273 1.94716 6.24311 7.28158C4.46349 12.616 2.24106 79.7452 6.24311 84.6356C10.2452 89.5259 87.1522 85.0799 88.4872 83.3019C89.8223 81.5238 96.0434 5.50312 91.1546 5.05877Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M20.5 70.5C20.5 70.5 36 49.5 37 48.5C38 47.5 52 55 53.5 54C55 53 72.0343 29.2416 72.5 27.5"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
    </SvgIcon>
  );
}

export function HeartDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 97 86"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.4961 5.17159C30.5344 5.93132 48.0094 16.5675 49.1484 16.5675C49.1484 16.5675 79.1576 -0.554077 86.4305 5.17157C93.7034 10.8972 94.2967 17.2996 91.3133 23.7849C88.3299 30.2703 60.9796 82.6319 46.8692 81.5243C32.7587 80.4166 12.6815 46.9566 8.12303 39.3594C3.56458 31.7621 0.187961 10.2051 12.6814 5.17159C18.0477 3.00953 24.4578 4.41186 27.4961 5.17159Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
    </SvgIcon>
  );
}

export function CollectionDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 75 73"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.375 3.125H7.83333C5.78528 3.125 4.125 4.78528 4.125 6.83333V25.375C4.125 27.4231 5.78528 29.0833 7.83333 29.0833H26.375C28.4231 29.0833 30.0833 27.4231 30.0833 25.375V6.83333C30.0833 4.78528 28.4231 3.125 26.375 3.125Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M26.375 43.9165H7.83333C5.78528 43.9165 4.125 45.5768 4.125 47.6248V66.1665C4.125 68.2146 5.78528 69.8748 7.83333 69.8748H26.375C28.4231 69.8748 30.0833 68.2146 30.0833 66.1665V47.6248C30.0833 45.5768 28.4231 43.9165 26.375 43.9165Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M67.1665 3.125H48.6248C46.5768 3.125 44.9165 4.78528 44.9165 6.83333V25.375C44.9165 27.4231 46.5768 29.0833 48.6248 29.0833H67.1665C69.2146 29.0833 70.8748 27.4231 70.8748 25.375V6.83333C70.8748 4.78528 69.2146 3.125 67.1665 3.125Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M57.896 43.9175V69.8766"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M70.8752 56.8962H44.9165"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M47.0002 56.0961C47.9166 56.2451 70.0541 55.2005 70.2758 56.3813"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M58.6745 45C58.5081 45.9134 59.1299 68.0669 57.945 68.2659"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M28.4816 4.29741C27.1084 4.17259 5.12998 3.42336 4.63009 4.92179C4.13019 6.42023 3.50592 25.2767 4.63009 26.6504C5.75426 28.0241 27.3573 26.7753 27.7324 26.2758C28.1074 25.7764 29.8549 4.42223 28.4816 4.29741Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M68.4816 4.29741C67.1084 4.17259 45.13 3.42336 44.6301 4.92179C44.1302 6.42023 43.5059 25.2767 44.6301 26.6504C45.7543 28.0241 67.3573 26.7753 67.7324 26.2758C68.1074 25.7764 69.8549 4.42223 68.4816 4.29741Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M28.4816 45.2974C27.1084 45.1726 5.12998 44.4234 4.63009 45.9218C4.13019 47.4202 3.50592 66.2767 4.63009 67.6504C5.75426 69.0241 27.3573 67.7753 27.7324 67.2758C28.1074 66.7764 29.8549 45.4222 28.4816 45.2974Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
    </SvgIcon>
  );
}

export function PlayDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 98 101"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.1294 8.75117C67.0251 5.60457 56.1294 4.25118 56.1294 4.25118C56.1294 4.25118 35.5844 2.39953 25.1294 8.75117C15.6158 14.5309 11.7453 20.9084 7.62943 31.2512C4.34148 39.5135 3.67023 44.8706 4.12943 53.7512C4.678 64.3604 5.65128 71.3315 12.1294 79.7512C17.417 86.6235 30.1294 92.7512 30.1294 92.7512C30.1294 92.7512 43.4942 98.0501 52.1294 96.7512C59.8167 95.5949 63.6243 92.5074 70.1294 88.2512C77.6474 83.3323 82.4346 80.4111 87.1294 72.7512C92.277 64.3526 92.6627 58.0907 93.1294 48.2512C93.5586 39.2032 94.1615 33.3624 90.1294 25.2512C86.0111 16.9665 81.353 12.9902 73.1294 8.75117Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M33.3249 36.4985C34.6267 36.9014 66.4334 41.1577 66.6594 42.6051"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M37.8219 62.1582C37.7473 61.1267 32.6665 37.4891 33.4572 36.9242"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M38.4751 62.4473C39.5046 61.5545 66.9071 44.8546 66.5314 43.4386"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
    </SvgIcon>
  );
}

export function QuestionDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 98 101"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.1294 8.75117C67.0251 5.60457 56.1294 4.25118 56.1294 4.25118C56.1294 4.25118 35.5844 2.39953 25.1294 8.75117C15.6158 14.5309 11.7453 20.9084 7.62943 31.2512C4.34148 39.5135 3.67023 44.8706 4.12943 53.7512C4.678 64.3604 5.65128 71.3315 12.1294 79.7512C17.417 86.6235 30.1294 92.7512 30.1294 92.7512C30.1294 92.7512 43.4942 98.0501 52.1294 96.7512C59.8167 95.5949 63.6243 92.5074 70.1294 88.2512C77.6474 83.3323 82.4346 80.4111 87.1294 72.7512C92.277 64.3526 92.6627 58.0907 93.1294 48.2512C93.5586 39.2032 94.1615 33.3624 90.1294 25.2512C86.0111 16.9665 81.353 12.9902 73.1294 8.75117Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M47.7447 59.1479C48.1898 57.0414 48.5877 49.8479 48.4797 47.6714"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M46.7227 73.8243L48.0382 72.9494"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M41.0381 38.5362C41.0381 38.5362 40.4661 30.7289 46.7415 27.3664C53.0168 24.0038 56.9642 25.9173 59.1131 29.7575C61.262 33.5978 62.3357 44.4756 57.8406 46.2489C53.3454 48.0222 48.6284 47.7551 48.6284 47.7551"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
    </SvgIcon>
  );
}

export function DeleteDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 98 101"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.1294 8.75117C67.0251 5.60457 56.1294 4.25118 56.1294 4.25118C56.1294 4.25118 35.5844 2.39953 25.1294 8.75117C15.6158 14.5309 11.7453 20.9084 7.62943 31.2512C4.34148 39.5135 3.67023 44.8706 4.12943 53.7512C4.678 64.3604 5.65128 71.3315 12.1294 79.7512C17.417 86.6235 30.1294 92.7512 30.1294 92.7512C30.1294 92.7512 43.4942 98.0501 52.1294 96.7512C59.8167 95.5949 63.6243 92.5074 70.1294 88.2512C77.6474 83.3323 82.4346 80.4111 87.1294 72.7512C92.277 64.3526 92.6627 58.0907 93.1294 48.2512C93.5586 39.2032 94.1615 33.3624 90.1294 25.2512C86.0111 16.9665 81.353 12.9902 73.1294 8.75117Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
      />
      <path
        d="M25.4999 54.5C27.3966 54.2601 70.597 44.1681 71.7008 45.1697"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export function EditDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 92 90"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9414 15.7764C38.9414 15.7764 9.15295 14.8277 6.04834 16.3805C2.94372 17.9334 3.71802 81.6044 6.04834 83.9338C8.37866 86.2632 74.8735 86.0909 77.484 83.9338C80.0945 81.7768 78.5416 50.7178 78.5416 50.7178"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M46.9982 55.5C45.4982 56.5 29.6319 65.2959 27.5 63C25.3681 60.7041 36.0005 46.8203 36.4924 46C36.9843 45.1796 70.9924 8.99964 72.4924 7.99982C73.9924 7 77.6039 2.40768 84.4924 7.00001C88.2646 11.2643 87.4446 14.0633 86.4605 15.7037C85.4765 17.344 48.4982 54.5 46.9982 55.5Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M68.582 11.4388L81.7029 24.2317"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M34.998 44L45.998 56"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
    </SvgIcon>
  );
}

export function InfoDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 98 101"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.1294 8.75117C67.0251 5.60457 56.1294 4.25118 56.1294 4.25118C56.1294 4.25118 35.5844 2.39953 25.1294 8.75117C15.6158 14.5309 11.7453 20.9084 7.62943 31.2512C4.34148 39.5135 3.67023 44.8706 4.12943 53.7512C4.678 64.3604 5.65128 71.3315 12.1294 79.7512C17.417 86.6235 30.1294 92.7512 30.1294 92.7512C30.1294 92.7512 43.4942 98.0501 52.1294 96.7512C59.8167 95.5949 63.6243 92.5074 70.1294 88.2512C77.6474 83.3323 82.4346 80.4111 87.1294 72.7512C92.277 64.3526 92.6627 58.0907 93.1294 48.2512C93.5586 39.2032 94.1615 33.3624 90.1294 25.2512C86.0111 16.9665 81.353 12.9902 73.1294 8.75117Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M43.7633 76.3143C45.7472 70.0421 48.8228 48.1965 48.9998 41.4999"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M50.1299 27.1824L52.0023 25.7781"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
    </SvgIcon>
  );
}

export function LocationDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 83 109"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.0467 86.6809C54.9113 94.7671 45.6856 104.508 42.2315 104.695C38.7774 104.882 35.0585 102.481 35.0585 102.481C35.0585 102.481 22.3337 85.8267 16.0642 74.0608C11.4952 65.4863 7.99606 60.8132 5.99844 51.3325L5.95414 51.1223C5.30152 48.0264 4.91569 46.1961 4.8482 42.9762C4.69086 35.4692 5.99648 30.9654 9.52765 24.3174C13.3138 17.1895 23.6208 9.10751 23.6208 9.10751C23.6208 9.10751 30.3198 6.21386 34.8891 5.46957C43.6122 4.04867 49.4926 4.3589 57.344 8.38097C63.6435 11.608 66.452 14.9792 70.5651 20.6848C75.2515 27.1855 77.5633 31.6249 78.5104 39.542C79.6092 48.7273 76.683 53.9824 73.6248 62.7207C70.1386 72.6818 67.4474 78.2451 61.0467 86.6809Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M51.6225 37.1464C51.0801 34.3774 49.9003 32.752 47.7758 30.5838C45.9252 28.695 43.3416 27.0237 42.1333 26.8257C40.925 26.6276 37.3488 28.4181 35.1162 30.4566C32.7499 32.6172 31.0162 34.1028 30.8593 37.4618C30.6909 41.0666 31.8176 43.9971 34.961 46.5453C37.2834 48.4281 39.2264 49.1034 42.0114 49.1175C45.5685 49.1356 47.4173 47.5685 49.4054 45.0171C51.3253 42.5532 52.2797 40.501 51.6225 37.1464Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
    </SvgIcon>
  );
}

export function PreviewDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 83 80"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9414 5.77643C38.9414 5.77643 9.15295 4.8277 6.04834 6.38054C2.94372 7.93338 3.71802 71.6044 6.04834 73.9338C8.37866 76.2632 74.8735 76.0909 77.484 73.9338C80.0945 71.7768 78.5416 40.7178 78.5416 40.7178"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M27.791 54.5218C23.1323 59.1807 59.1584 25.1808 78.262 6.38037M78.262 6.38037C69.7208 3.27453 51.8617 6.3804 51.8617 6.3804M78.262 6.38037C79.8151 13.3687 78.262 21.91 76.7089 30.4512"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M27.791 54.5219L27.9652 54.6525"
        stroke={props.hex || "black"}
        strokeWidth="8"
        strokeLinecap="round"
        fill="none"
      />
    </SvgIcon>
  );
}

export function ShieldDrwIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      sx={{ cursor: "pointer" }}
      viewBox={props.viewBox || "0 0 98 100"}
      fill={props.color || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99834 25.6763C5.99901 23.996 31.5768 5.27703 47.41 4.99893C63.8718 4.7098 91.9979 23.3526 92.9984 25.6763C93.9988 28 83.9926 67.3988 79.9984 74.1994C76.0041 81 58.4988 95.996 47.41 95.996C36.3212 95.996 18.5 81.5 14.4983 74.1994C10.4967 66.8988 3.99768 27.3567 4.99834 25.6763Z"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
      <path
        d="M28.9985 47.927C28.9985 47.927 43.7112 59.881 44.6307 60.3408C45.5503 60.8006 68.9985 29.996 68.9985 29.996"
        stroke={props.hex || "black"}
        strokeWidth="8"
        fill="none"
      />
    </SvgIcon>
  );
}
