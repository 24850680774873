import React from "react";
import { Grid, Stack, Typography } from "@mui/material";

export interface TileColumn {
  title: string;
  detail: JSX.Element;
}

export interface SumTileProps {
  title: string;
  detail?: string[];
  columns?: TileColumn[];
}

export const SummaryTile = (props: SumTileProps): JSX.Element => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack>
          <Typography sx={{ fontWeight: "bold" }}>{props.title}</Typography>
          {props.detail &&
            props.detail.map((item: string) => {
              return <Typography key={item}> {item} </Typography>;
            })}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {props.columns &&
          props.columns.map((column: TileColumn) => {
            return (
              <div key={column.title}>
                <Typography> {column.title} </Typography>
                {column.detail}
              </div>
            );
          })}
      </Grid>
    </Grid>
  );
};
