import {
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import {
  Question,
  QuestionIn,
  QuestionLink,
  QuestionLinkIn,
} from "../../types";
import { Queries, sendRequest } from "./api";
import { ListResource } from "../api";

export const useQuestionCreate = (
  pParams: { [name: string]: string }
) => {
  return useMutation({
    mutationKey: Queries.QuestionCreate.key,
    mutationFn: (data: QuestionIn) =>
      sendRequest<Question>(Queries.QuestionCreate.endpoint, { body: data, pathParams: pParams }),
  })
};

export const useQuestionLinkCreate = (
  pParams: { [name: string]: string }
) => {
  return useMutation({
    mutationKey: Queries.QuestionLinkCreate.key,
    mutationFn: (data: QuestionLinkIn) =>
      sendRequest<QuestionLink>(Queries.QuestionLinkCreate.endpoint, { body: data, pathParams: pParams }),
  })
};

export const useQuestionCreateAndLink = (
  pParams: { [name: string]: string }
) => {
  const link = useQuestionLinkCreate({
    company: pParams.company,
    campaign: pParams.campaign_id,
  });
  return useMutation({
    mutationKey: Queries.QuestionCreate.key,
    mutationFn: (data: QuestionIn) =>
      sendRequest<Question>(Queries.QuestionCreate.endpoint, { body: data, pathParams: pParams }),
    onSuccess: async (data) => {
      console.log('campaign: ', pParams.campaign_id, data.id);
      const linkdata: QuestionLinkIn = {
        customer_id: data.customer_id,
        campaign_id: parseInt(pParams.campaign_id),
        question_id: `${data.id}`,
      };
      link.mutate(linkdata);
    }
  })
};

export const useQuestionList = (
  pParams: { [name: string]: any },
  qParams: { [name: string]: any }
) => {
  return useQuery({
    queryKey: Queries.QuestionList.key.concat(Object.values(pParams)).concat(Object.values(qParams)),
    queryFn: () =>
      sendRequest<ListResource>(Queries.QuestionList.endpoint, { pathParams: pParams, queryParams: qParams }),
  })
};
