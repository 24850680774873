import React, { useState } from 'react';
import { Box, Grid, Modal } from '@mui/material';
import { CloseButton } from './CloseButton';

export interface ModalProps {
  children: React.ReactNode;
  size?: 'small' | 'large';
  onClose?: () => void;
  backgroundColor?: string;
  closeIconColor?: string;
}

export const PopupModal = (props: ModalProps): JSX.Element => {
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const handleModalClose = () => setModalOpen(false);
  const getSizes = (type: string) => {
    switch (type) {
      case 'minWidth':
        return props.size === 'small' ? { xs: '60%', md: '60%' } : '100%';
      case 'maxWidth':
        return props.size === 'small' ? { xs: '85%', md: '75%' } : '100%';
      case 'minHeight':
        return props.size === 'small' ? { xs: '30%', md: '30%' } : '100%';
      case 'maxHeight':
        return props.size === 'small' ? { xs: '50%', md: '50%' } : '100%';
      default:
        return props.size === 'small' ? { xs: '85%', md: '75%' } : '100%';
    }
  };
  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' } }}
    >
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: props.size === 'small' ? '30%' : '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: props.backgroundColor || 'background.paper',
          borderRadius: props.size === 'small' ? 3 : 0,
          minWidth: getSizes('minWidth'),
          maxWidth: getSizes('maxWidth'),
          minHeight: getSizes('minHeight'),
          maxHeight: getSizes('maxHeight'),
          overflow: 'auto',
          outline: 'none',
          '&::-webkit-scrollbar': { width: 8 },
          '&::-webkit-scrollbar-track': {
            background: '#F8F8F8',
            borderRadius: 5,
          },
          '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: 5 },
          '&::-webkit-scrollbar:horizontal': {
            height: 0,
            width: 0,
            display: 'none',
          },
        }}
      >
        <Grid container sx={{ padding: 2 }} spacing={5} justifyContent="center">
          <Grid
            item
            container
            xs={12}
            direction="column"
            alignItems="flex-end"
            sx={{ position: 'relative', top: -30, right: -20 }}
          >
            <CloseButton onclick={handleModalClose} />
          </Grid>
          {props.children}
        </Grid>
      </Box>
    </Modal>
  );
};
