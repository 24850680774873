import React, { useEffect, useState } from 'react';
import { Alert, Button, Grid, Typography, TextField } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { APIRequests } from '../config/api';
import { RequestInput } from '../types/config';

import useAPI from '../utils/useApi';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const SetPassword = (): JSX.Element => {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const setPasswordPost = useAPI<{}>(APIRequests.LoginVerify);
  let query = useQuery();

  const handleSubmit = async (_: React.MouseEvent) => {
    const token = query.get('token');
    if (token) {
      const req: RequestInput = {
        pathParams: { token: token },
        body: { password: password },
      };
      setPasswordPost.execute(req);
    }
  };

  useEffect(() => {
    if (setPasswordPost.data) {
      navigate('/login');
    }
    if (setPasswordPost.error) {
      setError(setPasswordPost.error[0]);
    }
  }, [setPasswordPost.data, setPasswordPost.error]);

  const checkPasswordMatch = (value: string) => {
    if (value !== password) {
      setError('Passwords do not match');
    } else {
      setError('');
    }
  };

  useEffect(() => {
    if (!query.get('token')) {
      navigate('/login');
    }
  }, []);

  return (
    <>
      {query.get('token') && (
        <Grid container justifyContent="center">
          <Grid
            item
            xs={4}
            direction="column"
            container
            justifyContent="center"
            sx={{
              backgroundImage:
                'linear-gradient(146deg, rgba(249, 225, 255, 0.7) 17%, rgba(225, 244, 255, 0.8))',
              minHeight: '100vh',
              textAlign: 'center',
              display: 'flex',
            }}
          >
            <img
              src="/images/logo-block-purple.png"
              alt="Jamyr Logo"
              style={{
                width: '50%',
                margin: 'auto',
                display: 'block',
              }}
            />
          </Grid>
          <Grid
            item
            xs={8}
            container
            justifyContent="center"
            sx={{
              padding: { xs: 10, sm: 10, md: 20, lg: 30 },
            }}
          >
            <Grid item xs={12} container justifyContent="center">
              <Typography variant="h4" color="primary">
                Set Password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="password"
                label="Password"
                type="text"
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.currentTarget.value.trim())
                }
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="confirm-password"
                label="Confirm Password"
                type="text"
                onChange={(e) => checkPasswordMatch(e.target.value)}
                required
                fullWidth
              />
            </Grid>
            <Grid container alignItems="center">
              {error && (
                <Grid item>
                  <Alert severity="error">{error}</Alert>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ width: '100%' }}
              >
                Set Password
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
