import React, { useEffect, useState } from 'react';
import { Box, Divider, List, ListItem } from '@mui/material';
import { JamyrSwitch, JamyrTextField } from '../atoms';

export interface ATSOption {
  title: string;
  disabled: boolean;
  description?: string;
  content?: JSX.Element;
}

export type ATSSettingProps = {
  data: any;
  onUpdate: (data: any) => void;
};

export const ATSSettings = (props: ATSSettingProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<ATSOption | undefined>();
  const [initializing, setInitializing] = useState<boolean>(true);
  const handleChange = (field: string, value: string) => {
    props.data[field] = value;
    if (props.onUpdate) {
      props.onUpdate(props.data);
    }
  };
  const greenhouseOptions = (): JSX.Element => {
    return (
      <Box sx={{ borderRadius: 5, mb: 3 }}>
        <JamyrTextField
          name="greenhouse_jobboard_token"
          changehandler={handleChange}
          placeholder=""
          label="Job Board Token"
          currentvalue={props.data.greenhouse_jobboard_token}
        />
      </Box>
    );
  };

  const options: ATSOption[] = [
    { title: '', disabled: false, content: greenhouseOptions() },
  ];

  useEffect(() => {
    setSelectedOption(options[0]);
    setInitializing(false);
  }, []);

  const onSelect = (value: ATSOption) => {
    if (!initializing) {
      // Hack to avoid updating before first initializing
      if (value.title === selectedOption?.title) {
        setSelectedOption(undefined);
        handleChange('greenhouse_jobboard_token', '');
      } else {
        setSelectedOption(
          options.filter((option) => option.title === value.title)[0]
        );
      }
    }
  };

  return (
    <>
      <List>
        {options.map((option: ATSOption) => {
          const optionText = option.disabled
            ? 'Coming Soon!'
            : option.title === selectedOption?.title
            ? 'Enabled'
            : '';
          return (
            <div key={option.title}>
              <ListItem>
                {/* <ListItemText
                  primary={option.title}
                  secondary={option.description}
                  sx={{ fontWeight: "bold" }}
                /> */}
                <JamyrSwitch
                  label={optionText}
                  onChange={() => onSelect(option)}
                  defaultChecked={option.title === selectedOption?.title}
                  disabled={option.disabled}
                />
              </ListItem>
              {option.title === selectedOption?.title && option.content}
              <Divider />
            </div>
          );
        })}
      </List>
    </>
  );
};
