import { Testimonial } from "./testimonial";

export interface Collection {
  id: number;
  customer_id: number;
  external_id: string;
  created_at: string;
  updated_at: string;
  description: string;
  title: string;
  heading_text: string;
  company_name: string;
  campaign_id: number;
  cta_title: string;
  cta_link: string;
  cta_btn_text: string;
  secondary_cta_link: string;
  secondary_cta_btn_text: string;
  page_style: string;
  hide_question_text: boolean;
}

export interface CollectionLink {
  id: number;
  external_id: string;
  created_at: string;
  updated_at: string;
  collection_id: number;
  testimonial_id: number;
  position: number;
  testimonial: Testimonial;
}

export enum CollectionPageFormat {
  "video-guide" = "video-guide",
  tile = "tile"
}