import React, { useState, useEffect } from 'react';
import {
  ColumnSort,
  JamyrTable,
  TableProps,
  TableAction,
  PopupModal,
} from '../../atoms';
import { TableCard, UserCreate } from '../../components';
import { DeleteConfirm } from '../../components/Forms/DeleteConfirm';
import { useModal } from 'mui-modal-provider';
import useAPI from '../../utils/useApi';
import { toast } from 'react-toastify';
import { APIRequests, ListResource } from '../../config/api';
import { RequestInput } from '../../types/config';
import { User } from '../../types/user';
import {
  useCurrentCompany,
  useGlobalState,
} from '../../utils/GlobalStateProvider';
import { isMobile } from '../../utils/global';

export const UserAdmins = (): JSX.Element => {
  const [tableData, setTableData] = useState<{ [name: string]: string }[]>();
  const [fetchData, setFetchData] = useState<boolean>(false);
  const [sortValue, setSortValue] = useState<ColumnSort>();
  const admins = useAPI<ListResource>(APIRequests.UsersList);
  const userToDelete = useAPI(APIRequests.UserDelete);
  const context = useGlobalState(true);
  const company = useCurrentCompany();
  const { showModal } = useModal();

  useEffect(() => {
    let sortParams;
    if (sortValue) {
      sortParams = {
        sort: sortValue.field,
        sortDir: sortValue.direction,
        company: company,
      };
    } else {
      sortParams = {
        company: company,
      };
    }
    if (company || fetchData) {
      const req: RequestInput = {
        queryParams: sortParams,
      };
      admins.execute(req);
      if (fetchData) {
        setFetchData(false);
      }
    }
  }, [context.state.company, fetchData, sortValue]);

  useEffect(() => {
    if (admins.data) {
      setTableData((admins.data.records as { [name: string]: string }[]) || []);
    }
  }, [admins.data]);

  const createAdmin = () => {
    const modal = showModal(PopupModal, {
      children: (
        <UserCreate
          onComplete={() => {
            modal.destroy();
            setFetchData(true);
          }}
        />
      ),
      size: 'large',
    });
  };

  useEffect(() => {
    if (userToDelete.data) {
      toast('🦄 User Deleted!');
    }
    if (userToDelete.error) {
      toast.error(String(userToDelete.error));
    }
  }, [userToDelete.data, userToDelete.error]);

  const handleDelete = (user: User, onComplete: () => void) => {
    const req: RequestInput = {
      pathParams: { userid: user.id },
    };
    userToDelete.execute(req);
    onComplete();
    setFetchData(true);
  };

  const handleDeleteClick = (idx: number) => {
    if (tableData) {
      const user: User = tableData[idx] as unknown as User;
      const modal = showModal(PopupModal, {
        children: (
          <DeleteConfirm
            title="Delete User"
            warning={`Are you sure you want to delete ${user.email}?`}
            callback={() =>
              handleDelete(user, () => {
                modal.destroy();
              })
            }
          />
        ),
        size: isMobile() ? 'large' : 'small',
      });
    }
  };

  const actions: TableAction[] = [
    {
      header: 'Delete',
      icon: 'delete',
      onClick: handleDeleteClick,
    },
  ];

  const tableProps: TableProps = {
    columns: [
      {
        name: 'first_name',
        label: 'First Name',
        sorted:
          sortValue && sortValue.field === 'first_name'
            ? sortValue.direction
            : undefined,
      },
      {
        name: 'last_name',
        label: 'Last Name',
        sorted:
          sortValue && sortValue.field === 'last_name'
            ? sortValue.direction
            : undefined,
      },
      {
        name: 'email',
        label: 'Email',
        sorted:
          sortValue && sortValue.field === 'email'
            ? sortValue.direction
            : undefined,
      },
    ],
    rows: tableData,
    actions: actions,
    sortData: (data: ColumnSort) => setSortValue(data),
  };
  return (
    <>
      <TableCard
        title="Admins"
        actionClick={createAdmin}
        actionLabel="Add"
        actionIcon="add"
      >
        <JamyrTable {...tableProps} />
      </TableCard>
    </>
  );
};
