import React, { useState, useEffect } from 'react';
import { useModal } from 'mui-modal-provider';
import { useGlobalState } from '../../utils/GlobalStateProvider';
import useAPI from '../../utils/useApi';
import { toast } from 'react-toastify';

import { APIRequests, ListResource } from '../../config/api';
import { RequestInput } from '../../types/config';
import { User } from '../../types/user';

import { TableCard, UserCreate } from '../../components';
import { JamyrTable, TableProps, TableAction, PopupModal } from '../../atoms';
import { DeleteConfirm } from '../../components/Forms/DeleteConfirm';

export const Admins = (): JSX.Element => {
  const [tableData, setTableData] = useState<{ [name: string]: string }[]>();
  const [fetchData, setFetchData] = useState<boolean>(false);
  const admins = useAPI<ListResource>(APIRequests.UsersList);
  const userToDelete = useAPI(APIRequests.UserDelete);
  const context = useGlobalState(true);
  const { showModal } = useModal();

  useEffect(() => {
    if (context.state.user || fetchData) {
      const req: RequestInput = {
        queryParams: { company: context.state.user.customer_id },
      };
      admins.execute(req);
      if (fetchData) {
        setFetchData(false);
      }
    }
  }, [context.state.user, fetchData]);

  useEffect(() => {
    if (admins.data) {
      setTableData(admins.data.records as { [name: string]: string }[]);
    }
  }, [admins.data]);

  const createAdmin = () => {
    const modal = showModal(PopupModal, {
      children: (
        <UserCreate
          onComplete={() => {
            modal.destroy();
            setFetchData(true);
          }}
        />
      ),
      size: 'small',
    });
  };

  useEffect(() => {
    if (userToDelete.data) {
      toast('🦄 User Deleted!');
    }
    if (userToDelete.error) {
      toast.error(String(userToDelete.error));
    }
  }, [userToDelete.data, userToDelete.error]);

  const handleDelete = (user: User, onComplete: () => void) => {
    const req: RequestInput = {
      pathParams: { userid: user.id },
    };
    userToDelete.execute(req);
    onComplete();
    setFetchData(true);
  };

  const handleDeleteClick = (idx: number) => {
    if (tableData) {
      const user: User = tableData[idx] as unknown as User;
      const modal = showModal(PopupModal, {
        children: (
          <DeleteConfirm
            title="Delete User"
            warning={`Are you sure you want to delete ${user.email}?`}
            callback={() =>
              handleDelete(user, () => {
                modal.destroy();
              })
            }
          />
        ),
        size: 'small',
      });
    }
  };

  const actions: TableAction[] = [
    {
      header: 'Delete',
      icon: 'delete',
      onClick: handleDeleteClick,
    },
  ];

  const tableProps: TableProps = {
    columns: [
      { name: 'first_name', label: 'First Name' },
      { name: 'last_name', label: 'Last Name' },
      { name: 'email', label: 'Email' },
      { name: 'scopes', label: 'Admin Type' },
    ],
    rows: tableData,
    actions: actions,
  };

  return (
    <>
      <TableCard
        title="Master Admins"
        actionClick={createAdmin}
        actionLabel="Add"
        actionIcon="add"
      >
        <JamyrTable {...tableProps} />
      </TableCard>
    </>
  );
};
