import React, { useState } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  Typography,
  Button,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { PublicGet } from '../config/api';
import { Facet, Job, JobList as JobListType } from '../types';
import { isMobile } from '../utils/global';
import { useNavigate } from 'react-router-dom';

interface JobListProps {
  joblist: PublicGet;
}

const JobList: React.FC<JobListProps> = ({ joblist }) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleLocationChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedLocation(event.target.value as string);
  };

  const handleDepartmentChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedDepartment(event.target.value as string);
  };

  const handleJobClick = (jobId: string) => {
    navigate(jobId);
  };

  const renderFilter = (facet: Facet) => {
    if (facet.descriptor) {
      return (
        <Grid item xs={12} sm={6} md={4} key={facet.descriptor}>
          <Select
            value={selectedLocation}
            // onChange={handleLocationChange}
            fullWidth
            displayEmpty
          >
            <MenuItem value="">Select {facet.descriptor}</MenuItem>
            {facet.values.map((item) => {
              return <MenuItem value={item.id}>{item.descriptor}</MenuItem>;
            })}
          </Select>
        </Grid>
      );
    }
  };

  return (
    <Box py={4} sx={{ px: { xs: 0, xl: 50 } }} m="auto">
      <Grid item sx={{ textAlign: 'center', mb: 5 }}>
        <img
          src={joblist.settings.default_company_logo}
          alt="company logo"
          crossOrigin="anonymous"
          width={isMobile() ? '200px' : '400px'}
        />
        <Typography variant="h4">{joblist.settings.gallery_header}</Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ mb: 5, px: { xs: 2, sm: 0 } }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={handleSearchChange}
            fullWidth
          />
        </Grid>
        <>
          {joblist.data?.facets?.map((item: Facet) => {
            return renderFilter(item);
          })}
        </>
      </Grid>
      {joblist.data?.jobPostings && joblist.data.jobPostings.length > 0 ? (
        <List sx={{ px: { xs: 2, sm: 0 } }}>
          {joblist.data.jobPostings.map((job: Job) => (
            <ListItem
              key={job.title}
              onClick={() => handleJobClick(job.externalPath)}
              sx={{ border: '1px solid grey', borderRadius: 5, my: 2 }}
              secondaryAction={
                <Button
                  sx={{
                    borderRadius: 10,
                    minWidth: { xs: 50, lg: 150 },
                    lineHeight: 2.5,
                  }}
                  variant="outlined"
                >
                  Learn More
                </Button>
              }
            >
              <ListItemText>
                <Typography variant="h6" component="h3">
                  {job.title}
                </Typography>
                {/* <Typography variant="body1">
                  Location: {job.locationsText}
                </Typography> */}
                <Typography variant="body1">
                  {job.bulletFields.join(' | ')}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1">No jobs found.</Typography>
      )}
    </Box>
  );
};

export default JobList;
