import React, { useState, useEffect } from 'react';
import useAPI from '../../utils/useApi';
import {
  useGlobalState,
  useCurrentCompany,
} from '../../utils/GlobalStateProvider';
import { APIRequests } from '../../config/api';
import { Form, FieldConfig } from '../../atoms/Form';
import { Collection } from '../../types/collection';
import { RequestInput, CreateFormProps } from '../../types/config';
import { toast } from 'react-toastify';

export const DirectUpload = (props: CreateFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const context = useGlobalState();
  const company = useCurrentCompany();
  const fields: FieldConfig[] = [
    {
      name: 'first_name',
      label: 'First Name',
      type: 'text',
      placeholder: 'eg. John',
      validation: { required: true, errormessage: 'First Name is required' },
      columnSize: 6,
    },
    {
      name: 'last_name',
      label: 'Last Name',
      type: 'text',
      placeholder: 'eg. Smith',
      validation: { required: true, errormessage: 'Last Name is required' },
      columnSize: 6,
    },
    {
      name: 'email',
      label: 'Email',
      type: 'text',
      placeholder: 'john.doe@mycompany.com',
      columnSize: 6,
      validation: { required: true, errormessage: 'Email is required' },
    },
    {
      name: 'title',
      label: 'Title',
      type: 'text',
      placeholder: 'Engineering Manager',
      columnSize: 6,
      validation: { required: true, errormessage: 'Title is required' },
    },
    {
      name: 'question_text',
      label: 'Question Title',
      type: 'text',
      validation: { required: true, errormessage: 'Last Name is required' },
      placeholder: 'eg. What is a typical day for an engineer?',
      columnSize: 12,
    },
    {
      name: 'video',
      label: 'Upload Video',
      type: 'upload',
      validation: { required: true, errormessage: 'Video is required' },
      columnSize: 12,
    },
  ];
  const testimonial = useAPI(APIRequests.TestimonialCreate);

  useEffect(() => {
    if (testimonial.data) {
      toast('🦄 Video Created!');
      props.onComplete();
      setLoading(false);
    }
    if (testimonial.error) {
      toast.error(String(testimonial.error));
      setLoading(false);
    }
  }, [testimonial.data, testimonial.error]);

  const handleSubmit = (data: any) => {
    // setLoading(true);
    console.log(data);
    data.customer_id = company || context.state.company;
    const collectiondata = data as Collection;
    const req: RequestInput = {
      pathParams: { company: company || context.state.company },
      body: collectiondata,
    };
    testimonial.execute(req);
  };

  return (
    <Form
      title="Upload a Video"
      submitLabel="Submit"
      fields={fields}
      callback={handleSubmit}
      isLoading={loading}
    />
  );
};
