import { APIEndpoint, RequestInput } from "../types/config";
import { BACKEND_URL } from "../config/index";
import { CustomerSetting } from "../types/user";

export type ListResource = {
  total: number;
  records: any;
};

export type PublicGet = {
  data: any;
  settings: CustomerSetting;
};

export enum HTTPMethods {
  DELETE = "DELETE",
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT"
}

export enum APIRequests {
  AnalyticsSummary = "analyticsSummary",
  AnalyticsVideoSummary = "analyticsVideoSummary",
  CampaignCreate = "campaignCreate",
  CampaignDelete = "campaignDelete",
  CampaignGet = "campaignGet",
  CampaignUpdate = "campaignUpdate",
  CampaignsList = "campaignsList",
  CampaignResponses = "campaignResponses",
  CollectionCreate = "collectionCreate",
  CollectionDelete = "collectionDelete",
  CollectionGet = "collectionGet",
  CollectionGetPublic = "collectionGetPublic",
  CollectionUpdate = "collectionUpdate",
  CollectionsList = "collectionsList",
  CollectionLinkCreate = "collectionLinkCreate",
  CollectionLinkDelete = "collectionLinkDelete",
  CollectionLinksList = "collectionLinksList",
  CollectionLinkUpdate = "collectionLinkUpdate",
  CustomerCreate = "customerCreate",
  CustomerGet = "customerGet",
  CustomerBrandingGet = "customerBrandingGet",
  CustomerBrandingUpdate = "customerBrandingUpdate",
  CustomerUpdate = "customerUpdate",
  CustomersList = "customersList",
  EmployeeCreate = "employeeCreate",
  EmployeesList = "employeesList",
  GreenHouseDeptsList = "greenHouseDeptsList",
  GreenHouseJobsList = "greenHouseJobsList",
  Identify = "identify",
  Login = "login",
  LoginVerify = "loginVerify",
  Me = "me",
  ProjectCreate = "projectCreate",
  ProjectsList = "projectsList",
  QuestionCreate = "questionCreate",
  QuestionDelete = "questionDelete",
  QuestionEdit = "questionEdit",
  QuestionGet = "questionGet",
  QuestionsList = "questionsList",
  QuestionLinkCreate = "questionLinkCreate",
  QuestionLinkUpdate = "questionLinkUpdate",
  QuestionLinkUpdateBulk = "questionLinkUpdateBulk",
  QuestionLinkDelete = "questionLinkDelete",
  ResetPassword = "resetPassword",
  TestimonialCreate = "testimonialCreate",
  TestimonialGet = "testimonialGet",
  TestimonialGetHistory = "testimonialGetHistory",
  TestimonialGetPublic = "testimonialGetPublic",
  TestimonialInit = "testimonialInit",
  TestimonialsList = "testimonialsList",
  TestimonialsListAll = "testimonialsListAll",
  TestimonialsListPublic = "testimonialsListPublic",
  TestimonialUpdate = "testimonialUpdate",
  TestimonialUpload = "testimonialUpload",
  Track = "track",
  TranscriptionGet = "transcriptionGet",
  TranscriptionUpdate = "transcriptionUpdate",
  UserCreate = "userCreate",
  UserDelete = "userDelete",
  UsersList = "usersList",
  VideoEdit = "videoEdit"
}

const apiEndpoints: Record<APIRequests, APIEndpoint> = {
  analyticsSummary: {
    path: "/analytics/:company/summary",
    method: HTTPMethods.GET,
    pathParams: ["company"]
  },
  analyticsVideoSummary: {
    path: "/analytics/:company/videos/:id",
    method: HTTPMethods.GET,
    pathParams: ["company", "id"]
  },
  campaignCreate: {
    path: "/campaigns/:company",
    method: HTTPMethods.POST,
    pathParams: ["company"]
  },
  campaignDelete: {
    path: "/campaigns/:company/:id",
    method: HTTPMethods.DELETE,
    pathParams: ["company", "id"]
  },
  campaignGet: {
    path: "/campaigns/:company/:id",
    method: HTTPMethods.GET,
    pathParams: ["company", "id"]
  },
  campaignResponses: {
    path: "/campaigns/:external_id/responses",
    method: HTTPMethods.GET,
    pathParams: ["external_id"],
    queryParams: ["qs"]
  },
  campaignUpdate: {
    path: "/campaigns/:company/:id",
    method: HTTPMethods.PUT,
    pathParams: ["company", "id"]
  },
  campaignsList: {
    path: "/campaigns/:company",
    method: HTTPMethods.GET,
    pathParams: ["company"],
    queryParams: ["q"]
  },
  collectionCreate: {
    path: "/collections/:company",
    method: HTTPMethods.POST,
    pathParams: ["company"]
  },
  collectionDelete: {
    path: "/collections/:company/:id",
    method: HTTPMethods.DELETE,
    pathParams: ["company", "id"]
  },
  collectionGet: {
    path: "/collections/:company/:id",
    method: HTTPMethods.GET,
    pathParams: ["company", "id"]
  },
  collectionGetPublic: {
    path: "/collections/:id/videos",
    method: HTTPMethods.GET,
    pathParams: ["id"]
  },
  collectionUpdate: {
    path: "/collections/:company/:id",
    method: HTTPMethods.PUT,
    pathParams: ["company", "id"]
  },
  collectionsList: {
    path: "/collections/:company",
    method: HTTPMethods.GET,
    pathParams: ["company"],
    queryParams: ["testimonial"]
  },
  collectionLinkCreate: {
    path: "/collections/:company/:collection_id",
    method: HTTPMethods.POST,
    pathParams: ["company", "collection_id"]
  },
  collectionLinkDelete: {
    path: "/collections/:company/:collectionid/:id",
    method: HTTPMethods.DELETE,
    pathParams: ["company", "collectionid", "id"]
  },
  collectionLinksList: {
    path: "/collections/:company/:collection_id/links",
    method: HTTPMethods.GET,
    pathParams: ["company", "collection_id"]
  },
  collectionLinkUpdate: {
    path: "/collections/:company/:collection_id/bulk",
    method: HTTPMethods.PUT,
    pathParams: ["company", "collection_id"]
  },
  customerCreate: {
    path: "/customers",
    method: HTTPMethods.POST
  },
  customerGet: {
    path: "/customers/:customer",
    method: HTTPMethods.GET,
    pathParams: ["customer"]
  },
  customerBrandingGet: {
    path: "/customers/:customer/branding",
    method: HTTPMethods.GET,
    pathParams: ["customer"]
  },
  customerBrandingUpdate: {
    path: "/customers/:customer/branding",
    method: HTTPMethods.PUT,
    pathParams: ["customer"]
  },
  customerUpdate: {
    path: "/customers/:customer_name",
    method: HTTPMethods.PUT,
    pathParams: ["customer_name"]
  },
  customersList: {
    path: "/customers",
    method: HTTPMethods.GET,
    queryParams: []
  },
  employeeCreate: {
    path: "/employees/:customer_id",
    method: HTTPMethods.POST,
    pathParams: ["customer_id"]
  },
  employeesList: {
    path: "/employees/:company",
    method: HTTPMethods.GET,
    pathParams: ["company"]
  },
  identify: {
    path: "/analytics/identify",
    method: HTTPMethods.GET
  },
  greenHouseDeptsList: {
    path:
      "https://boards-api.greenhouse.io/v1/boards/:boardToken/departments?render_as=tree",
    method: HTTPMethods.GET,
    pathParams: ["boardToken"]
  },
  greenHouseJobsList: {
    path: "https://boards-api.greenhouse.io/v1/boards/:boardToken/jobs",
    method: HTTPMethods.GET,
    pathParams: ["boardToken"]
  },
  login: {
    path: "/token",
    method: HTTPMethods.POST,
    queryParams: ["username", "password"]
  },
  loginVerify: {
    path: "/token/:token",
    method: HTTPMethods.POST,
    pathParams: ["token"]
  },
  me: {
    path: "/users/me",
    method: HTTPMethods.GET
  },
  projectCreate: {
    path: "/projects/:company",
    method: HTTPMethods.POST,
    pathParams: ["company"]
  },
  projectsList: {
    path: "/projects/:company",
    method: HTTPMethods.GET,
    pathParams: ["company"]
  },
  questionCreate: {
    path: "/questions/:company",
    method: HTTPMethods.POST,
    pathParams: ["company"]
  },
  questionEdit: {
    path: "/questions/:company/:id",
    method: HTTPMethods.PUT,
    pathParams: ["company", "id"]
  },
  questionDelete: {
    path: "/questions/:company/:id",
    method: HTTPMethods.DELETE,
    pathParams: ["company", "id"]
  },
  questionGet: {
    path: "/questions/:company",
    method: HTTPMethods.GET,
    pathParams: ["company"],
    queryParams: ["campaign"]
  },
  questionsList: {
    path: "/questions/:company",
    method: HTTPMethods.GET,
    pathParams: ["company"],
    queryParams: ["campaign", "full"]
  },
  questionLinkCreate: {
    path: "/questions/:company/:campaign",
    method: HTTPMethods.POST,
    pathParams: ["company", "campaign"]
  },
  questionLinkUpdate: {
    path: "/questions/:company/:campaign/link",
    method: HTTPMethods.PUT,
    pathParams: ["company", "campaign"]
  },
  questionLinkUpdateBulk: {
    path: "/questions/:company/:campaign/bulk",
    method: HTTPMethods.PUT,
    pathParams: ["company", "campaign"]
  },
  questionLinkDelete: {
    path: "/questions/:company/:campaign_id/:id",
    method: HTTPMethods.DELETE,
    pathParams: ["company", "campaign_id", "id"]
  },
  resetPassword: {
    path: "/users/reset-password",
    method: HTTPMethods.POST
  },
  testimonialCreate: {
    path: "/testimonials/:company",
    method: HTTPMethods.POST,
    pathParams: ["company"]
  },
  testimonialGet: {
    path: "/testimonials/:company/:id",
    method: HTTPMethods.GET,
    pathParams: ["company", "id"]
  },
  testimonialGetHistory: {
    path: "/testimonials/:company/:id/history",
    method: HTTPMethods.GET,
    pathParams: ["company", "id"]
  },
  testimonialGetPublic: {
    path: "/testimonials/published/:id",
    method: HTTPMethods.GET,
    pathParams: ["id"]
  },
  testimonialInit: {
    path: "/testimonials/:campaign/initialize",
    method: HTTPMethods.GET,
    pathParams: ["campaign"]
  },
  testimonialUpdate: {
    path: "/testimonials/:company/:id",
    method: HTTPMethods.PUT,
    pathParams: ["company", "id"]
  },
  testimonialUpload: {
    path: "/testimonials/upload/:filename",
    method: HTTPMethods.GET,
    pathParams: ["filename"]
  },
  testimonialsList: {
    path: "/testimonials/:company",
    method: HTTPMethods.GET,
    pathParams: ["company"],
    queryParams: ["campaign", "status"]
  },
  testimonialsListAll: {
    path: "/testimonials",
    method: HTTPMethods.GET
  },
  testimonialsListPublic: {
    path: "/testimonials/:company/published",
    method: HTTPMethods.GET,
    pathParams: ["company"],
    queryParams: ["path"]
  },
  track: {
    path: "/analytics/track",
    method: HTTPMethods.POST
  },
  transcriptionGet: {
    path: "/testimonials/:company/:id/transcription",
    method: HTTPMethods.GET,
    pathParams: ["company", "id"]
  },
  transcriptionUpdate: {
    path: "/testimonials/:company/:id/transcription",
    method: HTTPMethods.PUT,
    pathParams: ["company", "id"]
  },
  userCreate: {
    path: "/users/:company",
    method: HTTPMethods.POST,
    pathParams: ["company"]
  },
  userDelete: {
    path: "/users/:userid",
    method: HTTPMethods.DELETE,
    pathParams: ["userid"]
  },
  usersList: {
    path: "/users",
    method: HTTPMethods.GET,
    queryParams: ["company"]
  },
  videoEdit: {
    path: "/testimonials/:company/:external_id/process",
    method: HTTPMethods.POST,
    pathParams: ["company", "external_id"]
  },
};

// eslint-disable-next-line complexity
export const MakeRequest = (
  req: APIRequests,
  input: RequestInput
): RequestInfo => {
  let { method, path, pathParams, queryParams } = apiEndpoints[req];
  let common_params = ["limit", "q", "sort", "sortDir", "page", "search"];
  if (queryParams) {
    queryParams.push(...common_params);
  } else {
    queryParams = common_params;
  }
  let fixedpath = path.startsWith("https://") ? path : BACKEND_URL + path;
  if (pathParams?.length && input.pathParams) {
    for (const p of pathParams) {
      fixedpath = fixedpath.replace(`/:${p}`, `/${input.pathParams[p]}`);
    }
  }
  if (queryParams && queryParams?.length && input.queryParams) {
    const params = Object.keys(input.queryParams)
      // @ts-ignore
      .filter(k => queryParams.indexOf(k) >= 0)
      .map(k => [k, String(input.queryParams?.[k]) || ""]);
    fixedpath += "?" + new URLSearchParams(params);
  }

  let jsonBody = "";
  if (input.body) {
    jsonBody = JSON.stringify(input.body);
  }

  const ctrl = new AbortController();
  const sig = ctrl.signal;

  setTimeout(() => ctrl.abort(), 60000);
  return new Request(fixedpath, {
    body: jsonBody || null,
    method: method,
    signal: sig,
    headers: { "Content-Type": "application/json" }
  });
};
