import React, { useEffect, useState } from 'react';
import { Alert, Button, Grid, Typography, TextField } from '@mui/material';
import { APIRequests } from '../config/api';
import { RequestInput } from '../types/config';

import useAPI from '../utils/useApi';

export const ForgotPassword = (): JSX.Element => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const resetPassword = useAPI<{}>(APIRequests.ResetPassword);

  const handleSubmit = async (_: React.MouseEvent) => {
    const req: RequestInput = {
      body: { email: email },
    };
    resetPassword.execute(req);
  };

  useEffect(() => {
    if (resetPassword.data) {
      setSuccess(true);
    }
    if (resetPassword.error) {
      setError(resetPassword.error[0]);
    }
  }, [resetPassword.data, resetPassword.error]);

  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          item
          xs={4}
          direction="column"
          container
          justifyContent="center"
          sx={{
            backgroundImage:
              'linear-gradient(146deg, rgba(249, 225, 255, 0.7) 17%, rgba(225, 244, 255, 0.8))',
            minHeight: '100vh',
            textAlign: 'center',
            display: 'flex',
          }}
        >
          <img
            src="/images/logo-block-purple.png"
            alt="Jamyr Logo"
            style={{
              width: '50%',
              margin: 'auto',
              display: 'block',
            }}
          />
        </Grid>
        <Grid
          item
          xs={8}
          container
          justifyContent="center"
          sx={{
            padding: { xs: 10, sm: 10, md: 20, lg: 30 },
          }}
        >
          <Grid item xs={12} container justifyContent="center">
            <Typography variant="h4" color="primary">
              Forgot Password
            </Typography>
          </Grid>
          {!success && (
            <>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.currentTarget.value.trim())
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid container alignItems="center">
                {error && (
                  <Grid item>
                    <Alert severity="error">{error}</Alert>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ width: '100%' }}
                >
                  Reset Password
                </Button>
              </Grid>
            </>
          )}
          {success && (
            <Grid item xs={12}>
              <Typography>
                Check your email for access to reset your password
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      )
    </>
  );
};
