import React, { useState, useEffect } from 'react';
import { setCookie, isMobile } from '../../utils/global';
import { useNavigate, useLocation } from 'react-router-dom';
import { useModal } from 'mui-modal-provider';
import { TableCard, CustomerCreate } from '../../components';
import { JamyrTable, TableProps, TableAction, PopupModal } from '../../atoms';
import useAPI from '../../utils/useApi';
import { APIRequests, ListResource } from '../../config/api';
import { JamyrCookies, DataType, RequestInput } from '../../types/config';
import { Customer } from '../../types/user';
import { CustomerStatus } from '../../config/layouts/selectLists';
import { Grid, Tabs, Tab } from '@mui/material';

export const Customers = (): JSX.Element => {
  const [tableData, setTableData] = useState<{ [name: string]: string }[]>();
  const [value, setValue] = useState<number>(0);
  const [filter, setFilter] = useState<string>(CustomerStatus[value].name);

  const customers = useAPI<ListResource>(APIRequests.CustomersList);
  let navigate = useNavigate();
  let location = useLocation();

  let { showModal } = useModal();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    window.history.pushState(null, '', `#${CustomerStatus[newValue].label}`);
    setValue(newValue);
    setFilter(CustomerStatus[newValue].name);
  };

  useEffect(() => {
    let filterStatus = filter;
    if (location.hash && !tableData) {
      // location.hash is only reliable on the first load of the page
      const status: string = location.hash.replace('#', '');
      const selectedStatus = CustomerStatus.filter(
        (val) => val.label === status
      );
      if (selectedStatus) {
        filterStatus = selectedStatus[0].name;
        setValue(CustomerStatus.indexOf(selectedStatus[0]));
        setFilter(filterStatus);
      }
    }
    let req: RequestInput = {
      queryParams: { q: `status=${filterStatus}` },
    };
    customers.execute(req);
  }, [filter]);

  useEffect(() => {
    if (customers.data) {
      setTableData(customers.data.records as { [name: string]: string }[]);
    }
  }, [customers.data]);

  const createCustomer = () => {
    const modal = showModal(PopupModal, {
      children: (
        <CustomerCreate
          onComplete={() => {
            customers.execute({});
            modal.destroy();
          }}
        />
      ),
      size: 'small',
    });
  };

  const handleEditClick = (idx: number) => {
    if (tableData) {
      const customer: Customer = tableData[idx] as unknown as Customer;
      setCookie(JamyrCookies.COMPANY, customer.id, 1);
      navigate('/dashboard/questions');
    }
  };

  const actions: TableAction[] = [
    {
      header: 'Edit',
      icon: 'edit',
      onClick: handleEditClick,
    },
  ];

  const tableProps: TableProps = {
    columns: [
      { name: 'name', label: 'Customer' },
      { name: 'created_at', label: 'Date joined', type: DataType.Date },
    ],
    rows: tableData,
    actions: actions,
  };
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          ml: { xs: 4, sm: 15 },
          mt: { xs: 5 },
        }}
      >
        <Tabs
          value={value}
          onChange={handleTabChange}
          variant={isMobile() ? 'scrollable' : 'standard'}
          scrollButtons={isMobile()}
          aria-label="customer status tabs"
          sx={{
            ml: -5,
          }}
        >
          {CustomerStatus.map((tab) => {
            return (
              <Tab
                key={tab.name}
                label={tab.label}
                id={tab.name}
                aria-controls={`testimonial-tabpanel-${tab.name}`}
                sx={{
                  textTransform: 'none',
                }}
              />
            );
          })}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <TableCard
          title="Customers"
          actionClick={createCustomer}
          actionLabel="Add"
          actionIcon="add"
        >
          <JamyrTable {...tableProps} />
        </TableCard>
      </Grid>
    </Grid>
  );
};
