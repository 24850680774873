import React, { useState, useEffect } from 'react';
import { PublicHeader, JamyrPlayer, VideoPlayer } from '../components';
import { Loading, GalleryCard, PopupModal } from '../atoms';
import { useParams } from 'react-router-dom';
import useAPI from '../utils/useApi';
import { APIRequests, PublicGet } from '../config/api';
import { RequestInput } from '../types/config';
import { Collection, CollectionPageFormat } from '../types/collection';
import { Testimonial, LandingPageFormat } from '../types/testimonial';
import { CustomerSetting } from '../types/user';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { track, isMobile } from '../utils/global';

export const CollectionPage = (): JSX.Element => {
  const { pathId } = useParams();
  const collectionGet = useAPI<PublicGet>(APIRequests.CollectionGetPublic);
  const [collection, setCollection] = useState<{
    collection: Collection;
    testimonials: Testimonial[];
    settings: CustomerSetting;
  }>();
  const [hasError, setHasError] = useState<Boolean>(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState<Testimonial>();
  const { showModal } = useModal();

  useEffect(() => {
    if (pathId) {
      const req: RequestInput = {
        pathParams: { id: pathId, company: 0 },
      };
      collectionGet.execute(req);
    }
  }, [pathId]);

  useEffect(() => {
    if (collectionGet.data) {
      const c = collectionGet.data.data;
      track('page-view', `${c.collection.customer_id}`, {
        collection: c.collection.external_id,
      });
      setCollection({
        collection: c.collection,
        testimonials: c.testimonials,
        settings: collectionGet.data.settings,
      });
      if (c.collection.page_style === CollectionPageFormat['video-guide']) {
        setSelectedTestimonial(c.testimonials[0]);
      }
    }
    if (collectionGet.error) {
      setHasError(true);
    }
  }, [collectionGet.data, collectionGet.error]);

  const playVideo = (testimonial: Testimonial) => {
    showModal(PopupModal, {
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      children: (
        <Box
          justifyContent="center"
          display="inline-block"
          sx={{ ml: { xs: 5, sm: 0 }, textAlign: 'center' }}
        >
          <Typography
            component="p"
            sx={{
              color: 'white',
              fontWeight: 'bold',
              mb: 3,
              textAlign: 'center',
            }}
          >
            {testimonial.question_text}
          </Typography>
          <JamyrPlayer
            videoSrc={testimonial.final_video || testimonial.video}
            videoId={testimonial.external_id}
            alt={testimonial.question_text}
            customerId={`${testimonial.customer_id}`}
            img={testimonial.thumbnail}
            gif={testimonial.preview}
            languages={collection?.settings.languages}
          />
        </Box>
      ),
    });
  };

  const handleLinkClick = (event: string, link: string) => {
    const data = { collection: collection?.collection.external_id };
    track(event, `${collection?.collection?.customer_id}`, data);
    window.open(link, '_blank');
  };

  const renderCtas = (alignment: string) => {
    return (
      <>
        {collection?.collection.cta_link && (
          <Box justifyContent={alignment}>
            <Typography sx={{ textAlign: alignment, mb: 2 }}>
              {collection.collection.cta_title}
            </Typography>
            <Box display="flex" justifyContent={alignment}>
              <Button
                sx={{
                  borderRadius: 50,
                  width: 130,
                  fontSize: { xs: '0.6rem', sm: '0.8rem' },
                }}
                onClick={() =>
                  handleLinkClick(
                    'primary-cta-click',
                    collection.collection.cta_link
                  )
                }
              >
                {collection.collection.cta_btn_text}
              </Button>
              {collection.collection.secondary_cta_btn_text && (
                <Button
                  sx={{
                    ml: 1,
                    borderRadius: 50,
                    width: 130,
                    backgroundColor: 'white',
                    color: 'black',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.24)',
                    fontSize: { xs: '0.6rem', sm: '0.8rem' },
                  }}
                  onClick={() =>
                    handleLinkClick(
                      'secondary-cta-click',
                      collection.collection.secondary_cta_link
                    )
                  }
                >
                  {collection.collection.secondary_cta_btn_text}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </>
    );
  };

  const tileLayout = () => {
    return (
      collection && (
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: 38,
                mb: 2,
                color: collection.settings.landing_text_color,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {collection.collection.title}
            </Typography>
          </Grid>
          {collection.collection?.heading_text && (
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 5,
                  color: collection.settings.landing_text_color,
                  textAlign: 'center',
                  px: { xs: 1, sm: 20 },
                }}
              >
                {collection.collection.heading_text}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sx={{ mb: 5 }}>
            {renderCtas('center')}
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            sx={{ width: '100px' }}
          >
            {collection?.testimonials.map((testimonial) => {
              return (
                <Grid
                  key={testimonial.external_id}
                  item
                  xs={12}
                  sm={3}
                  sx={{ mb: 2, px: 0, mx: 0, maxWidth: 225 }}
                >
                  <GalleryCard
                    testimonial={testimonial}
                    onclick={() => playVideo(testimonial)}
                    hideQuestionText={collection.collection.hide_question_text}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )
    );
  };

  const jobGuideStyle = () => {
    const isJamLand =
      selectedTestimonial?.page_style === LandingPageFormat.JamLand;

    const gridParameters = isJamLand
      ? {
          container: true,
          item: true,
          xs: 12,
          sm: 6,
          sx: { pt: 2, pr: 2, alignContent: 'center' },
        }
      : { item: true, xs: 12, sm: 6, sx: { pt: 2 } };

    const playerProps = isJamLand ? { width: '100%', height: 'auto' } : {};

    return (
      collection && (
        <Grid container sx={{ pt: { xs: 0, sm: 7 } }}>
          {isMobile() && (
            <Grid container sx={{ px: { xs: 5, sm: 0 } }}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: 28,
                    mb: 2,
                    color: collection.settings.landing_text_color,
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  {collection.collection.title}
                </Typography>
              </Grid>
              {selectedTestimonial && (
                <Grid container item xs={12} sm={6} justifyContent="center">
                  <Box
                    display="flex"
                    sx={{
                      pl: 2,
                      mb: 1,
                      minWidth: '400px',
                      overflowX: 'scroll',
                    }}
                  >
                    {collection.testimonials.map((testimonial) => {
                      return (
                        <Box
                          key={testimonial.external_id}
                          onClick={() => setSelectedTestimonial(testimonial)}
                          sx={{ overflowX: 'visible' }}
                        >
                          <Typography
                            sx={{
                              p: 1,
                              mx: 1,
                              mb: 2,
                              minWidth: 150,
                              maxHeight: 25,
                              whiteSpace: 'nowrap',
                              cursor: 'pointer',
                              borderRadius: 2,
                              boxShadow: '0 6px 12px 0 rgba(0, 0, 0, 0.12)',
                              backgroundColor:
                                selectedTestimonial?.external_id ===
                                testimonial.external_id
                                  ? collection.settings.landing_btn_color ||
                                    collection.settings.default_bg
                                  : 'transparent',
                              color:
                                selectedTestimonial?.external_id ===
                                testimonial.external_id
                                  ? collection.settings.landing_btn_txt_color
                                  : 'black',
                            }}
                          >
                            {testimonial.question_text}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
          {selectedTestimonial && (
            <Grid {...gridParameters}>
              <JamyrPlayer
                videoSrc={
                  selectedTestimonial.final_video || selectedTestimonial.video
                }
                videoId={selectedTestimonial.external_id}
                alt={selectedTestimonial.question_text}
                customerId={`${selectedTestimonial.customer_id}`}
                img={selectedTestimonial.thumbnail}
                gif={selectedTestimonial.preview}
                languages={collection?.settings.languages}
                {...playerProps}
              />
              {isMobile() && (
                <>
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: 18 }}>
                      {selectedTestimonial?.first_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: 14, mb: 2 }}>
                      {selectedTestimonial?.title}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          )}
          {isMobile() && (
            <Grid container justifyContent="center" sx={{ mt: 2 }}>
              {renderCtas('center')}
            </Grid>
          )}

          {!isMobile() && (
            <Grid item xs={12} sm={6}>
              <Grid container sx={{ textAlign: 'left' }}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 42,
                      mb: 2,
                      color: collection.settings.landing_text_color,
                      fontWeight: 'bold',
                    }}
                  >
                    {collection.collection.title}
                  </Typography>
                </Grid>
                {selectedTestimonial && (
                  <>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: 18 }}>
                        {selectedTestimonial?.first_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: 14, mb: 2 }}>
                        {selectedTestimonial?.title}
                      </Typography>
                    </Grid>
                  </>
                )}
                {collection.testimonials.map((testimonial) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      key={testimonial.external_id}
                      onClick={() => setSelectedTestimonial(testimonial)}
                    >
                      <Typography
                        sx={{
                          boxSizing: 'border-box',
                          p: 2,
                          mb: 2,
                          cursor: 'pointer',
                          borderRadius: 2,
                          boxShadow: '0 6px 12px 0 rgba(0, 0, 0, 0.12)',
                          backgroundColor:
                            selectedTestimonial?.external_id ===
                            testimonial.external_id
                              ? collection.settings.landing_btn_color ||
                                collection.settings.default_bg
                              : 'transparent',
                          color:
                            selectedTestimonial?.external_id ===
                            testimonial.external_id
                              ? collection.settings.landing_btn_txt_color
                              : 'black',
                        }}
                      >
                        {testimonial.question_text}
                      </Typography>
                    </Grid>
                  );
                })}
                <Grid item xs={12} pt={5}>
                  {renderCtas('left')}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )
    );
  };

  return (
    <>
      {collection ? (
        <>
          <PublicHeader settings={collection.settings} />
          <Grid
            container
            sx={{
              m: { xs: 0, sm: 'auto' },
              pt: 5,
              maxWidth:
                collection.collection.page_style !==
                CollectionPageFormat['video-guide']
                  ? '900px'
                  : '1200px',
              textAlign: 'center',
            }}
          >
            {collection.collection.page_style !==
              CollectionPageFormat['video-guide'] && tileLayout()}
            {collection.collection.page_style ===
              CollectionPageFormat['video-guide'] && jobGuideStyle()}
          </Grid>
        </>
      ) : hasError ? (
        <Typography> Something went very wrong </Typography>
      ) : (
        <Loading />
      )}
    </>
  );
};
