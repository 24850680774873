import {
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import {
  Campaign,
  CampaignIn,
  GeneratedQuestion
} from "../../types";
import { Queries, sendRequest, ListResource } from "./api";

export const useCampaignCreate = (
  pParams: { [name: string]: string }
) => {
  return useMutation({
    mutationKey: Queries.CampaignCreate.key,
    mutationFn: (data: CampaignIn) =>
      sendRequest<Campaign>(Queries.CampaignCreate.endpoint, { body: data, pathParams: pParams }),
  })
};

export const useCampaignGet = (
  pParams: { [name: string]: any },
  qParams: { [name: string]: any }
) => {
  return useQuery({
    queryKey: Queries.CampaignGet.key.concat(Object.values(pParams)).concat(Object.values(qParams)),
    queryFn: () =>
      sendRequest<Campaign>(Queries.CampaignGet.endpoint, { pathParams: pParams, queryParams: qParams }),
  })
};

export const useCampaignGetQuestions = (
  pParams: { [name: string]: any },
  qParams: { [name: string]: any }
) => {
  return useQuery({
    queryKey: Queries.CampaignGetQuestions.key.concat(Object.values(pParams)).concat(Object.values(qParams)),
    queryFn: () =>
      sendRequest<GeneratedQuestion[]>(Queries.CampaignGetQuestions.endpoint, { pathParams: pParams, queryParams: qParams }),
    enabled: !!pParams.id,
    refetchOnWindowFocus: false,
    retry: false
  })
};

export const useCampaignList = (
  pParams: { [name: string]: any },
  qParams: { [name: string]: any }
) => {
  return useQuery({
    queryKey: Queries.CampaignList.key.concat(Object.values(pParams)).concat(Object.values(qParams)),
    queryFn: () =>
      sendRequest<ListResource>(Queries.CampaignList.endpoint, { pathParams: pParams, queryParams: qParams }),
  })
}; 
