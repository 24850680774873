import React, { useState, useRef } from 'react';
import { S3_BUCKET } from '../config';
import { isMobile, track } from '../utils/global';
import { transcriptionLanguages } from '../config';

export interface PlayerProps {
  videoSrc: string;
  videoId: string;
  alt: string;
  customerId: string;
  languages?: string;
  img?: string;
  gif?: string;
  width?: string;
  height?: string;
}

export const JamyrPlayer = (props: PlayerProps): JSX.Element => {
  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const videoRef = useRef(null); // HTMLVideoElement
  const intervalRef = useRef(null); // NodeJS.Timer
  const location = window.location;

  // const landscapeStyle = {
  //   maxWidth: props.width || '400px',
  //   maxHeight: props.height || '225px',
  //   borderRadius: '15px',
  //   display: 'inline-block',
  // };

  // const portaitStyle = {
  //   width: props.width || '338px',
  //   height: props.height || '600px',
  //   borderRadius: '15px',
  //   display: 'inline-block',
  // };

  const landscapeStyle = {
    width: props.width || "auto",
    maxWidth: props.width || isMobile() ? "90vw" : "65vw",
    height: props.height || "auto",
    maxHeight: props.height || "55vh",
    borderRadius: "15px",
    display: "inline-block",
  };
  const portaitStyle = {
    width: props.width || "auto",
    maxWidth: props.width || "400px",
    height: props.height || "auto",
    maxHeight: props.height || "60vh",
    borderRadius: "15px",
    display: "inline-block",
  };

  const handleLoad = () => {
    if (videoRef?.current && typeof videoRef.current === 'object') {
      const t = videoRef.current;
      // @ts-ignore
      if (t.videoHeight > t.videoWidth && !isPortrait) {
        setIsPortrait(true);
        // @ts-ignore
      } else if (t.videoHeight < t.videoWidth && isPortrait) {
        setIsPortrait(false);
      }
    }
  };

  const handlePauseVideo = () => {
    if (videoRef?.current && typeof videoRef.current === 'object') {
      const data = {
        video: props.videoId,
        url: location.pathname,
        // @ts-ignore: Property does not exist
        mark: videoRef.current?.currentTime,
        completion: Math.round(
          // @ts-ignore: Property does not exist
          (videoRef.current.currentTime / videoRef.current.duration) * 100
        ),
      };
      track(
        data.completion === 100 ? 'completed-video' : 'stop-video',
        props.customerId,
        data
      );
      if (intervalRef?.current) {
        clearInterval(intervalRef.current);
      }
    }
  };

  const handlePlayVideo = () => {
    if (videoRef?.current && typeof videoRef.current === 'object') {
      const playData = {
        video: props.videoId,
        url: location.pathname,
        // @ts-ignore: Property does not exist
        mark: videoRef.current?.currentTime,
        completion: Math.round(
          // @ts-ignore: Property does not exist
          (videoRef.current.currentTime / videoRef.current.duration) * 100
        ),
      };
      track('start-video', props.customerId, playData);

      // @ts-ignore: Property does not exist
      intervalRef.current = setInterval(() => {
        const data = {
          video: props.videoId,
          url: location.pathname,
          // @ts-ignore: Property does not exist
          mark: videoRef.current?.currentTime,
          completion: Math.round(
            // @ts-ignore: Property does not exist
            (videoRef.current.currentTime / videoRef.current.duration) * 100
          ),
        };
        // @ts-ignore: Property does not exist
        track('video-progress', props.customerId, data);
      }, 3000);
    }
  };

  const getTrack = (lang: string) => {
    const lcode = lang.split('-')[0];
    return (
      <track
        src={`https://${S3_BUCKET}/transcriptions/${props.videoId}/${lcode}/${props.videoId}.vtt`}
        label={transcriptionLanguages.filter((tl) => tl.id === lang)[0].value}
        srcLang="lcode"
      />
    );
  };

  return (
    <>
      <video
        ref={videoRef}
        crossOrigin="anonymous"
        onLoadedData={handleLoad}
        onPlay={handlePlayVideo}
        onEnded={handlePauseVideo}
        onPause={handlePauseVideo}
        style={isPortrait ? portaitStyle : landscapeStyle}
        controls
        // playsInline
        autoPlay
        src={props.videoSrc}
      >
        <track
          src={`https://${S3_BUCKET}/transcriptions/${props.videoId}/en/${props.videoId}.vtt`}
          label="English"
          srcLang="en"
          default
        />
        {props.languages &&
          props.languages.split(';').map((l: string) => {
            return getTrack(l);
          })}
      </video>
      {/* {!playing && props.img && <img alt={props.alt} src={props.img} />} */}
      {/* {!playing && (
        <div>
          <PlayCircleIcon
            style={{
              cursor: 'pointer',
              color: '#E7E5DB',
              fontSize: '96px',
            }}
            onClick={handlePlayVideo}
          />
        </div>
      )} */}
    </>
  );
};
