import React, { useState } from 'react';
import { Testimonial } from '../types/testimonial';
import { BACKEND_URL, S3_BUCKET } from '../config/index';
import { OptionsList } from '.';
import { Button, List, ListItem, ListItemText } from '@mui/material';
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from 'react-share';
import { toast } from 'react-toastify';
import { CloseButton, CustomIcon } from '../atoms';
import { videoExportOptions } from '../config';
import { useGlobalState } from '../utils/GlobalStateProvider';

export interface SharingAssetsProps {
  testimonial: Testimonial;
}

export const SharingAssets = (props: SharingAssetsProps) => {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const context = useGlobalState();

  let shareLink = `${BACKEND_URL}/testimonials/share/${props.testimonial.external_id}`;
  if (
    props.testimonial.status === 'new' ||
    props.testimonial.status === 'pending approval'
  ) {
    shareLink = shareLink + '?review=true';
  }

  const emailEmbed = () => {
    // returns a jsx element that displays a link and video preview in an email
    const copyCode = () => {
      const element = document.getElementById('embed');
      if (element) {
        navigator.clipboard.write([
          new ClipboardItem({
            'text/plain': new Blob([element.innerText], { type: 'text/plain' }),
            'text/html': new Blob([element.outerHTML], { type: 'text/html' }),
          }),
        ]);
        toast('🦄 Embed Code copied!');
      }
    };
    return (
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <CloseButton onclick={() => setShowPreview(false)} />
        <div id="embed">
          <a href={shareLink}>
            {`${props.testimonial.question_text}`}
            <br />
            <img
              src={props.testimonial.preview}
              style={{
                width: 200,
                height: 325,
                objectFit: 'cover',
                maxWidth: 200,
                maxHeight: 325,
                borderRadius: 10,
                cursor: 'pointer',
              }}
              crossOrigin="anonymous"
              alt={props.testimonial.question_text}
              onClick={() => window.open(shareLink, '_blank')}
            />
          </a>
        </div>
        <Button
          sx={{ textAlign: 'center', width: '100%', px: 20 }}
          onClick={copyCode}
        >
          Copy Embed Code
        </Button>
      </div>
    );
  };

  const websiteEmbed = () => {
    return `
    <script async src="https://widget.jamyr.com/js/pub/application.js" id="jamyr-app" data-company="${props.testimonial.company_slug}" data-dataid="${props.testimonial.external_id}" data-component="embed" data-containerid=""/>
    `;
  };

  const websiteWrapper = () => {
    return `
    <div id="jamyr_video_embed_div" style="display: flex;justify-content: center;"> <script async src="https://widget.jamyr.com/js/pub/application.js" id="jamyr-app" data-company="${props.testimonial.company_slug}" data-dataid="${props.testimonial.external_id}" data-component="embed" data-containerid="jamyr_video_embed_div"></script> </div>
    `;
  };

  const greenhouseEmbed = () => {
    const pageformat = props.testimonial.page_style?.startsWith('jamland')
      ? 'jamlandembed'
      : 'jamjobembed';
    const style =
      pageformat === 'jamjobembed'
        ? 'border: none; min-height: 360px; max-width: 300px'
        : 'border: none; width:100%;';
    const height = pageformat === 'jamjobembed' ? '420px' : '360px';
    const width = pageformat === 'jamjobembed' ? '' : '510px';
    const link = `${window.location.origin}/video/embed/${props.testimonial.external_id}`;
    return `
    <iframe
      style="${style}"
      src="${link}"
      height="${height}"
      width="${width}"
      data-mce-fragment="1">
    </iframe>
  `;
  };

  const getExportLink = (dimension: string) => {
    if (dimension) {
      return `https://${S3_BUCKET}/testimonials/${
        props.testimonial.external_id
      }/${props.testimonial.external_id}/${dimension.replace(':', '-')}.mp4`;
    } else {
      return props.testimonial.video;
    }
  };
  const displayExportOptions = () => {
    return (
      <div
        style={{
          textAlign: 'center',
          maxWidth: 200,
          maxHeight: 350,
          position: 'absolute',
          overflowY: 'scroll',
          backgroundColor: '#F7F7FB',
          top: -275,
          right: -100,
        }}
      >
        <List sx={{ m: 0, p: 0 }}>
          {videoExportOptions.map((item) => {
            return (
              <ListItem key={item.name}>
                <ListItemText
                  primary={`${item.label} ${item.dimensions}`}
                  sx={{ pr: 5 }}
                />
                <CustomIcon
                  name="download"
                  onClick={() =>
                    window.open(getExportLink(item.dimensions), '_blank')
                  }
                  sx={{ cursor: 'pointer', ml: 1 }}
                />
              </ListItem>
            );
          })}
        </List>
      </div>
    );
  };

  let options = [
    {
      title: 'Landing Page',
      description: 'For linking in emails, blogs, websites and more',
      actions: [
        { icon: 'mail', onClick: () => setShowPreview(true) },
        {
          icon: 'link',
          onClick: () => {
            navigator.clipboard.writeText(shareLink);
            toast('🦄 Link copied!');
          },
        },
        {
          icon: 'preview',
          onClick: () => window.open(shareLink, '_blank'),
        },
      ],
    },
    {
      title: 'Embed',
      description: 'For websites and job postings',
      actions: [
        {
          icon: 'codeblock',
          onClick: () => {
            navigator.clipboard.writeText(websiteWrapper());
            toast(
              '🦄 Code copied! Paste the code on whatever page you would like!'
            );
          },
        },
        {
          icon: 'code',
          onClick: () => {
            navigator.clipboard.writeText(websiteEmbed());
            toast(
              '🦄 Code copied! Set the container id parameter to the id of the element you want to load the video under'
            );
          },
        },
        {
          icon: 'greenhouse',
          onClick: () => {
            navigator.clipboard.writeText(greenhouseEmbed());
            toast('🦄 Code copied!');
          },
        },
      ],
    },
    {
      title: 'Social Media',
      description: 'For sharing videos directly to social media',
      actions: [
        {
          icon: (
            <FacebookShareButton url={shareLink}>
              <FacebookIcon size={28} round></FacebookIcon>
            </FacebookShareButton>
          ),
        },
        {
          icon: (
            <LinkedinShareButton url={shareLink}>
              <LinkedinIcon size={28} round></LinkedinIcon>
            </LinkedinShareButton>
          ),
        },
        {
          icon: (
            <TwitterShareButton url={shareLink}>
              <TwitterIcon size={28} round></TwitterIcon>
            </TwitterShareButton>
          ),
        },
      ],
    },
    {
      title: 'Downloads',
      description: 'For exporting videos, gifs and images',
      actions: [
        {
          icon: 'video',
          popupElement: context.state?.user?.scopes?.includes('global_admin')
            ? displayExportOptions()
            : undefined,
          onClick: () => {
            window.open(
              props.testimonial.final_video || props.testimonial.video,
              '_blank'
            );
          },
        },
        {
          icon: 'gif',
          onClick: () => {
            window.open(props.testimonial.preview, '_blank');
          },
        },
        {
          icon: 'image',
          onClick: () => {
            window.open(props.testimonial.thumbnail, '_blank');
          },
        },
      ],
    },
  ];
  return showPreview ? emailEmbed() : <OptionsList {...options} />;
};
