import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import useAPI from '../../utils/useApi';
import { useParams } from 'react-router-dom';
import { APIRequests, ListResource } from '../../config/api';
import {
  campaignCardLayout,
  campaignNavItems,
} from '../../config/layouts/campaignSettings';
import { RequestInput } from '../../types/config';
import { Campaign } from '../../types/campaign';
import { Question } from '../../types/testimonial';
import { Videos } from './Videos';
import {
  useGlobalState,
  useCurrentCompany,
} from '../../utils/GlobalStateProvider';
import {
  PageNavigation,
  PageNavItem,
  SettingsCard,
  SCardProps,
} from '../../components';
import {
  CustomIcon,
  JamyrMultiSelect,
  JamyrCard,
  Loading,
  SelectOption,
  PopupSelectList,
} from '../../atoms';
import DraggableList from '../../atoms/DraggableList';
import { toast } from 'react-toastify';

export const CampaignDetail = (): JSX.Element => {
  const campaignGet = useAPI(APIRequests.CampaignGet);
  const campaignUpdate = useAPI(APIRequests.CampaignUpdate);
  const questionGet = useAPI<ListResource>(APIRequests.QuestionsList);
  const questionList = useAPI<ListResource>(APIRequests.QuestionsList);
  const questionLinkUpdateBulk = useAPI<ListResource>(
    APIRequests.QuestionLinkUpdateBulk
  );
  const questionLinkUpdate = useAPI<Question>(APIRequests.QuestionLinkUpdate);
  const questionDelete = useAPI<Question | unknown>(
    APIRequests.QuestionLinkDelete
  );
  const questionCreate = useAPI(APIRequests.QuestionLinkCreate);
  const [campaign, setCampaign] = useState<Campaign>();
  const [questions, setQuestions] = useState<{ [name: string]: string }[]>([]);
  const [linkedQuestions, setLinkedQuestions] = useState<SelectOption[]>([]);
  const [questionOptions, setQuestionOptions] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const context = useGlobalState(true);
  const company = useCurrentCompany();
  const { campaignId } = useParams();

  const getQuestions = () => {
    if (campaignId) {
      const questreq: RequestInput = {
        pathParams: {
          company: company || context.state.company,
        },
        queryParams: { campaign: campaignId },
      };
      questionGet.execute(questreq);
      const questreqfull: RequestInput = {
        pathParams: {
          company: company || context.state.company,
        },
        queryParams: { campaign: campaignId, full: 'false' },
      };
      questionList.execute(questreqfull);
    }
  };

  useEffect(() => {
    if (context.state.company && campaignId) {
      const req: RequestInput = {
        pathParams: {
          company: company || context.state.company,
          id: campaignId,
        },
      };
      campaignGet.execute(req);
      getQuestions();
    }
  }, [context.state.company]);

  useEffect(() => {
    if (campaignGet.data && !campaign) {
      const c = campaignGet.data as Campaign;
      setCampaign(c);
    }
  }, [campaignGet.data]);

  useEffect(() => {
    if (questionGet.data) {
      const tquestion =
        (questionGet.data.records as { [name: string]: string }[]) || [];
      setQuestions(tquestion);
      let qls: SelectOption[] = [];
      const qoptions = questionGet.data.records as Question[];
      qoptions.forEach((q) => {
        qls.push({ id: String(q.id), value: q.text, detail: q.prompt });
      });
      setLinkedQuestions(qls);
      setLoading(false);
    }
  }, [questionGet.data]);

  useEffect(() => {
    if (questionList.data) {
      let allquestiondata: SelectOption[] = [];
      const qoptions = questionList.data.records as Question[];
      qoptions.forEach((q) => {
        allquestiondata.push({ id: String(q.id), value: q.text });
      });
      setQuestionOptions(allquestiondata);
      setLoading(false);
    }
  }, [questionList.data]);

  const getTagsAsOptions = (values: string) => {
    if (values) {
      let returnlist: SelectOption[] = [];
      const allValues = values.split(';');
      allValues.forEach((val) => {
        returnlist.push({ id: val, value: val });
      });
      return returnlist;
    } else {
      return undefined;
    }
  };

  const updateCampaignField = (field: string, value: string) => {
    let data = campaign as any;
    data[field] = value;
    setCampaign(data);
    updateCampaign(data);
  };

  const updateCampaign = (data: any) => {
    const req: RequestInput = {
      pathParams: {
        company: company || context.state.company,
        id: data.id,
      },
      body: data,
    };
    campaignUpdate.execute(req);
  };

  const createQuestionLink = (data: SelectOption[]) => {
    const customer_id = company || context.state.company;
    if (campaignId) {
      setLoading(true);
      data?.forEach((question) => {
        const req: RequestInput = {
          pathParams: { company: customer_id, campaign: campaignId },
          body: {
            customer_id: customer_id,
            campaign_id: campaignId,
            question_id: question.id,
          },
        };
        questionCreate.execute(req);
      });
      // TODO: hack to avoid displaying multiple success messages for now.
      setTimeout(() => {
        toast('🦄 Questions Linked to Campaign!');
        getQuestions();
      }, 3000);
    }
  };

  useEffect(() => {
    if (campaignUpdate.data) {
      toast('🦄 Campaign Updated!');
    }
    if (campaignUpdate.error) {
      toast.error(String(campaignUpdate.error));
    }
  }, [campaignUpdate.data, campaignUpdate.error]);

  useEffect(() => {
    if (questionDelete.data) {
      getQuestions();
      toast('🦄 Question Deleted!');
    }
    if (questionDelete.error) {
      toast.error(String(questionDelete.error));
      setLoading(false);
    }
  }, [questionDelete.data, questionDelete.error]);

  useEffect(() => {
    if (questionLinkUpdateBulk.data) {
      toast('🦄 Question Order Updated!');
      setLoading(false);
    }
    if (questionLinkUpdateBulk.error) {
      toast.error(String(questionLinkUpdateBulk.error));
      setLoading(false);
    }
  }, [questionLinkUpdateBulk.data, questionLinkUpdateBulk.error]);

  useEffect(() => {
    if (questionLinkUpdate.data) {
      toast('🦄 Question Link Updated!');
      setLoading(false);
    }
    if (questionLinkUpdate.error) {
      toast.error(String(questionLinkUpdateBulk.error));
      setLoading(false);
    }
  }, [questionLinkUpdate.data, questionLinkUpdate.error]);

  useEffect(() => {
    if (questionCreate.error) {
      toast.error(String(questionDelete.error));
    }
  }, [questionCreate.error]);

  const renderCard = (item: SCardProps) => {
    item.data = campaign;
    if (campaign) {
      if (item.title === 'Category Tags') {
        const handleChange = (values: SelectOption[]) => {
          let vals: string[] = [];
          values.forEach((option) => {
            if (option.id) {
              vals.push(option.id);
            } else {
              vals.push(option as any as string);
            }
          });
          const val: string = vals.join(';');
          updateCampaignField('category_tags', val);
        };
        const cardProps = {
          title: item.title,
          children: (
            <JamyrMultiSelect
              name="category_tags"
              placeholder="e.g. Culture"
              allowinput={true}
              handlechange={handleChange}
              selected={getTagsAsOptions(campaign.category_tags)}
            />
          ),
        };
        return <JamyrCard {...cardProps} />;
      } else if (item.title === 'Questions') {
        const handleRemove = (recordid: number) => {
          if (questions) {
            const req: RequestInput = {
              pathParams: {
                company: campaign.customer_id,
                campaign_id: campaign.id,
                id: recordid,
              },
            };
            setLoading(true);
            questionDelete.execute(req);
          }
        };
        const handleEditLink = (data: SelectOption) => {
          const reqi: RequestInput = {
            pathParams: {
              company: campaign.customer_id,
              campaign: campaign.id,
            },
            body: { id: data.id, prompt: data.detail },
          };
          setLoading(true);
          questionLinkUpdate.execute(reqi);
        };

        const handleResort = (data: SelectOption[] | any) => {
          const payload: { [name: string]: string | any }[] = [];
          data.forEach((item: SelectOption, idx: number) => {
            payload.push({ id: item.id, position: idx });
          });
          const req: RequestInput = {
            pathParams: {
              company: campaign.customer_id,
              campaign: campaign.id,
            },
            body: payload,
          };
          setLoading(true);
          questionLinkUpdateBulk.execute(req);
        };

        const cardProps = {
          title: item.title,
          children: (
            <>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <PopupSelectList
                    options={questionOptions}
                    onSelect={createQuestionLink}
                    title="Add Questions"
                    subtitle="Add questions to this campaign"
                  />
                  <DraggableList
                    items={linkedQuestions}
                    onDragEnd={(data: SelectOption[]) => handleResort(data)}
                    onItemRemove={handleRemove}
                    onItemEdit={handleEditLink}
                  />
                </>
              )}
            </>
          ),
        };
        return <JamyrCard {...cardProps} />;
      } else if (item.title === 'Video Responses') {
        const cardProps = {
          title: item.title,
          children: <Videos campaign={campaign.id} embedded={true} />,
        };
        return <JamyrCard {...cardProps} />;
      } else {
        item.handleUpdate = updateCampaign;
        return <SettingsCard {...item} />;
      }
    } else {
      return <></>;
    }
  };

  return (
    <>
      {campaign && (
        <Grid container sx={{ ml: { xs: 0, sm: 5 }, mr: { xs: 0, sm: 5 } }}>
          <Grid item xs={12}>
            <PageNavigation {...campaignNavItems} />
          </Grid>
          <Grid item xs={12} sx={{ pl: { xs: 2, sm: 5 }, pt: 5 }}>
            <Typography
              component="h1"
              sx={{ fontSize: 24, fontWeight: 'bold' }}
            >
              {campaign.title}
              <CustomIcon
                name="link"
                sx={{ ml: 2, cursor: 'pointer' }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://${campaign.company_name}.jamyr.com/campaign/${campaign.external_id}`
                  );
                  toast('🦄 Link copied!');
                }}
              />
              <CustomIcon
                name="preview"
                sx={{ ml: 2, cursor: 'pointer' }}
                onClick={() =>
                  window.open(
                    `https://${campaign.company_name}.jamyr.com/campaign/${campaign.external_id}`,
                    '_blank'
                  )
                }
              />
            </Typography>
          </Grid>
          {campaignNavItems.map((item: PageNavItem) => {
            return (
              <Grid
                item
                key={item.link}
                xs={12}
                id={item.link}
                sx={{
                  mx: ['details'].includes(item.link) ? { xs: 2, sm: 5 } : 0,
                  mt: ['details'].includes(item.link) ? { xs: 2, sm: 5 } : 0,
                }}
              >
                {renderCard(campaignCardLayout[item.title])}
              </Grid>
            );
          })}
        </Grid>
      )}
      {!campaign && <Loading fullpage={true} />}
    </>
  );
};
