import {
  useQuery,
} from "@tanstack/react-query";
import { Queries, sendRequest, PublicGet } from "./api";

export const useJobsGet = () => {
  return useQuery({
    queryKey: Queries.JobsList.key,
    queryFn: () =>
      sendRequest<PublicGet>(Queries.JobsList.endpoint),
  })
};

export const useJobDetailGet =   (
  qParams: { [name: string]: string }
) => {
  return useQuery({
    queryKey: Queries.JobDetailGet.key,
    queryFn: () =>
      sendRequest<PublicGet>(Queries.JobDetailGet.endpoint, { queryParams: qParams }),
  })
};