import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { TableCard } from '../../components/TableCard';
import {
  JamyrTable,
  TableProps,
  TableAction,
  ColumnSort,
} from '../../atoms/JamyrTable';
import useAPI from '../../utils/useApi';
import { APIRequests, ListResource } from '../../config/api';
import { RequestInput, DataType } from '../../types/config';
import { Collection } from '../../types/collection';
import {
  useGlobalState,
  useCurrentCompany,
} from '../../utils/GlobalStateProvider';
import { CollectionCreate, DeleteConfirm } from '../../components/Forms';
import { PopupModal } from '../../atoms';
import { useModal } from 'mui-modal-provider';
import { toast } from 'react-toastify';
import { isMobile } from '../../utils/global';

export const Collections = (): JSX.Element => {
  const context = useGlobalState(true);
  const company = useCurrentCompany();
  const [tableData, setTableData] = useState<{ [name: string]: string }[]>();
  const [fetchData, setFetchData] = useState<boolean>(false);
  const [sortValue, setSortValue] = useState<ColumnSort>();
  const collections = useAPI<ListResource>(APIRequests.CollectionsList);
  const collectionDelete = useAPI(APIRequests.CollectionDelete);
  let { showModal } = useModal();
  let navigate = useNavigate();

  useEffect(() => {
    let sortParams;
    if (sortValue) {
      sortParams = {
        sort: sortValue.field,
        sortDir: sortValue.direction,
      };
    }
    const req: RequestInput = {
      pathParams: { company: company || context.state.company },
      queryParams: sortParams || {},
    };
    collections.execute(req);
  }, [fetchData, context.state.company, sortValue]);

  useEffect(() => {
    if (collections.data) {
      setTableData(
        (collections.data.records as { [name: string]: string }[]) || []
      );
      setFetchData(false);
    }
  }, [collections.data]);

  const createCollection = () => {
    const modal = showModal(PopupModal, {
      children: (
        <CollectionCreate
          onComplete={() => {
            collections.execute({});
            modal.destroy();
            setFetchData(true);
          }}
        />
      ),
      size: isMobile() ? 'large' : 'small',
    });
  };

  const handlePreviewClick = (idx: number) => {
    if (tableData) {
      const collection: Collection = tableData[idx] as unknown as Collection;
      window.open(
        `https://${collection.company_name}.jamyr.com/collection/${collection.external_id}`,
        '_blank'
      );
    }
  };
  const handleEditClick = (idx: number) => {
    if (tableData) {
      const collection: Collection = tableData[idx] as unknown as Collection;
      navigate(`/dashboard/collections/${collection.id}`);
    }
  };

  const handleDelete = (collection: Collection, onComplete: () => void) => {
    const req: RequestInput = {
      pathParams: {
        id: collection.id,
        company: company || context.state.company,
      },
    };
    collectionDelete.execute(req);
    onComplete();
    setFetchData(true);
  };

  const handleDeleteClick = (idx: number) => {
    if (tableData) {
      const collection: Collection = tableData[idx] as unknown as Collection;
      const modal = showModal(PopupModal, {
        children: (
          <DeleteConfirm
            title="Delete Collection"
            warning={`Are you sure you want to delete ${collection.title}?`}
            callback={() =>
              handleDelete(collection, () => {
                modal.destroy();
                toast('🦄 Collection Deleted!');
              })
            }
          />
        ),
        size: isMobile() ? 'large' : 'small',
      });
    }
  };

  const actions: TableAction[] = [
    {
      header: 'Edit',
      icon: 'edit',
      onClick: handleEditClick,
    },
    {
      header: 'Preview',
      icon: 'preview',
      onClick: handlePreviewClick,
    },
    {
      header: 'Delete',
      icon: 'delete',
      onClick: handleDeleteClick,
    },
  ];

  const tableProps: TableProps = {
    columns: [
      {
        name: 'title',
        label: 'Title',
        sorted:
          sortValue && sortValue.field === 'title'
            ? sortValue.direction
            : undefined,
      },
      {
        name: 'created_at',
        label: 'Created',
        type: DataType.Date,
        sorted:
          sortValue && sortValue.field === 'created_at'
            ? sortValue.direction
            : undefined,
      },
    ],
    rows: tableData,
    actions: actions,
    sortData: (data: ColumnSort) => setSortValue(data),
    emptyMessage: <Typography>No collections</Typography>,
  };
  return (
    <>
      <TableCard
        title="Collections"
        actionClick={createCollection}
        actionLabel="Add"
        actionIcon="add"
      >
        <JamyrTable {...tableProps} />
      </TableCard>
    </>
  );
};
