import React from "react"
import { useLocation, Navigate } from "react-router-dom";
import { isAuthenticated } from "./auth";

export function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();
  if (!isAuthenticated()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}