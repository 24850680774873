import React, { useState, useEffect } from 'react';
import { TableCard, ProcessVideo } from '../../components';
import { JamyrTable, TableProps, TableAction, PopupModal } from '../../atoms';
import useAPI from '../../utils/useApi';
import { APIRequests, ListResource } from '../../config/api';
import { Testimonial } from '../../types/testimonial';
import { RequestInput, DataType } from '../../types/config';
import { TestimonialStatus, StatusTab } from '../../config/layouts/selectLists';
import { toast } from 'react-toastify';
import { useModal } from 'mui-modal-provider';

export const Requests = (): JSX.Element => {
  const [tableData, setTableData] = useState<{ [name: string]: string }[]>();
  const [fetchData, setFetchData] = useState<boolean>(false);
  const { showModal } = useModal();
  const requests = useAPI<ListResource>(APIRequests.TestimonialsListAll);
  const testimonialUpdate = useAPI(APIRequests.TestimonialUpdate);

  useEffect(() => {
    const req: RequestInput = { queryParams: { q: 'status=pending edit' } };
    requests.execute(req);
    setFetchData(false);
  }, [fetchData]);

  useEffect(() => {
    if (requests.data) {
      setTableData(
        (requests.data.records as { [name: string]: string }[]) || []
      );
    }
  }, [requests.data]);

  const handleUploadClick = (idx: number) => {
    if (tableData) {
      const testimonial: Testimonial = tableData[idx] as unknown as Testimonial;
      const modal = showModal(PopupModal, {
        children: (
          <ProcessVideo
            data={testimonial}
            onComplete={() => {
              modal.destroy();
            }}
          />
        ),
      });
    }
  };

  const handleDownloadClick = (idx: number) => {
    if (tableData) {
      const testimonial: Testimonial = tableData[idx] as unknown as Testimonial;
      window.open(testimonial.video, '_blank');
    }
  };

  useEffect(() => {
    if (testimonialUpdate.data) {
      toast('🦄 Video Updated!');
      setFetchData(true);
    }
    if (testimonialUpdate.error) {
      toast.error(String(testimonialUpdate.error));
    }
  }, [testimonialUpdate.data, testimonialUpdate.error]);

  const handleStatusChange = (idx: number, status: StatusTab) => {
    if (tableData) {
      const t = tableData[idx];
      t.status = status.name;
      const req: RequestInput = {
        pathParams: {
          company: t.customer_id,
          id: t.id,
        },
        body: t,
      };
      testimonialUpdate.execute(req);
    }
  };

  const actions: TableAction[] = [
    {
      header: 'Status',
      element: 'select',
      onClick: handleStatusChange,
      selectOptions: TestimonialStatus,
      value: 'status',
      order: -1,
    },
    {
      header: 'Download',
      icon: 'download',
      onClick: handleDownloadClick,
    },
    {
      header: 'Upload',
      icon: 'upload',
      iconColor: '#474747',
      onClick: handleUploadClick,
    },
  ];

  const tableProps: TableProps = {
    columns: [
      { name: 'created_at', label: 'Date', type: DataType.Date },
      { name: 'company_name', label: 'Company' },
      { name: 'question_text', label: 'Question' },
      { name: 'email', label: 'Email' },
    ],
    rows: tableData,
    actions: actions,
  };
  return (
    <>
      <TableCard title="Edit Requests">
        <JamyrTable {...tableProps} />
      </TableCard>
    </>
  );
};
