import React, { useState } from 'react';
import { Divider, List, ListItem, ListItemText } from '@mui/material';
import { JamyrCard, JamyrSwitch, SelectOption } from '../atoms';

export interface OptionToggleConfig {
  options: SelectOption[];
  updatingField: string;
  currentvalue?: string;
  changehandler?: (field: string, value: string) => void;
}

export const OptionToggles = (props: OptionToggleConfig): JSX.Element => {
  const [selected, setSelected] = useState<string>(
    props.currentvalue || props.options[0].id
  );

  const onPageSelect = (id: string) => {
    setSelected(id);
    if (props.changehandler) {
      props.changehandler(props.updatingField, id);
    }
  };
  return (
    <>
      <JamyrCard embedded={true} title="Page Format">
        <List>
          {props.options.map((option: SelectOption) => {
            return (
              <div key={option.id}>
                <ListItem>
                  <ListItemText primary={option.value} />
                  <JamyrSwitch
                    label=""
                    onChange={() => onPageSelect(option.id)}
                    checked={selected === option.id}
                  />
                </ListItem>
                <Divider />
              </div>
            );
          })}
        </List>
      </JamyrCard>
    </>
  );
};
