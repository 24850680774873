import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';

import { CustomIcon } from '../atoms';
import { FileUpload } from './FileUpload';

export interface UploadCardProps {
  label: string;
  uploadPath: string;
  onUpdate: (url: string) => void;
  current?: string;
}

export const UploadCard = (props: UploadCardProps) => {
  const [value, setValue] = useState<string>(props.current || '');
  return (
    <Grid
      item
      xs={12}
      sm={2}
      sx={{ ml: 1, pt: 2 }}
      container
      justifyContent="center"
    >
      {value && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <Grid xs={12} item container justifyContent="center">
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-end"
              sx={{ position: 'relative', top: 10 }}
            >
              <CustomIcon
                name="delete"
                onClick={() => {
                  props.onUpdate('');
                  setValue('');
                }}
                sx={{ zIndex: 10 }}
              />
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                sx={{ position: 'relative', top: -10 }}
              >
                <img
                  src={value}
                  alt="video asset"
                  style={{
                    width: 150,
                    height: 275,
                    maxHeight: 275,
                    borderRadius: 15,
                    objectFit: 'cover',
                  }}
                  crossOrigin="anonymous"
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      {!value && (
        <FileUpload
          label={props.label}
          value={value || ''}
          uploadDir={`testimonials/${props.uploadPath}`}
          showSuccess={true}
          changehandler={(url: string) => {
            props.onUpdate(url);
            setValue(url);
          }}
          filetypes={'image/*'}
          disableDisplay={true}
        />
      )}
    </Grid>
  );
};
