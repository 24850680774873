export enum JamyrCookies {
  SITE = "jamyr-site",
  COMPANY = "jamyr-company",
  EMPLOYEE = "jamyr-employee",
  ID = "jamyr-id"
}

export interface AppRoute {
  title: string;
  path: string;
  primary: boolean;
  icon?: JSX.Element;
  exact?: boolean;
  external?: boolean;
  component?: JSX.Element;
}

export interface APIEndpoint {
  path: string;
  method: string;
  pathParams?: string[];
  queryParams?: string[];
}

export interface RequestInput {
  body?: any;
  pathParams?: { [name: string]: any };
  queryParams?: { [name: string]: any };
}

export interface TrackEvent {
  customer_id: number;
  event: string;
  identity: string;
  location: string;
  data: any;
  created?: string;
}

export interface ErrorDetail {
  status: string;
  statusText: string;
  detail: string;
}

export type CreateFormProps = {
  onComplete: (data?: any) => void;
  data?: any;
};

export enum DataType {
  Date = "string"
}
