import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
export interface JamCardProps {
  title: string;
  children: React.ReactNode;
  embedded?: boolean;
  height?: string;
}

export const JamyrCard = (props: JamCardProps): JSX.Element => {
  return (
    <>
      <Typography
        component="h2"
        sx={{
          position: 'relative',
          top: props.embedded ? -10 : { xs: 10, sm: 30 },
          left: props.embedded ? 0 : { xs: 20, sm: 40 },
          textAlign: 'left',
          fontWeight: 700,
          fontSize: { xs: 16, sm: 24 }
        }}
      >
        {props.title}
      </Typography>
      <Card
        variant="outlined"
        sx={{
          borderRadius: 3,
          padding: { xs: 1, sm: 5 },
          minHeight: props.height ? props.height : 'auto',
          margin: props.embedded ? 0 : { xs: 2, sm: 5 },
          minWidth: {
            xs: '20%', sm: '70%'
          },
          boxShadow: '0px 1px 16px rgba(137, 137, 137, 0.25)',
          display: 'grid',
        }
        }
      >
        <CardContent>{props.children}</CardContent>
      </Card >
    </>
  );
};
