import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from "react";

import { APIRequests } from "../config/api";
import { User } from "../types/user";
import useAPI from "../utils/useApi";
import { JamyrCookies } from "../types/config";
import { getCookie } from "../utils/global";

export interface GlobalStateInterface {
  user: User;
  company: number;
  companyName: string;
}

export type GlobalContext = {
  state: GlobalStateInterface;
  setState: Dispatch<SetStateAction<GlobalStateInterface>>;
};

const initialState: GlobalStateInterface = {
  user: {} as User,
  company: 0,
  companyName: ""
};

const GlobalStateContext = createContext({
  state: { ...initialState },
  setState: {} as Dispatch<SetStateAction<GlobalStateInterface>>
});

const GlobalStateProvider = ({
  children,
  value = { ...initialState } as GlobalStateInterface
}: {
  children: React.ReactNode;
  value?: GlobalStateInterface;
}): JSX.Element => {
  const [state, setState] = useState(value);
  return (
    <GlobalStateContext.Provider value={{ state, setState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

const useGlobalState = (initialize?: Boolean): GlobalContext => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateContext");
  }

  const user = useAPI<User>(APIRequests.Me);
  useEffect(() => {
    if (!context.state.user === false && initialize) {
      user.execute({});
    }
  }, []);

  useEffect(() => {
    if (initialize && user.data) {
      context.setState(prevState => ({
        ...prevState,
        ...{ user: user.data, company: user.data.customer_id }
      }));
    }
  }, [user.data]);

  return context;
};

const useCurrentCompany = (): number => {
  const current = getCookie(JamyrCookies.COMPANY);
  const context = useGlobalState();
  if (current) {
    return parseInt(current);
  } else {
    return context.state.company;
  }
};

export { GlobalStateProvider, useGlobalState, useCurrentCompany };
