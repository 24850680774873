import React from "react";
import { Button, Grid, Typography } from "@mui/material";

export type DCProps = {
  title: string;
  warning: string;
  callback: () => void;
  detail?: string;
  buttonLabel?: string;
};

export const DeleteConfirm = (props: DCProps): JSX.Element => {
  return (
    <>
      <Grid container spacing={2} sx={{ paddingLeft: 5, paddingRight: 5 }}>
        <Grid item xs={12}>
          <Typography component="h1" sx={{ tabIndex: 0, fontSize: 24 }}>
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="h4" sx={{ tabIndex: 0, fontSize: 18 }}>
            {props.warning}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="p" sx={{ tabIndex: 0, fontSize: 14 }}>
            {props.detail}
          </Typography>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Button
            onClick={props.callback}
            sx={{
              minWidth: "200px",
              bottom: 50,
              position: "absolute",
              backgroundColor: "red"
            }}
          >
            {props.buttonLabel || "Delete"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
