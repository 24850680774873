import React, { useState, useEffect } from 'react';
import { PublicHeader, JamyrPlayer } from '../components';
import { CTA, Loading } from '../atoms';
import { useParams, useSearchParams } from 'react-router-dom';
import useAPI from '../utils/useApi';
import { APIRequests, PublicGet } from '../config/api';
import { RequestInput } from '../types/config';
import { Testimonial, LandingPageFormat } from '../types/testimonial';
import { CustomerSetting } from '../types/user';
import { Box, Grid, Typography, Avatar } from '@mui/material';
import { isMobile, track } from '../utils/global';

export const LandingPage = (): JSX.Element => {
  const { pathId } = useParams();
  const video = useAPI<PublicGet>(APIRequests.TestimonialGetPublic);
  const [testimonial, setTestimonial] = useState<{
    record: Testimonial;
    settings: CustomerSetting;
  }>();
  const [hasError, setHasError] = useState<Boolean>(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (pathId) {
      let videoId = pathId;
      if (pathId in Object.keys(LandingPageFormat)) {
        videoId = searchParams.get('video') || '';
      }
      if (videoId) {
        const req: RequestInput = {
          pathParams: { id: videoId, company: 0 },
        };
        video.execute(req);
      }
    }
  }, [pathId]);

  useEffect(() => {
    if (video.data) {
      const t = video.data.data as Testimonial;
      track('page-view', `${t.customer_id}`, { video: t.external_id });
      setTestimonial({ record: t, settings: video.data.settings });
    }
    if (video.error) {
      setHasError(true);
    }
  }, [video.data, video.error]);

  return (
    <>
      {testimonial ? (
        <div
          style={{
            backgroundColor: '#F7F7FB',
            minHeight: '100vh',
          }}
        >
          <PublicHeader settings={testimonial.settings} />
          <Grid
            container
            padding={
              testimonial.record.page_style === LandingPageFormat.JamLand ||
              isMobile()
                ? 1
                : 10
            }
            sx={{
              maxWidth:
                testimonial.record.page_style === LandingPageFormat.JamLand
                  ? '50%'
                  : '80%',
              margin: 'auto',
              textAlign:
                testimonial.record.page_style === LandingPageFormat.JamLand
                  ? 'center'
                  : 'left',
              height: 'auto',
            }}
          >
            <Grid
              container
              item
              xs={12}
              md={
                testimonial.record.page_style === LandingPageFormat.JamLand
                  ? 12
                  : 6
              }
              sx={{
                px: {
                  xl:
                    testimonial.record.page_style === LandingPageFormat.JamLand
                      ? 50
                      : 0,
                },
              }}
              justifyContent="center"
            >
              <Box
                sx={{
                  pt: {
                    xs: 0,
                    sm:
                      testimonial.record.page_style ===
                      LandingPageFormat.JamLand
                        ? 5
                        : 10,
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: 38,
                    mb: 5,
                    color: testimonial.settings.landing_text_color,
                    fontWeight: 'bold',
                  }}
                >
                  {testimonial.record.question_text}
                </Typography>

                {/* This is to have the "Learn More" button above the video player to match the example on JAM-288 */}

                {(testimonial.record.cta_btn_text ||
                  testimonial.settings.landing_btn_text) &&
                  testimonial.record.page_style ===
                    LandingPageFormat.JamLand && (

                    <Box sx={{ pb: 4 }}>

                      <CTA
                        customerId={`${testimonial.record.customer_id}`}
                        btnId={`lp-click-${testimonial.record.external_id}`}
                        video={testimonial.record.external_id}
                        text={
                          testimonial.record.cta_btn_text ||
                          testimonial.settings.landing_btn_text
                        }
                        link={
                          testimonial.record.cta_link ||
                          testimonial.settings.landing_btn_link
                        }
                        btnColor={testimonial.settings.landing_btn_color}
                        btnTextColor={
                          testimonial.settings.landing_btn_txt_color
                        }
                        btnAlignment={'center'}
                      />
                    </Box>
                  )}

                {testimonial.record.page_style ===
                  LandingPageFormat.JamLand && (
                  <JamyrPlayer
                    videoId={testimonial.record.external_id}
                    videoSrc={
                      testimonial.record.final_video || testimonial.record.video
                    }
                    alt={testimonial.record.question_text}
                    customerId={`${testimonial.record.customer_id}`}
                    languages={testimonial?.settings.languages}
                    height={isMobile() ? undefined : '70vh'}
                    width={isMobile() ? undefined : 'auto'}
                  />
                )}
                <Box
                  sx={{
                    display: 'flex',
                    pt:
                      testimonial.record.page_style ===
                      LandingPageFormat.JamLand
                        ? 2
                        : 0,
                  }}
                  justifyContent={
                    testimonial.record.page_style === LandingPageFormat.JamLand
                      ? 'center'
                      : 'left'
                  }
                >
                  <Avatar
                    sx={{ width: 56, height: 56 }}
                    src={testimonial.record.thumbnail}
                    imgProps={{ crossOrigin: 'anonymous' }}
                  />
                  <Box>
                    <Typography
                      component="p"
                      sx={{
                        pl: 1,
                        pt: 0.5,
                        color: testimonial.settings.landing_text_color,
                        textAlign: 'left',
                      }}
                    >{`${testimonial.record.first_name}`}</Typography>
                    <Typography
                      component="p"
                      sx={{
                        pl: 1,
                        color: testimonial.settings.landing_text_color,
                      }}
                    >{`${testimonial.record.title}`}</Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    py: 3,
                    color: testimonial.settings.landing_text_color,
                    px: { xs: 2, sm: 0 },
                  }}
                >
                  {testimonial.record.landing_text ||
                    testimonial.settings.landing_text}
                </Typography>
                {(testimonial.record.cta_btn_text ||
                  testimonial.settings.landing_btn_text) &&
                  testimonial.record.page_style !==
                    LandingPageFormat.JamLand && (
                    <CTA
                      customerId={`${testimonial.record.customer_id}`}
                      btnId={`lp-click-${testimonial.record.external_id}`}
                      video={testimonial.record.external_id}
                      text={
                        testimonial.record.cta_btn_text ||
                        testimonial.settings.landing_btn_text
                      }
                      link={
                        testimonial.record.cta_link ||
                        testimonial.settings.landing_btn_link
                      }
                      btnColor={testimonial.settings.landing_btn_color}
                      btnTextColor={testimonial.settings.landing_btn_txt_color}
                      btnAlignment={
                        testimonial.record.page_style ===
                        LandingPageFormat.JamLand
                          ? 'center'
                          : 'left'
                      }
                    />
                  )}
              </Box>
            </Grid>
            {testimonial.record.page_style !== LandingPageFormat.JamLand && (
              <Grid
                container
                item
                xs={12}
                md={6}
                justifyContent="center"
                sx={{ pt: { xs: 4, sm: 0 } }}
              >
                <JamyrPlayer
                  videoId={testimonial.record.external_id}
                  videoSrc={
                    testimonial.record.final_video || testimonial.record.video
                  }
                  alt={testimonial.record.question_text}
                  customerId={`${testimonial.record.customer_id}`}
                  languages={testimonial?.settings.languages}
                />
              </Grid>
            )}
          </Grid>
        </div>
      ) : hasError ? (
        <Typography> Something went very wrong </Typography>
      ) : (
        <Loading />
      )}
    </>
  );
};
