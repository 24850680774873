import React from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { CustomIcon } from '../atoms/CustomIcon';
export interface TableCardProps {
  title: string;
  children?: React.ReactNode;
  actionLabel?: string;
  actionIcon?: string;
  actionClick?: () => void;
  containerPadding?: number;
  paperMargin?: number;
}

export const TableCard = (props: TableCardProps): JSX.Element => {
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      sx={{
        width: { xs: '87%', sm: '100%' },
        pl: { xs: 1, sm: props.containerPadding || 10 },
        pr: { xs: 1, sm: props.containerPadding || 10 },
        pt: { xs: 4, sm: 0 },
      }}
    >
      <Paper
        sx={{
          marginTop: props.paperMargin || 15,
          paddingLeft: 3,
          paddingRight: { xs: 1, sm: 3 },
          borderRadius: 5,
          boxShadow: '0px 6px 12px rgba(137, 137, 137, 0.08)',
          minHeight: 200,
          minWidth: '100%',
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          sx={{
            position: 'relative',
            top: -50,
            mb: props.title === '' ? 3 : 0,
          }}
        >
          <Grid item>
            <Typography
              variant="h3"
              sx={{
                fontSize: 24,
                fontWeight: 'bold',
              }}
            >
              {props.title}
            </Typography>
          </Grid>
          {props.actionLabel && (
            <Grid item sx={{ position: 'relative', top: -5 }}>
              <Button
                onClick={props.actionClick}
                sx={{
                  minWidth: { xs: 85, sm: 130 },
                  minHeight: { xs: 24, sm: 45 },
                  borderRadius: 2,
                }}
              >
                {props.actionIcon && (
                  <CustomIcon
                    name={props.actionIcon}
                    fontSize="small"
                    hex="white"
                  />
                )}
                <Typography
                  component="span"
                  sx={{ fontSize: 16, paddingLeft: 1 }}
                >
                  {props.actionLabel}
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
        {props.children && props.children}
      </Paper>
    </Box>
  );
};
