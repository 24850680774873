import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  Chip,
  TextField,
  TextFieldProps,
} from '@mui/material';

export type SelectOption = {
  id: string;
  value: string;
  detail?: string;
};

export type JMultiProps = TextFieldProps & {
  options?: SelectOption[];
  selected?: SelectOption[];
  allowinput?: boolean;
  handlechange?: (values: SelectOption[]) => void;
};

export const JamyrMultiSelect = (props: JMultiProps): JSX.Element => {
  const [values, setValues] = useState<SelectOption[]>(props.selected || []);

  const handleDelete = (value: SelectOption) => {
    const newValues = values.filter((v) => v.id !== value.id);
    setValues(newValues);
    if (props.handlechange) {
      props.handlechange(newValues);
    }
  };
  const handleChange = (newValues: any) => {
    // newValues is a list of strings (when the value is newly entered)
    // and select options (when the values come from the parent component)
    // So here we handle to convert the string to a selectoption
    // Not sure if it even makes sense to use the selectoption type anymore
    // but would need to review
    let valsToUpdate: SelectOption[] = [];
    newValues.forEach((val: any) => {
      if (typeof val === 'string') {
        valsToUpdate.push({ id: val, value: val });
      } else {
        valsToUpdate.push(val);
      }
    });
    setValues(valsToUpdate);
    if (props.handlechange) {
      props.handlechange(valsToUpdate);
    }
  };
  return (
    <>
      <Autocomplete
        id={props.name}
        size="medium"
        onChange={(event, newValue) => {
          handleChange(newValue as SelectOption[]);
        }}
        value={values}
        multiple
        disableClearable
        freeSolo={props.allowinput}
        filterSelectedOptions={props.allowinput ? false : true}
        options={props.options || []}
        isOptionEqualToValue={(option, value) =>
          props.allowinput ? option === value : option.value === value.value
        }
        // @ts-ignore
        getOptionLabel={(option) => option.value ?? option}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.placeholder}
            label={props.label}
          />
        )}
      />
      <Box
        mt={3}
        sx={{
          position: 'relative',
          top: 20,
        }}
      >
        {values.map((v) => (
          <Chip
            key={v.id ?? v}
            label={v.value ?? v}
            onDelete={() => handleDelete(v)}
            color="primary"
            sx={{
              backgroundColor: 'info.main',
              color: 'white',
              margin: 1,
              maxWidth: '70%',
            }}
          />
        ))}
      </Box>
    </>
  );
};
