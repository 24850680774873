import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { track } from '../utils/global';

export type CtaProps = {
  btnId: string;
  text: string;
  customerId: string;
  video?: string;
  btnColor?: string;
  btnTextColor?: string;
  link?: string;
  func?: () => void;
  subText?: string;
  subTextLink?: string | undefined;
  subTextFunction?: () => void;
  icon?: JSX.Element;
  btnAlignment?: string;
};

export const CTA = (props: CtaProps): JSX.Element => {
  const handleClick = () => {
    track('btn-click', props.customerId, {
      id: props.btnId,
      video: props.video,
      link: props.link || '',
      text: props.text,
    });
    if (props.link) {
      window.open(props.link, '_blank');
    } else if (props.func) {
      props.func();
    }
  };

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        justifyContent={props.btnAlignment || 'left'}
        sx={{ mb: props.icon ? 2 : 0 }}
      >
        <Button
          variant="contained"
          id={props.btnId}
          disabled={!props.func && !props.link}
          sx={{
            borderRadius: 2,
            display: 'flex',
            maxHeight: '60px',
            width: 'auto',
            maxWidth: '280px',
            backgroundColor: props.btnColor || 'info.main',
            color: props.btnTextColor || '#fff',
            '&.Mui-disabled': {
              backgroundColor: 'info.main',
              color: '#fff',
            },
          }}
          onClick={handleClick}
        >
          <Grid container item xs={12} justifyContent="center">
            <Grid item sx={{ pr: 1, pt: 0.5 }}>
              {props.icon && props.icon}
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontSize: { xs: '0.8rem', sm: '1rem' },
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {props.text}
              </Typography>
            </Grid>
          </Grid>
        </Button>
      </Grid>
      {props.subText && (
        <Grid container item xs={12} justifyContent="center">
          <Typography
            sx={{
              fontSize: { xs: 12 },
              paddingTop: 2,
              textAlign: 'center',
              textDecoration:
                props.subTextLink || props.subTextFunction
                  ? 'underline'
                  : 'none',
              cursor:
                props.subTextLink || props.subTextFunction ? 'pointer' : 'auto',
            }}
            onClick={props.subTextFunction && props.subTextFunction}
          >
            {props.subText}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
