import React from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { CustomIcon, SelectOption } from '../atoms';

type BoxSelectProps = {
  title: string;
  options: SelectOption[];
  onSelect: (selection: string) => void;
};
const newItemClass = {
  cursor: 'pointer',
  mt: { xs: 1, sm: 8 },
  mx: { xs: 0, sm: 1 },
  p: { xs: 2, sm: 2 },
  maxWidth: { xs: 250, sm: 250 },
  minWidth: 150,
  minHeight: { xs: 150, sm: 250 },
  maxHeight: { xs: 150, sm: 250 },
  borderRadius: 3,
  boxShadow: '0px 1px 16px rgba(137, 137, 137, 0.25)',
  backgroundColor: '#F7F7FB',
  position: 'relative',
};

const baseClass = {
  cursor: 'pointer',
  mt: { xs: 1, sm: 8 },
  mx: { xs: 0, sm: 1 },
  p: { xs: 2, sm: 2 },
  maxWidth: { xs: 250, sm: 250 },
  minWidth: 150,
  minHeight: { xs: 150, sm: 250 },
  maxHeight: { xs: 150, sm: 250 },
  borderRadius: 3,
  background:
    'linear-gradient(146deg, rgba(249, 225, 255, 0.4) 17%, rgba(225, 244, 255, 0.5))',
  boxShadow: '0px 1px 16px rgba(137, 137, 137, 0.25)',
  position: 'relative',
};
export const BoxSelection = (props: BoxSelectProps): JSX.Element => {
  console.log(props.options);
  return (
    <>
      <Grid
        container
        padding={1}
        sx={{ m: { xs: 1, sm: 6 }, mt: { xs: 6, sm: 6 } }}
      >
        <Grid item xs={12}>
          <Typography variant="h5"> {props.title} </Typography>
        </Grid>
        {props.options.map((option: SelectOption) => {
          return (
            <Grid item xs={12} md={3} key={option.id}>
              <Card
                id={option.id}
                onClick={() => props.onSelect(option.id)}
                sx={option.id ? baseClass : newItemClass}
              >
                <Typography
                  component="h4"
                  sx={{ fontWeight: 'bold', fontSize: 18 }}
                >
                  {option.value}
                </Typography>
                <Typography
                  component="p"
                  sx={{
                    pt: 2,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    // whiteSpace: 'nowrap',
                  }}
                >
                  {option.detail}
                </Typography>
                <CustomIcon
                  name={option.id === '' ? 'add' : 'edit'}
                  sx={{ position: 'absolute', bottom: 2, right: 2 }}
                />
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
