import React, { useState } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Divider,
  Grid,
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { AppRoute } from '../types/config';
import { routes } from '../config/routes';
import { isMobile } from '../utils/global';
import { useGlobalState } from '../utils/GlobalStateProvider';
import { CustomIcon } from '../atoms/CustomIcon';
import { alignProperty } from '@mui/material/styles/cssUtils';
import { logout } from '../utils/auth';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  backgroundSize: 'cover',
  background: 'linear-gradient(#02B3FF, #7A3BFF)',
  overflowX: 'hidden',
  borderRadius: '0px',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  background: 'linear-gradient(#02B3FF, #7A3BFF)',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const Navigation = (): JSX.Element => {
  const [open, setOpen] = useState(isMobile() ? false : true);
  const context = useGlobalState(true);
  const navigate = useNavigate();

  const renderRoute = (route: AppRoute): JSX.Element => {
    return (
      <ListItemButton
        key={route.title}
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
        onClick={() =>
          !route.external
            ? navigate(route.path)
            : window.open(route.path, '_blank')
        }
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {route.icon}
        </ListItemIcon>
        <ListItemText
          primary={route.title}
          sx={{ opacity: open ? 1 : 0, color: 'white' }}
        />
      </ListItemButton>
    );
  };

  return (
    <Box sx={{ display: 'flex', mb: 10 }}>
      <CssBaseline />
      <StyledDrawer variant="permanent" open={open}>
        <DrawerHeader
          sx={{
            marginBottom: 10,
          }}
        >
          {open && (
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}
            >
              <img
                src="/images/jamyr-recruitics.svg"
                alt="Jamyr Logo"
                style={{
                  width: '90%',
                  margin: 'auto',
                  display: 'block',
                }}
              />
            </ListItemButton>
          )}
          <IconButton onClick={() => setOpen(!open)}>
            {open ? (
              <ChevronLeft color="secondary" />
            ) : (
              <ChevronRight color="secondary" />
            )}
          </IconButton>
        </DrawerHeader>
        <List>
          {routes.map((route: AppRoute) => route.primary && renderRoute(route))}
        </List>
        <Divider sx={{ marginTop: 10 }} />
        <List>
          {routes.map(
            (route: AppRoute) => !route.primary && renderRoute(route)
          )}
          {open && (
            <Grid
              container
              justifyContent="center"
              spacing={2}
              sx={{ paddingTop: 10, width: '100%' }}
            >
              <Grid item xs={12}>
                {context.state?.user?.scopes?.includes('global_admin') && (
                  <Button
                    variant="outlined"
                    href="/admin/customers"
                    sx={{
                      width: '100%',
                      mr: 5,
                      ml: 1,
                      border: 'none',
                    }}
                  >
                    Admin Panel
                  </Button>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    logout();
                  }}
                  sx={{
                    width: '100%',
                    mr: 5,
                    ml: 1,
                    border: 'none',
                  }}
                >
                  <CustomIcon name="logout" hex="white" sx={{ mr: 1 }} /> Logout
                </Button>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          )}
        </List>
      </StyledDrawer>
      <Outlet />
    </Box>
  );
};
