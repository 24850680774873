import React, { useState, useEffect } from "react";
import { TableCard } from "../../components/TableCard";
import {
  ColumnSort,
  JamyrTable,
  TableProps,
  TableAction,
  PopupModal,
  JamyrTextField
} from "../../atoms";
import { DeleteConfirm, QuestionCreate } from "../../components/Forms";
import { useModal } from "mui-modal-provider";
import useAPI from "../../utils/useApi";
import { APIRequests, ListResource } from "../../config/api";
import { RequestInput } from "../../types/config";
import { Question } from "../../types/testimonial";
import {
  useGlobalState,
  useCurrentCompany
} from "../../utils/GlobalStateProvider";
import { toast } from "react-toastify";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Questions = (): JSX.Element => {
  const context = useGlobalState(true);
  const company = useCurrentCompany();
  const [sortValue, setSortValue] = useState<ColumnSort>();
  const { showModal } = useModal();
  const navigate = useNavigate();
  const [fetchData, setFetchData] = useState<boolean>(false);
  const [tableData, setTableData] = useState<{ [name: string]: string }[]>();
  const [questionValue, setQuestionValue] = useState<string>();
  const questions = useAPI<ListResource>(APIRequests.QuestionsList);
  const questionDelete = useAPI(APIRequests.QuestionDelete);
  const questionCreate = useAPI(APIRequests.QuestionCreate);

  useEffect(() => {
    let sortParams;
    if (sortValue) {
      sortParams = {
        sort: sortValue.field,
        sortDir: sortValue.direction
      };
    }
    const req: RequestInput = {
      pathParams: { company: company || context.state.company },
      queryParams: sortParams || {}
    };
    questions.execute(req);
    setFetchData(false);
  }, [fetchData, context.state.company, sortValue]);

  useEffect(() => {
    if (questions.data) {
      setTableData(
        (questions.data.records as { [name: string]: string }[]) || []
      );
      setFetchData(false);
    }
  }, [questions.data]);

  useEffect(() => {
    if (questionCreate.data) {
      toast("🦄 Question Created!");
      setFetchData(true);
    }
    if (questionCreate.error) {
      toast.error(String(questionCreate.error));
    }
  }, [questionCreate.data, questionCreate.error]);

  const createQuestion = () => {
    if (questionValue) {
      const cid = company || context.state.company;
      const q = {
        customer_id: cid,
        text: questionValue
      };
      const req: RequestInput = {
        pathParams: { company: cid },
        body: q
      };
      questionCreate.execute(req);
    }
  };

  useEffect(() => {
    if (questionDelete.data) {
      toast("🦄 Question Deleted!");
    }
    if (questionDelete.error) {
      toast.error(String(questionDelete.error));
    }
  }, [questionDelete.data, questionDelete.error]);

  const handleDelete = (question: Question, onComplete: () => void) => {
    const req: RequestInput = {
      pathParams: {
        id: question.id,
        company: company || context.state.company
      }
    };
    questionDelete.execute(req);
    onComplete();
    setFetchData(true);
  };

  const handleDeleteClick = (idx: number) => {
    if (tableData) {
      const question: Question = (tableData[idx] as unknown) as Question;
      const modal = showModal(PopupModal, {
        children: (
          <DeleteConfirm
            title="Delete Question"
            warning={`Are you sure you want to delete ${question.text}?`}
            callback={() =>
              handleDelete(question, () => {
                modal.destroy();
              })
            }
          />
        ),
        size: "small"
      });
    }
  };

  const handleEditClick = (idx: number) => {
    if (tableData) {
      const question: Question = (tableData[idx] as unknown) as Question;
      const modal = showModal(PopupModal, {
        children: (
          <QuestionCreate onComplete={() => modal.destroy()} data={question} />
        ),
        size: "small"
      });
    }
  };

  const actions: TableAction[] = [
    {
      header: "Edit",
      icon: "edit",
      onClick: handleEditClick
    },
    {
      header: "Delete",
      icon: "delete",
      onClick: handleDeleteClick
    }
  ];

  const tableProps: TableProps = {
    columns: [
      {
        name: "text",
        label: "Question",
        sorted:
          sortValue && sortValue.field === "text"
            ? sortValue.direction
            : undefined
      }
    ],
    rows: tableData,
    actions: actions,
    sortData: (data: ColumnSort) => setSortValue(data),
    emptyMessage: (
      <Typography>
        Get started by adding a question in the field above!{" "}
      </Typography>
    )
  };
  return (
    <>
      <TableCard
        title="Questions"
        actionClick={() => navigate("/dashboard/campaigns/create")}
        actionLabel="Campaign"
        actionIcon="add"
      >
        <JamyrTextField
          name="text"
          label="Add Custom Questions"
          currentvalue={questionValue}
          placeholder="e.g. What is the company culture like?"
          disableDebounce={true}
          sx={{ pb: { xs: 2, sm: 7 }, width: { xs: "95%", sm: "50%" } }}
          changehandler={(field: string, value: string) =>
            setQuestionValue(value)
          }
          required
          reset={fetchData}
        />
        <Button
          sx={{
            ml: { xs: 0, sm: 1 },
            mb: { xs: 3, sm: 0 },
            borderRadius: 2,
            minHeight: 45,
            minWidth: 75
          }}
          onClick={createQuestion}
        >
          <Typography sx={{ lineHeight: "1.8em" }}> Save </Typography>
        </Button>
        <JamyrTable {...tableProps} />
      </TableCard>
    </>
  );
};
