import React, { useState } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { Box, Drawer, List, CssBaseline, Divider } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AppRoute, JamyrCookies } from '../types/config';
import { adminRoutes } from '../config/routes';
import { eraseCookie, isMobile } from '../utils/global';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  backgroundColor: '#5352ED',
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#5352ED',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const AdminNavigation = (): JSX.Element => {
  const [open, setOpen] = useState(isMobile() ? false : true);
  eraseCookie(JamyrCookies.COMPANY);
  const navigate = useNavigate();

  const renderRoute = (route: AppRoute): JSX.Element => {
    return (
      <ListItemButton
        key={route.title}
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
        onClick={() => navigate(route.path)}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {route.icon}
        </ListItemIcon>
        <ListItemText
          primary={route.title}
          sx={{ opacity: open ? 1 : 0, color: 'white' }}
        />
      </ListItemButton>
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <StyledDrawer variant="permanent" open={open}>
        <DrawerHeader>
          {open && (
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <img
                src="/images/jamyr-logo-white.png"
                alt="Jamyr Logo"
                style={{
                  width: '90%',
                  margin: 'auto',
                  display: 'block',
                }}
              />
            </ListItemButton>
          )}
          <IconButton>
            {open ? (
              <ChevronLeftIcon
                color="secondary"
                onClick={() => setOpen(!open)}
              />
            ) : (
              <ChevronRightIcon
                color="secondary"
                onClick={() => setOpen(!open)}
              />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>{adminRoutes.map((route: AppRoute) => renderRoute(route))}</List>
      </StyledDrawer>
      <Divider />
      <Outlet />
    </Box>
  );
};
