import {
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import {
  Customer,
  CustomerSetting,
} from "../../types";
import { Queries, sendRequest, queryClient } from "./api";

export const useCustomerGet = (
  pParams: { [name: string]: string }
) => {
  return useQuery({
    queryKey: Queries.CustomerGet.key,
    queryFn: () =>
      sendRequest<Customer>(Queries.CustomerSettingGet.endpoint, { pathParams: pParams }),
  })
};

export const useCustomerSettingGet = (
  pParams: { [name: string]: any }
) => {
  return useQuery({
    queryKey: Queries.CustomerSettingGet.key,
    queryFn: () =>
      sendRequest<CustomerSetting>(Queries.CustomerSettingGet.endpoint, { pathParams: pParams }),
    enabled: !!pParams.id,
    onSuccess: () => {
      console.log('fetched settings')
    }
  })
};

export const useCustomerSettingUpdate = (
  pParams: { [name: string]: any }
) => {
  return useMutation({
    mutationKey: Queries.CustomerSettingUpdate.key,
    mutationFn: (data: CustomerSetting) =>
      sendRequest<CustomerSetting>(Queries.CustomerSettingUpdate.endpoint, { body: data, pathParams: pParams }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: Queries.CustomerSettingGet.key })
  })
};