import React, { useState } from 'react';
import { Testimonial } from '../types';
import { format } from 'date-fns';
import { Box, Typography } from '@mui/material';
import { TestimonialStatus } from '../config/layouts/selectLists';
import { CustomIcon, JamyrSelectField } from '../atoms';

export interface VideoCardProps {
  testimonial: Testimonial;
  onClick: (testimonial: Testimonial) => void;
}

export const VideoCard = (props: VideoCardProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const handleUpdateTestimonial = (field: string, value: string) => {
    console.log(field, value);
  };
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const handleClick = () => {
    setIsSelected(!isSelected);
    props.onClick(props.testimonial);
  };

  return (
    <Box sx={{ maxWidth: 150, ml: 2 }}>
      <Box
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          position: 'relative',
        }}
        onClick={handleClick}
      >
        <img
          src={props.testimonial.thumbnail}
          alt="preview"
          crossOrigin="anonymous"
          style={{
            width: 150,
            height: 275,
            maxHeight: 275,
            borderRadius: 15,
            objectFit: 'cover',
            border: isSelected ? '5px solid green' : 'inherit',
          }}
        />
        {isHovered && (
          <Box
            sx={{
              width: 150,
              height: 275,
              maxHeight: 275,
              borderRadius: 3,
              backgroundColor: 'rgba(0,0,0, 0.6)',
              zIndex: 1,
              position: 'absolute',
              top: 0,
            }}
          >
            <JamyrSelectField
              name="status"
              options={TestimonialStatus}
              onChange={(data) => handleUpdateTestimonial('status', data)}
              value={
                TestimonialStatus.filter(
                  (option) => props.testimonial.status === option.name
                )[0]
              }
              sx={{ backgroundColor: 'white', m: 1 }}
            />
          </Box>
        )}
      </Box>
      <Box>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
            {`${props.testimonial.first_name} ${props.testimonial.last_name}`}
          </Typography>
          <CustomIcon
            name="preview"
            sx={{ position: 'relatve', pl: 1, pt: 1, cursor: 'pointer' }}
            onClick={() =>
              window.open(
                `/dashboard/videos/${props.testimonial.external_id}`,
                '_self'
              )
            }
          />
        </Box>

        <Typography color="text.disabled">{props.testimonial.label}</Typography>
        <Typography color="text.disabled">
          {format(Date.parse(props.testimonial.created_at), 'MM/dd/yyyy')}
        </Typography>
      </Box>
    </Box>
  );
};
