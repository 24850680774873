import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ResponseParams } from '../../views/Responses';

export const Welcome = (props: ResponseParams) => {
  return (
    <>
      <Grid container justifyContent="center">
        <img
          src="../../images/jamyr-recruitics.svg"
          alt="Jamyr Logo"
          crossOrigin="anonymous"
          style={{
            width: '30%',
            margin: 'auto',
            display: 'block',
          }}
        />
        <Grid item xs={12}>
          <Typography
            variant="h1"
            sx={{
              fontSize: 24,
              fontWeight: 'bold',
              textAlign: 'center',
              my: 3,
            }}
          >
            Record & Upload Videos
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography component="p" sx={{ textAlign: 'center' }}>
            Thanks for taking the time to share your experience
          </Typography>
        </Grid>
        {props.campaign.prompt && (
          <Grid item xs={12}>
            <Typography component="p" sx={{ textAlign: 'center' }}>
              {props.campaign.prompt}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography component="p" sx={{ textAlign: 'center' }}>
            Click the 'Get Started' button when you're ready
          </Typography>
          {props.settings && props.settings.consent_link && (
            <Typography sx={{ mt: 5 }}>
              {`By using this platform, I give my company permission to use my content per the `}
              <a
                href={props.settings.consent_link}
                style={{ textDecoration: 'underline' }}
                target="_blank"
              >
                authorization agreement.
              </a>
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {props.questions &&
          props.questions.map((question) => {
            return (
              <Box
                key={question.external_id}
                sx={{
                  border: '1px solid #7E7CF2',
                  borderRadius: 2,
                  p: 1,
                  my: 1,
                }}
              >
                <Typography sx={{ textAlign: 'center' }}>
                  {question.text}
                </Typography>
              </Box>
            );
          })}
      </Grid>
    </>
  );
};
