import { SelectOption } from '../atoms';
export const BASE_URL: string = process.env.REACT_APP_BASE_URL || "";
export const BACKEND_URL: string = process.env.REACT_APP_API_URL || "";
export const S3_BUCKET: string = process.env.REACT_APP_S3_BUCKET || "";

export const transcriptionLanguages: SelectOption[] = [
  { id: "af-ZA", value: "Afrikaans" },
  { id: "ar-AE", value: "Arabic, Gulf" },
  { id: "ar-SA", value: "Arabic, Modern Standard" },
  { id: "zh-CN", value: "Chinese, Simplified" },
  { id: "zh-TW", value: "Chinese, Traditional" },
  { id: "da-DK", value: "Danish" },
  { id: "nl-NL", value: "Dutch" },
  // Removing english variants
  // {id: "en-AU",value: "English, Australian"},
  // {id: "en-GB",value: "English, British"},
  // {id: "en-IN",value: "English, Indian"},
  // {id: "en-IE",value: "English, Irish"},
  // {id: "en-NZ",value: "English, New Zealand"},
  // {id: "en-AB",value: "English, Scottish"},
  // {id: "en-ZA",value: "English, South African"},
  // {id: "en-WL",value: "English, Welsh"},
  { id: "fr-FR", value: "French" },
  { id: "fr-CA", value: "French, Canadian" },
  { id: "fa-IR", value: "Farsi" },
  { id: "de-DE", value: "German" },
  { id: "de-CH", value: "German, Swiss" },
  { id: "he-IL", value: "Hebrew" },
  { id: "hi-IN", value: "Hindi, Indian" },
  { id: "id-ID", value: "Indonesian" },
  { id: "it-IT", value: "Italian" },
  { id: "ja-JP", value: "Japanese" },
  { id: "ko-KR", value: "Korean" },
  { id: "ms-MY", value: "Malay" },
  { id: "pt-PT", value: "Portuguese" },
  { id: "pt-BR", value: "Portuguese, Brazilian" },
  { id: "ru-RU", value: "Russian" },
  { id: "es-ES", value: "Spanish" },
  { id: "es-US", value: "Spanish, US" },
  { id: "sv-SE", value: "Swedish" },
  { id: "ta-IN", value: "Tamil" },
  { id: "te-IN", value: "Telugu" },
  { id: "th-TH", value: "Thai" },
  { id: "tr-TR", value: "Turkish" },
  { id: "vi-VN", value: "Vietnamese" }
]

export const videoExportOptions = [
  { name: '', label: 'Original', dimensions: '' },
  { name: 'youtube', label: 'Youtube', dimensions: '1280:720' },
  {
    name: 'youtube_short',
    label: 'Youtube (short)',
    dimensions: "720:1280",
  },
  { name: 'tiktok', label: 'TikTok', dimensions: "720:1280" },
  { name: 'instagram', label: 'Instagram', dimensions: '1080:1080' },
  {
    name: 'instagram_story',
    label: 'Instagram (story)',
    dimensions: "720:1280",
  },
  {
    name: 'instagram_reel',
    label: 'Instagram (reel)',
    dimensions: "720:1280",
  },
  { name: 'linkedin', label: 'LinkedIn', dimensions: '1080:1080' },
  { name: 'twitter', label: 'X', dimensions: '1080:1080' },
  { name: 'facebook', label: 'Facebook', dimensions: '1080:1080' },
  {
    name: 'facebook_story',
    label: 'Facebook (story)',
    dimensions: "720:1280",
  },
  {
    name: 'facebook_video',
    label: 'Facebook (video)',
    dimensions: '1080:1080',
  },
  { name: 'snapchat', label: 'Snapchat', dimensions: "720:1280" }
]