import React from 'react';
import { AppRoute } from '../types/config';
import { CustomIcon } from '../atoms/CustomIcon';

export const routes: AppRoute[] = [
  {
    title: 'Questions',
    path: '/dashboard/questions',
    primary: true,
    exact: true,
    icon: <CustomIcon name="question" hex="white" />,
  },
  {
    title: 'Campaigns',
    path: '/dashboard/campaigns',
    primary: true,
    exact: true,
    icon: <CustomIcon name="folder" hex="white" />,
  },
  {
    title: 'Admins',
    path: '/dashboard/admins',
    primary: false,
    exact: true,
    icon: <CustomIcon name="shield" hex="white" />,
  },
  {
    title: 'Settings',
    path: '/dashboard/settings',
    primary: false,
    exact: true,
    icon: <CustomIcon name="gear" hex="white" />,
  },
  {
    title: 'Videos',
    path: '/dashboard/videos',
    primary: true,
    exact: true,
    icon: <CustomIcon name="play" hex="white" />,
  },
  // {
  //   title: 'Studio',
  //   path: '/dashboard/studio',
  //   primary: true,
  //   exact: true,
  //   icon: <CustomIcon name="studio" sx={{ color: 'white' }} />,
  // },
  {
    title: 'Collections',
    path: '/dashboard/collections',
    primary: true,
    exact: true,
    icon: <CustomIcon name="tiles" hex="white" />,
  },
  {
    title: 'Analytics',
    path: '/dashboard/analytics',
    primary: true,
    exact: true,
    icon: <CustomIcon name="chart" hex="white" />,
  },
];

export const adminRoutes: AppRoute[] = [
  {
    title: 'Master Admins',
    path: '/admin/users',
    primary: true,
    exact: true,
    icon: <CustomIcon name="shield" hex="white" />,
  },
  {
    title: 'Customers',
    path: '/admin/customers',
    primary: true,
    exact: true,
    icon: <CustomIcon name="person" hex="white" />,
  },
  {
    title: 'Editing Requests',
    path: '/admin/edit-requests',
    primary: true,
    exact: true,
    icon: <CustomIcon name="add" hex="white" />,
  },
];
