import {
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import {
  Testimonial,
  TestimonialIn,
  TranscriptionLine
} from "../../types";
import { Queries, sendRequest, ListResource } from "./api";

export const useTestimonialCreate = (
  pParams: { [name: string]: string }
) => {
  return useMutation({
    mutationKey: Queries.TestimonialCreate.key,
    mutationFn: (data: TestimonialIn) =>
      sendRequest<Testimonial>(Queries.TestimonialCreate.endpoint, { body: data, pathParams: pParams }),
  })
};

export const useTestimonialGet = (
  pParams: { [name: string]: any },
) => {
  return useQuery({
    queryKey: Queries.TestimonialGet.key.concat(Object.values(pParams)),
    queryFn: () =>
      sendRequest<Testimonial[]>(Queries.TestimonialGet.endpoint, { pathParams: pParams }),
    enabled: !!pParams.id
  })
};

export const useTestimonialList = (
  pParams: { [name: string]: any },
  qParams: { [name: string]: any }
) => {
  return useQuery({
    queryKey: Queries.TestimonialList.key.concat(Object.values(pParams)).concat(Object.values(qParams)),
    queryFn: () =>
      sendRequest<ListResource>(Queries.TestimonialList.endpoint, { pathParams: pParams, queryParams: qParams }),
    enabled: !!pParams.company
  })
};

export const useTranscriptionGet = (
  pParams: { [name: string]: any },
  qParams: { [name: string]: any }
) => {
  return useQuery({
    queryKey: Queries.TranscriptionGet.key.concat(Object.values(pParams)).concat(Object.values(qParams)),
    queryFn: () =>
      sendRequest<TranscriptionLine[]>(Queries.TranscriptionGet.endpoint, { pathParams: pParams, queryParams: qParams }),
  })
};

export const useTestimonialVersionsGet = (
  pParams: { [name: string]: any },
  qParams: { [name: string]: any }
) => {
  return useQuery({
    queryKey: Queries.VersionsGet.key.concat(Object.values(pParams)).concat(Object.values(qParams)),
    queryFn: () =>
      sendRequest<ListResource>(Queries.VersionsGet.endpoint, { pathParams: pParams, queryParams: qParams }),
    enabled: !!pParams.id
  })
};

export const useTranscriptionUpdate = (
  pParams: { [name: string]: any }
) => {
  return useMutation({
    mutationKey: Queries.TranscriptionUpdate.key,
    mutationFn: (data: TranscriptionLine[]) =>
      sendRequest<TranscriptionLine[]>(Queries.TranscriptionUpdate.endpoint, { body: data, pathParams: pParams }),
  })
};