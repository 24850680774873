import { SCardProps, PageNavItem } from "../../components";

export const campaignNavItems: PageNavItem[] = [
  { title: "Details", link: "details" },
  { title: "Questions", link: "questions" },
  { title: "Category Tags", link: "tags" },
  { title: "Video Responses", link: "reponses" },
];

export const campaignCardLayout: { [name: string]: SCardProps } = {
  Details: {
    title: "Details",
    colors: [],
    uploads: [],
    inputs: [
      {
        label: "Campaign Name",
        code: "title",
        type: "text",
        gridSize: 12,
      },
      {
        label: "Campaign Description",
        code: "description",
        type: "longtext",
        gridSize: 12,
      },
      {
        label: "Prompt",
        code: "prompt",
        type: "longtext",
        gridSize: 12,
        helpText: "Provide additional context or instructions to your creators about the campaign.",
      },
    ],
  },
  "Category Tags": {
    title: "Category Tags",
    colors: [],
    uploads: [],
    inputs: [],
  },
  Questions: {
    title: "Questions",
    colors: [],
    uploads: [],
    inputs: [],
  },
  "Video Responses": {
    title: "Video Responses",
    colors: [],
    uploads: [],
    inputs: [],
  },
};
