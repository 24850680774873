import React, { useState } from 'react';
import {
  useGlobalState,
  useCurrentCompany,
} from '../../utils/GlobalStateProvider';
import { Form, FieldConfig } from '../../atoms/Form';
import { CampaignIn } from '../../types/campaign';
import { CreateFormProps } from '../../types/config';
import { toast } from 'react-toastify';
import { useCampaignCreate } from '../../config/api/campaigns';

export const CampaignCreate = (props: CreateFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const context = useGlobalState();
  const company = useCurrentCompany();

  const campaignCreate = useCampaignCreate({
    company: `${company || context.state.company}`,
  });

  const fields: FieldConfig[] = [
    {
      name: 'title',
      label: 'Name',
      type: 'text',
      placeholder: 'e.g. Culture',
      validation: { required: true, errormessage: 'Name is required' },
      columnSize: 12,
    },
    // {
    //   name: 'tags',
    //   label: 'Category Tags',
    //   type: 'text',
    //   columnSize: 6,
    //   helpText:
    //     'Campaign category tags will be automatically applied to the videos when they are created.  This allows candidate to quickly filter for videos related to specific topics.',
    // },
    {
      name: 'description',
      label: 'Description',
      type: 'longtext',
      placeholder:
        'e.g. The purpose of this campaign is to educate candidates about the culture of our company',
      columnSize: 6,
      validation: { errormessage: 'Description is too long', max: 500 },
      helpText:
        'The description is helpful to understand the intent of the campaign. Our AI technology will use it to help generate recommendations for you.',
    },
    {
      name: 'prompt',
      label: 'Prompt',
      type: 'longtext',
      placeholder:
        'e.g. Talk about how your experiences that have made you feel a part of the company',
      columnSize: 6,
      validation: { errormessage: 'Prompt is too long', max: 255 },
      helpText:
        'Prompts guide your employees to create the kind of content you want.  The prompt will be displayed to them before they record their video.',
    },
    {
      name: 'is_template',
      label: 'Save as Template?',
      type: 'checkbox',
      checked: false,
      columnSize: 6,
      helpText:
        'Templates allow you to quickly recreate campaigns with similar questions.  For example, A Job Posting might need videos with the same questions.',
    },
  ];

  const handleSubmit = (data: any) => {
    data.customer_id = `${company || context.state.company}`;
    campaignCreate.mutate(data as CampaignIn, {
      onError: (error) => {
        toast.error(
          'Something went wrong! Your request could not be completed'
        );
        setLoading(false);
      },
      onSuccess: (data) => {
        toast('🦄 Campaign Created!');
        props.onComplete(data);
        setLoading(false);
      },
    });
  };

  return (
    <Form
      title="2. Add Details"
      submitLabel="Create"
      fields={fields}
      callback={handleSubmit}
      isLoading={loading}
      currentData={props.data}
      embedded
    />
  );
};
