export type StatusTab = {
  name: string;
  label: string;
};

export const TestimonialStatus: StatusTab[] = [
  { name: 'new', label: 'New' },
  { name: 'pending edit', label: 'Processing' },
  { name: 'approved', label: 'Active' },
  { name: 'rejected', label: 'Rejected' },
];

export const CustomerStatus: StatusTab[] = [
  { name: 'active', label: 'Active' },
  { name: 'inactive', label: 'Inactive' },
];
