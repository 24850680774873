import React from 'react';
import { Box, Typography } from '@mui/material';
import { useLottie } from 'lottie-react';
import animationData from '../lotties/loading.json';
import { CustomIcon } from '../atoms';

export interface LoadingProps {
  fullpage?: boolean;
}

const fullpageClass = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  opacity: '17%',
};

export const Loading = (props: LoadingProps): JSX.Element => {
  const normal = {
    opacity: '17%',
    height: 400,
    width: 400,
    margin: 'auto',
  };

  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
    height: 400,
    width: 400,
  };
  const { View } = useLottie({ animationData });
  return (
    <Box sx={props.fullpage ? fullpageClass : normal}>
      <CustomIcon name="jamyr" />
      {View}
      <Typography
        sx={{
          textAlign: 'center',
          position: 'relative',
          top: props.fullpage ? -500 : -210,
        }}
      >
        Loading
      </Typography>
    </Box>
  );
};
