import React, { useEffect, useState } from 'react';
import {
  JamyrCard,
  JamyrTable,
  TableAction,
  TableProps,
  Loading,
} from '../../atoms';
import {
  AnalyticsCard,
  AnalyticsCardProps,
  TableCard,
  VideoPlayer,
} from '../../components';
import useAPI from '../../utils/useApi';
import { APIRequests } from '../../config/api';
import { AnalyticsSummary } from '../../types/analytics';
import {
  useGlobalState,
  useCurrentCompany,
} from '../../utils/GlobalStateProvider';
import { RequestInput } from '../../types/config';
import { Testimonial } from '../../types/testimonial';
import { Grid, Typography } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { isMobile } from '../../utils/global';

export const Analytics = (): JSX.Element => {
  const [tableData, setTableData] = useState<AnalyticsSummary>();
  const context = useGlobalState(true);
  const company = useCurrentCompany();
  const { showModal } = useModal();
  const analytics = useAPI(APIRequests.AnalyticsSummary);

  useEffect(() => {
    const req: RequestInput = {
      pathParams: { company: company || context.state.company },
    };
    analytics.execute(req);
  }, [context.state.company]);

  const cardProps: AnalyticsCardProps[] = [
    {
      title: 'Impressions',
      subtitle: 'All-time',
      description: 'Number of times a video is displayed',
      value: tableData?.impressions,
    },
    {
      title: 'Interactions',
      subtitle: 'All-time',
      description: 'Number of times the video card was opened',
      value: tableData?.interactions,
    },
    {
      title: 'Plays',
      subtitle: 'All-time',
      description: 'Number of times a video was played',
      value: tableData?.plays,
    },
  ];

  useEffect(() => {
    if (analytics.data) {
      setTableData(analytics.data as AnalyticsSummary);
    }
  }, [analytics.data]);

  const handlePlayClick = (idx: number) => {
    if (tableData) {
      const testimonial = tableData.top_videos[idx] as unknown as Testimonial;
      const link = testimonial.final_video || testimonial.video;
      showModal(VideoPlayer, { link: link, testimonial: testimonial });
    }
  };
  const actions: TableAction[] = [
    {
      header: 'Video',
      element: 'player',
      onClick: handlePlayClick,
      order: -1,
    },
  ];

  const top5props: TableProps = {
    columns: [
      { name: 'question_text', label: 'Question' },
      { name: 'email', label: 'Employee' },
      { name: 'plays', label: 'Views' },
    ],
    rows: tableData?.top_videos,
    actions: actions,
    emptyMessage: <Typography>Not enough data to display</Typography>,
    pagination: true,
  };

  const topFilterProps: TableProps = {
    columns: [
      { name: '0', label: 'Filter' },
      { name: '1', label: 'Clicks' },
    ],
    rows: tableData?.top_filters,
    emptyMessage: <Typography>Not enough data to display</Typography>,
    pagination: true,
  };

  const topLandingProps: TableProps = {
    columns: [
      {
        name: 'question_text',
        label: 'Page',
      },
      {
        name: 'views',
        label: 'Views',
      },
      {
        name: 'cta_clicks',
        label: 'CTA Clicks',
      },
    ],
    rows: tableData?.top_shares,
    emptyMessage: <Typography>Not enough data to display</Typography>,
    pagination: true,
  };

  return (
    <>
      {tableData && (
        <Grid container>
          <Grid
            container
            item
            xs={12}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            sx={{
              pl: { xs: 2, sm: 10 },
              pr: { xs: 0, sm: 10 },
              mt: { xs: 0, sm: 10 },
              maxWidth: { xs: '85%', sm: '100%' },
            }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h3"
                sx={{
                  fontSize: 24,
                  fontWeight: 'bold',
                  mb: 2,
                  ml: 2,
                }}
              >
                Video Widget Analytics
              </Typography>
            </Grid>
            {cardProps.map((item: AnalyticsCardProps) => {
              return (
                <Grid item xs={12} sm={4} key={item.title}>
                  <JamyrCard
                    title={''}
                    children={<AnalyticsCard {...item} />}
                    embedded={true}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={12}>
            <TableCard title="Most Viewed Videos">
              <JamyrTable {...top5props} />
            </TableCard>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={{ pl: 5, pr: 5 }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={4}>
              <TableCard
                title="Top Filters"
                containerPadding={isMobile() ? 0 : 5}
              >
                <JamyrTable {...topFilterProps} />
              </TableCard>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TableCard title="Top Landing Pages" containerPadding={5}>
                <JamyrTable {...topLandingProps} />
              </TableCard>
            </Grid>
          </Grid>
        </Grid>
      )}

      {!tableData && <Loading fullpage={true} />}
    </>
  );
};
