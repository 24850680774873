import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TableCard } from '../../components/TableCard';
import { ColumnSort, JamyrTable, TableProps, TableAction } from '../../atoms';
import useAPI from '../../utils/useApi';
import { APIRequests, ListResource } from '../../config/api';
import { DataType, RequestInput } from '../../types/config';
import { Campaign } from '../../types/campaign';
import { DeleteConfirm } from '../../components/Forms';
import {
  useGlobalState,
  useCurrentCompany,
} from '../../utils/GlobalStateProvider';
import { PopupModal } from '../../atoms';
import { useModal } from 'mui-modal-provider';
import { toast } from 'react-toastify';

export const Campaigns = (): JSX.Element => {
  const context = useGlobalState(true);
  const company = useCurrentCompany();
  const [sortValue, setSortValue] = useState<ColumnSort>();
  const [tableData, setTableData] = useState<{ [name: string]: string }[]>();
  const campaigns = useAPI<ListResource>(APIRequests.CampaignsList);
  const campaignDelete = useAPI(APIRequests.CampaignDelete);
  const [fetchData, setFetchData] = useState<boolean>(false);
  let { showModal } = useModal();
  let navigate = useNavigate();

  useEffect(() => {
    let sortParams;
    if (sortValue) {
      sortParams = {
        sort: sortValue.field,
        sortDir: sortValue.direction,
      };
    }
    const req: RequestInput = {
      pathParams: { company: company || context.state.company },
      queryParams: sortParams || {},
    };
    campaigns.execute(req);
  }, [fetchData, context.state.company, sortValue]);

  useEffect(() => {
    if (campaigns.data) {
      setTableData(
        (campaigns.data.records as { [name: string]: string }[]) || []
      );
    }
  }, [campaigns.data]);

  const handleEditClick = (idx: number) => {
    if (tableData) {
      const campaign: Campaign = tableData[idx] as unknown as Campaign;
      navigate(`/dashboard/campaigns/${campaign.id}`);
    }
  };

  const handleDelete = (campaign: Campaign, onComplete: () => void) => {
    const req: RequestInput = {
      pathParams: {
        id: campaign.id,
        company: company || context.state.company,
      },
    };
    campaignDelete.execute(req);
    onComplete();
    setFetchData(true);
  };

  const handleDeleteClick = (idx: number) => {
    if (tableData) {
      const collection: Campaign = tableData[idx] as unknown as Campaign;
      const modal = showModal(PopupModal, {
        children: (
          <DeleteConfirm
            title="Delete Collection"
            warning={`Are you sure you want to delete ${collection.title}?`}
            callback={() =>
              handleDelete(collection, () => {
                modal.destroy();
                toast('🦄 Campaign Deleted!');
              })
            }
          />
        ),
        size: 'small',
      });
    }
  };

  const actions: TableAction[] = [
    {
      header: 'Edit',
      icon: 'edit',
      onClick: handleEditClick,
    },
    {
      header: 'Delete',
      icon: 'delete',
      onClick: handleDeleteClick,
    },
  ];

  const tableProps: TableProps = {
    columns: [
      {
        name: 'title',
        label: 'Title',
        sorted:
          sortValue && sortValue.field === 'title'
            ? sortValue.direction
            : undefined,
      },
      {
        name: 'created_at',
        label: 'Created',
        type: DataType.Date,
        sorted:
          sortValue && sortValue.field === 'created_at'
            ? sortValue.direction
            : undefined,
      },
    ],
    rows: tableData,
    actions: actions,
    sortData: (data: ColumnSort) => setSortValue(data),
    emptyMessage: <Typography> No campaigns </Typography>,
  };
  return (
    <>
      <TableCard
        title="Campaigns"
        actionClick={() => navigate('/dashboard/campaigns/create')}
        actionLabel="Campaign"
        actionIcon="add"
      >
        <JamyrTable {...tableProps} />
      </TableCard>
    </>
  );
};
