import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Box, Button, InputLabel, Typography } from '@mui/material';
import { JamyrSelectField, JamyrSwitch, JamyrTextField } from '../atoms';
import PlayIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import RestoreIcon from '@mui/icons-material/Restore';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import WaveSurfer from 'wavesurfer.js';
import Regions from 'wavesurfer.js/dist/plugins/regions.js';
import Timeline from 'wavesurfer.js/dist/plugins/timeline.js';
import Hover from 'wavesurfer.js/dist/plugins/hover.js';
import { S3_BUCKET } from '../config';
import { Testimonial, VideoEditRequest, VideoSnip } from '../types/testimonial';
import { APIRequests } from '../config/api';
import { RequestInput } from '../types/config';
import useAPI from '../utils/useApi';
import { toast } from 'react-toastify';
import { useCustomerSettingGet } from '../config/api/customer';
import { videoExportOptions } from '../config';
import { useGlobalState } from '../utils/GlobalStateProvider';

export interface VideoEditorProps {
  testimonial: Testimonial;
  onComplete: () => void;
}

export const VideoEditor = (props: VideoEditorProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const videoEditCreate = useAPI(APIRequests.VideoEdit);
  const [addBranding, setAddBranding] = useState<boolean>(false);
  const [playing, setPlaying] = useState<boolean>(false);
  const [addTitle, setAddTitle] = useState<boolean>(false);
  const [newVersion, setNewVersion] = useState<boolean>(false);
  const [displayType, setDisplayType] = useState<string>(
    props.testimonial.format || ''
  );
  const [label, setLabel] = useState<string>(props.testimonial.label || '');
  const [snips, setSnips] = useState<VideoSnip[]>([]);
  const [audioWave, setAudioWave] = useState<any>();
  const settings = useCustomerSettingGet({ id: props.testimonial.customer_id });
  const [zoom, setZoom] = useState<number>(1);
  const context = useGlobalState();

  const handlePauseVideo = () => {
    if (videoRef?.current && typeof videoRef.current === 'object') {
      setPlaying(false);
      const t = videoRef.current;
      t.pause();
    }
    if (audioWave) {
      audioWave.wave.pause();
    }
  };

  useEffect(() => {
    if (audioWave) {
      audioWave.wave.destroy();
    }
    if (videoRef.current) {
      var wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#6262EF',
        progressColor: '#6262EF',
        cursorColor: 'red',
        minPxPerSec: zoom,
        barHeight: 10,
        cursorWidth: 3,
        height: 75,
        hideScrollbar: false,
        media: videoRef.current,
        plugins: [],
      });
      const wsRegions = wavesurfer.registerPlugin(Regions.create());
      wavesurfer.registerPlugin(
        Timeline.create({
          timeInterval: 0.1,
          primaryLabelInterval: 1,
          style: {
            fontSize: '10px',
            color: '#6A3274',
            marginTop: '1px',
          },
        })
      );
      wavesurfer.registerPlugin(
        Hover.create({
          labelSize: 14,
          labelColor: 'white',
          labelBackground: 'rgba(0,0,0,0.7)',
        })
      );
      setAudioWave({ wave: wavesurfer, regions: wsRegions });
    }
  }, [videoRef.current]);

  const handlePlayVideo = () => {
    if (videoRef?.current && typeof videoRef.current === 'object') {
      const t = videoRef.current;

      if (audioWave) {
        setPlaying(true);
        t.currentTime = audioWave.wave.getCurrentTime();
        t.play();
        audioWave.wave.play();
      } else {
        // @ts-ignore
        t.play();
      }
    }
  };

  const setSlice = () => {
    if (audioWave) {
      const start = audioWave.wave.getCurrentTime();
      const end = start + 1;
      audioWave.regions.addRegion({
        start: start,
        end: end,
        color: 'rgba(20, 232, 94, 0.5)',
      });
      const newSnip: VideoSnip = { start: start, stop: end };
      setSnips([...snips, newSnip]);
    }
  };

  useEffect(() => {
    if (videoEditCreate.data) {
      toast('🦄 Video Edit Submitted!');
      props.onComplete();
    }
    if (videoEditCreate.error) {
      toast.error(String(videoEditCreate.error));
    }
  }, [videoEditCreate.data, videoEditCreate.error]);

  const formatVideo = () => {
    let finalSnips: VideoSnip[] = [];
    if (audioWave) {
      Object.values(audioWave.regions.regions).forEach((item: any) => {
        // these are the start & stop params for the snips
        finalSnips.push({ start: item.start, stop: item.end });
      });
      finalSnips.sort((a, b) =>
        a.start > b.start ? 1 : a.start < b.start ? -1 : 0
      );
      const editReq: VideoEditRequest = {
        external_id: props.testimonial.external_id,
        video: props.testimonial.final_video || props.testimonial.video,
        transcribe: [],
        transitions: false,
        trim: finalSnips,
        branding_options: {
          logo: addBranding ? settings.data?.default_company_logo : '',
          text: addTitle
            ? {
                line1:
                  props.testimonial.first_name +
                  ' ' +
                  props.testimonial.last_name,
                line2: props.testimonial.title,
              }
            : {},
        },
        saveAsNewVersion: newVersion,
        label: label,
        format: displayType,
      };
      const req: RequestInput = {
        pathParams: {
          company: props.testimonial.customer_id,
          external_id: props.testimonial.external_id,
        },
        body: editReq,
      };
      videoEditCreate.execute(req);
    }
  };

  const clearSnips = () => {
    if (audioWave) {
      audioWave.regions.clearRegions();
    }
    setSnips([]);
  };

  useEffect(() => {
    if (audioWave?.wave) {
      audioWave.wave.zoom(zoom);
    }
  }, [zoom]);

  const handleZoom = (event: React.WheelEvent) => {
    if (event.deltaY < 0 && zoom > 10) {
      setZoom(zoom - 100);
    } else if (event.deltaY > 0 && zoom < 5000) {
      setZoom(zoom + 100);
    }
  };

  const renderVideo = useMemo(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <video
            ref={videoRef}
            height="410px"
            src={props.testimonial.final_video || props.testimonial.video}
            onEnded={handlePauseVideo} // video reached end of content
            onLoadedData={() => setLoaded(true)}
            crossOrigin="anonymous"
          >
            <track
              src={`https://${S3_BUCKET}/transcriptions/${props.testimonial.external_id}/en/${props.testimonial.external_id}.vtt`}
              label="English"
              srcLang="en"
              default
            />
          </video>
          {addBranding && (
            <Box
              sx={{
                position: 'absolute',
                top: 10,
                left: 10,
              }}
            >
              <img
                src={settings.data?.default_company_logo}
                alt="logo"
                crossOrigin="anonymous"
                width="100px"
              />
            </Box>
          )}
          {addTitle && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 75,
                left: 0,
                color: 'white',
                backgroundColor: '#000',
                opacity: 0.6,
                width: '100%',
              }}
            >
              <Typography sx={{ pl: 1 }}>
                {props.testimonial.first_name} {props.testimonial.last_name}
              </Typography>
              <Typography sx={{ pl: 1 }}>{props.testimonial.title}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  }, [videoRef, addBranding, addTitle]);

  return (
    <div style={{ width: '90%' }}>
      <>
        <>
          {renderVideo}
          <Box display="flex" justifyContent="center" sx={{ py: 1 }}>
            <Button onClick={playing ? handlePauseVideo : handlePlayVideo}>
              {playing ? 'Pause' : 'Play'}
              {playing && <PauseIcon sx={{ cursor: 'pointer' }} />}
              {!playing && <PlayIcon sx={{ cursor: 'pointer' }} />}
            </Button>
          </Box>
        </>

        <div ref={audioRef} id="waveform" onWheel={handleZoom} />
        {snips.length > 0 && (
          <Typography sx={{ fontSize: 12, py: 1, textAlign: 'center' }}>
            {`Highlight the sections you want to keep by clicking the ‘Snip’
            button. You can highlight more than one section by clicking the
            ‘Snip’ button again. When you have highlighted all of the sections
            you want, click Create.`}
          </Typography>
        )}
        <Box display="flex" justifyContent="center" sx={{ py: 1 }}>
          <Button onClick={setSlice}>
            Snip <ContentCutIcon sx={{ cursor: 'pointer', ml: 1 }} />
          </Button>
          <Button
            onClick={formatVideo}
            sx={{
              ml: 1,
              backgroundColor:
                snips.length === 0 &&
                !addTitle &&
                !addBranding &&
                (!displayType || displayType === props.testimonial.format)
                  ? 'white'
                  : '#34344A',
            }}
            disabled={
              snips.length === 0 &&
              !addTitle &&
              !addBranding &&
              (!displayType || displayType === props.testimonial.format)
            }
          >
            Create <CloudUploadIcon sx={{ cursor: 'pointer', ml: 1 }} />
          </Button>
          <Button
            onClick={clearSnips}
            sx={{
              ml: 1,
              backgroundColor: snips.length === 0 ? 'white' : '#34344A',
            }}
            disabled={snips.length === 0}
          >
            Clear <RestoreIcon sx={{ cursor: 'pointer', ml: 1 }} />
          </Button>
        </Box>
        <Box sx={{ mt: 3, mx: 10, display: 'flex', textAlign: 'center' }}>
          <Box sx={{ textAlign: 'left', display: 'block', width: '33%' }}>
            <InputLabel htmlFor="dimensions">
              <Typography
                sx={{
                  fontSize: 16,
                  textAlign: 'left',
                }}
              >
                Select format
              </Typography>
            </InputLabel>
            <JamyrSelectField
              name="dimensions"
              label={undefined}
              value={
                videoExportOptions.filter(
                  (item) => item.name === displayType
                )[0] || { name: '', label: 'Original' }
              }
              options={[
                ...videoExportOptions.map((obj) => {
                  return {
                    name: obj.name,
                    label: `${obj.label} ${obj.dimensions}`,
                  };
                }),
              ]}
              onChange={(val) => setDisplayType(val.name)}
              sx={{ '& .MuiInputBase-root': { height: '45px' } }}
            />
          </Box>
          <Box sx={{ display: 'inline-block', width: '33%' }}>
            <Box>
              <JamyrSwitch
                label="Display Logo"
                onChange={() => setAddBranding(!addBranding)}
                defaultChecked={addBranding}
              />
            </Box>
            <Box>
              <JamyrSwitch
                label="Display Title"
                onChange={() => setAddTitle(!addTitle)}
                defaultChecked={addTitle}
              />
            </Box>
            <Box>
              <JamyrSwitch
                label="Save as New Version?"
                onChange={() => setNewVersion(!newVersion)}
                defaultChecked={newVersion}
              />
            </Box>
          </Box>
          <Box sx={{ width: '33%' }}>
            <JamyrTextField
              label="Label"
              name="label"
              changehandler={(f, v) => setLabel(v)}
              disableDebounce={true}
              currentvalue={label}
              sx={{ width: '100%' }}
            />
          </Box>
        </Box>
      </>
    </div>
  );
};
