import React, { useState } from 'react';
import { Form, FieldConfig } from '../../atoms/Form';
import { Customer } from '../../types/user';
import { RequestInput, CreateFormProps } from '../../types/config';
import { toast } from 'react-toastify';

export const EasyApplyContact = (props: CreateFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);

  const fields: FieldConfig[] = [
    {
      name: 'firstname',
      label: 'First Name',
      type: 'text',
      placeholder: 'eg. John',
      // validation: { required: true, errormessage: 'First Name is required' },
      columnSize: 6,
    },
    {
      name: 'lastname',
      label: 'Last Name',
      type: 'text',
      placeholder: 'eg. Smith',
      // validation: { required: true, errormessage: 'Last Name is required' },
      columnSize: 6,
    },
    {
      name: 'phone',
      label: 'Phone',
      type: 'text',
      placeholder: 'eg. 555-555-5555',
      columnSize: 12,
    },
  ];

  const handleSubmit = (data: any) => {
    setLoading(true);
    props.onComplete();
  };

  return (
    <Form
      title="Increase your chances of hearing back from a recruiter"
      submitLabel="Submit"
      fields={fields}
      callback={handleSubmit}
      isLoading={loading}
    />
  );
};
