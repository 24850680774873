import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListProps,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from '@mui/material';
import { CustomIcon } from './CustomIcon';
import { JamyrTextField } from './JamyrTextField';
import { Loading } from './Loading';

export type JListProps = ListProps & {
  title: string;
  data: { [name: string]: string }[] | any;
  onItemSelect?: (selection: string[] | number[]) => void;
  onItemRemove?: (selection: string | number) => void;
  readOnly?: boolean;
  width?: string;
  height?: string;
  searchFilter?: boolean;
};

const perPageLimit = 5; // Limit to 10 items per page

export const JamyrList = (props: JListProps): JSX.Element => {
  const [checked, setChecked] = useState<string[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [filter, setFilter] = useState<string>();
  const [filteredList, setFilteredList] = useState<
    { [name: string]: string }[]
  >(props.data || []);

  const pageCount = Math.ceil(
    props.data ? props.data.length / perPageLimit : 0
  );

  useEffect(() => {
    setFilteredList(props.data);
  }, [props.data]);

  useEffect(() => {
    if (filter) {
      const d = props.data.filter((item: { [name: string]: string }) =>
        item.text.toLowerCase().includes(filter.toLowerCase())
      );
      setFilteredList(d);
    } else {
      setFilteredList(props.data);
    }
  }, [filter]);

  const handleToggle = (value: string) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    if (props.onItemSelect) {
      props.onItemSelect(newChecked);
    }
  };

  const handleRemove = (value: string) => {
    // I dont think this is needed since handleToggle
    // triggers onItemSelect
    if (props.onItemRemove) {
      props.onItemRemove(value);
    }
  };

  return (
    <>
      <List
        sx={{
          width: props.width || '100%',
          bgcolor: 'background.paper',
          mt: 1,
        }}
      >
        {props.searchFilter && (
          <JamyrTextField
            name="search"
            placeholder="Search"
            sx={{ width: '100%', pl: 1 }}
            disableDebounce
            changehandler={(field: string, value: string) => setFilter(value)}
          />
        )}
        {filteredList.length === 0 && <Loading />}
        {filteredList
          .slice(offset * perPageLimit, offset * perPageLimit + perPageLimit)
          .map((item: { [name: string]: string }, idx: number) => {
            const labelId = `checkbox-list-label-${item.text}`;
            return (
              <ListItem
                key={`${item.text}-${idx}`}
                disablePadding
                divider
                secondaryAction={
                  props.onItemRemove && (
                    <IconButton aria-label="delete">
                      <CustomIcon
                        name="delete"
                        onClick={() => handleRemove(item.id)}
                      />
                    </IconButton>
                  )
                }
              >
                <ListItemButton onClick={() => handleToggle(item.id)} dense>
                  {!props.readOnly && (
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(item.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                  )}
                  <ListItemText id={labelId} primary={item.text} />
                </ListItemButton>
              </ListItem>
            );
          })}
      </List>
      <Box sx={{ display: 'flex' }}>
        {Array.from(Array(pageCount).keys()).map((page: number) => {
          return (
            <p
              onClick={() => setOffset(page)}
              style={{
                textDecoration: offset === page ? 'underline' : 'inherit',
                marginLeft: '5px',
                cursor: 'pointer',
              }}
              key={`paginate-${page}`}
            >
              {page + 1}
            </p>
          );
        })}
      </Box>
    </>
  );
};
