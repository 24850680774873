import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { RequestInput } from '../types/config';
import { EditNote } from '../types/testimonial';
import useAPI from '../utils/useApi';
import { APIRequests, ListResource } from '../config/api';
import {
  useGlobalState,
  useCurrentCompany,
} from '../utils/GlobalStateProvider';
import { format } from 'date-fns';

export type VideoHistoryProps = {
  testimonial_id: number;
};

export const VideoHistory = (props: VideoHistoryProps): JSX.Element => {
  const editnotes = useAPI<ListResource>(APIRequests.TestimonialGetHistory);
  const [records, setRecords] = useState<EditNote[]>([]);
  const context = useGlobalState(true);
  const company = useCurrentCompany();

  useEffect(() => {
    if (context.state.company && props.testimonial_id) {
      const req: RequestInput = {
        pathParams: {
          company: company || context.state.company,
          id: props.testimonial_id,
        },
      };
      editnotes.execute(req);
    }
  }, [context.state.company]);

  useEffect(() => {
    if (editnotes.data) {
      setRecords(editnotes.data.records);
    }
  }, [editnotes.data]);

  return (
    <List>
      {records.length === 0 && (
        <Typography> No video editing notes recorded</Typography>
      )}
      {records.length > 0 &&
        records.map((record: EditNote) => {
          return (
            <ListItem key={record.id} divider>
              <ListItemText
                primary={record.created_by}
                secondary={
                  <>
                    <Typography>
                      {format(Date.parse(record.created_at), 'MM/dd/yyyy')}
                    </Typography>
                    <Typography>{record.recommendation}</Typography>
                    <Typography>{record.note}</Typography>
                  </>
                }
              />
            </ListItem>
          );
        })}
    </List>
  );
};
