import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { getCookie, eraseCookie } from '../../utils/global';
import { JamyrCookies } from '../../types/config';
import { Employee } from '../../types/user';
import { EmployeeCreate } from '../Forms';

export interface UserInfoProps {
  customer_id: number;
  oncomplete: () => void;
}

export const UserInfo = (props: UserInfoProps) => {
  const [employee, setEmployee] = useState<Employee>();
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const data = getCookie(JamyrCookies.EMPLOYEE);
    const delimiter = '*&';
    if (!data || !data.includes(delimiter)) {
      setShowForm(true);
    } else if (!employee) {
      const userinfo = data.split(delimiter);
      let emp: Employee = {};
      if (userinfo.length === 5) {
        // Pronouns are not required, so handling for that
        emp = {
          first_name: userinfo[0],
          last_name: userinfo[1],
          email: userinfo[2],
          title: userinfo[3],
        };
      } else if (userinfo.length === 6) {
        emp = {
          first_name: userinfo[0],
          last_name: userinfo[1],
          pronouns: userinfo[2],
          email: userinfo[3],
          title: userinfo[4],
        };
      }
      setEmployee(emp);
    }
  }, [employee]);

  const resetEmployee = () => {
    eraseCookie(JamyrCookies.EMPLOYEE);
    setEmployee(undefined);
  };

  const employeeConfirm = () => {
    return (
      <Box>
        <Typography variant="h1" sx={{ fontSize: 24, pb: 4, pt: 10 }}>
          Welcome back! Is this you?
        </Typography>
        <Typography sx={{ pb: 2 }}>
          {employee?.first_name} {employee?.last_name}
        </Typography>
        <Typography sx={{ pb: 2 }}>{employee?.email}</Typography>
        <Box display="flex">
          <Button
            onClick={() => props.oncomplete()}
            sx={{
              backgroundColor: '#7E7CF2',
              width: 150,
              maxHeight: 100,
              fontWeight: 'bold',
              fontSize: 14,
              mr: 2,
            }}
          >
            Yes
          </Button>
          <Button onClick={() => resetEmployee()}>Nope</Button>
        </Box>
      </Box>
    );
  };
  return (
    <>
      {showForm && (
        <EmployeeCreate
          onComplete={() => props.oncomplete()}
          data={{ customer_id: props.customer_id }}
        />
      )}
      {employee && !showForm && employeeConfirm()}
    </>
  );
};
