import React, { useState, useEffect } from 'react';
import { Loading } from '../atoms';
import { useParams, useSearchParams } from 'react-router-dom';
import useAPI from '../utils/useApi';
import { APIRequests, PublicGet } from '../config/api';
import { RequestInput } from '../types/config';
import { Campaign } from '../types/campaign';
import { CustomerSetting } from '../types/user';
import { Button, Grid, Typography } from '@mui/material';
import { QuestionLink } from '../types/testimonial';
import {
  Welcome,
  UserInfo,
  QuestionUpload,
  Complete,
} from '../components/Responses';

export interface ResponseParams {
  campaign: Campaign;
  questions: QuestionLink[];
  settings: CustomerSetting;
}

export const Responses = (): JSX.Element => {
  const { campaignId } = useParams();
  const campaignGet = useAPI<PublicGet>(APIRequests.CampaignResponses);
  const [campaign, setCampaign] = useState<ResponseParams>();
  const [hasError, setHasError] = useState<Boolean>(false);
  const [params] = useSearchParams();
  const [curIdx, setCurIdx] = useState<number>(0);
  useEffect(() => {
    if (campaignId) {
      const req: RequestInput = {
        pathParams: { external_id: campaignId },
      };
      if (params.get('qs')) {
        req['queryParams'] = { qs: params.get('qs')! };
      }
      campaignGet.execute(req);
    }
  }, [campaignId]);

  useEffect(() => {
    if (campaignGet.data) {
      const t = campaignGet.data;
      setCampaign({
        campaign: t.data.record,
        questions: t.data.questions,
        settings: t.settings,
      });
    }
    if (campaignGet.error) {
      setHasError(true);
    }
  }, [campaignGet.data, campaignGet.error]);

  const screens = [
    { component: <Welcome {...campaign!} />, btnText: 'Get Started' },
    {
      component: (
        <UserInfo
          customer_id={campaign?.campaign.customer_id!}
          oncomplete={() => setCurIdx(curIdx + 1)}
        />
      ),
      btnText: '',
    },
    {
      component: (
        <QuestionUpload
          questions={campaign?.questions!}
          customer_id={campaign?.campaign.customer_id!}
          oncomplete={() => setCurIdx(curIdx + 1)}
        />
      ),
      btnText: '',
    },
    { component: <Complete />, btnText: '' },
  ];

  return (
    <>
      {campaign ? (
        <>
          <Grid
            container
            px={20}
            pt={5}
            justifyContent="center"
            sx={{
              height: '100vh',
              px: { xs: 5, md: 20, lg: 40, xl: 65 },
            }}
          >
            <Grid
              container
              justifyContent="center"
              sx={{ textAlign: 'center' }}
            >
              {screens[curIdx].component}
              {screens[curIdx].btnText && (
                <Grid item xs={12}>
                  <Button
                    onClick={() => setCurIdx(curIdx + 1)}
                    sx={{
                      backgroundColor: '#7E7CF2',
                      width: 150,
                      maxHeight: 100,
                      fontWeight: 'bold',
                      fontSize: 14,
                      margin: 'auto',
                    }}
                  >
                    {screens[curIdx].btnText}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      ) : hasError ? (
        <Typography> Something went very wrong </Typography>
      ) : (
        <Loading />
      )}
    </>
  );
};
