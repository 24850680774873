import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

export interface CtmBtnProps {
  onclick: () => void;
  color?: string;
}
export const CloseButton = (props: CtmBtnProps) => {
  return (
    <div
      style={{
        width: 20,
        height: 19,
        borderRadius: 50,
        backgroundColor: props.color || 'white',
        opacity: '50%',
        position: 'relative',
        top: 25,
        right: 6,
        textAlign: 'center',
        cursor: 'pointer',
        marginRight: 2,
        marginLeft: 'auto',
      }}
      onClick={() => props.onclick()}
    >
      <CloseIcon sx={{ fontSize: 16, position: 'relative', top: 1 }} />
    </div>
  );
};
