import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { CustomIcon, JamyrSelectField } from '../atoms';
import ReactPlayer from 'react-player';
import { Testimonial } from '../types/testimonial';
import { TestimonialStatus, StatusTab } from '../config/layouts/selectLists';
import useAPI from '../utils/useApi';
import { APIRequests } from '../config/api';
import { RequestInput } from '../types/config';
import { toast } from 'react-toastify';

export interface VideoPlayerProps {
  onClick?: () => void;
  link?: string;
  testimonial?: Testimonial;
}
export const VideoPlayer = (props: VideoPlayerProps): JSX.Element => {
  const [modalOpen, setModalOpen] = useState<boolean>(true);

  const handleModalClose = () => setModalOpen(false);

  const handleCustomize = () => {
    window.location.href = `/dashboard/videos/${props.testimonial?.external_id}`;
  };
  const testimonialUpdate = useAPI(APIRequests.TestimonialUpdate);
  useEffect(() => {
    if (testimonialUpdate.data) {
      toast('🦄 Video Updated!');
    }
    if (testimonialUpdate.error) {
      toast.error(String(testimonialUpdate.error));
    }
  }, [testimonialUpdate.data, testimonialUpdate.error]);

  const updateTestimonialField = (status: StatusTab) => {
    if (props.testimonial) {
      let t = props.testimonial;
      t.status = status.name;
      const req: RequestInput = {
        pathParams: {
          company: props.testimonial.customer_id,
          id: props.testimonial.id,
        },
        body: t,
      };
      testimonialUpdate.execute(req);
    }
  };

  const getStatusOption = (): { [name: string]: string } => {
    const selectedOptions = TestimonialStatus.filter(
      (option) =>
        props.testimonial?.status && option.name === props.testimonial.status
    );
    return selectedOptions[0];
  };

  const getTracks = () => {
    let tracks = [
      {
        kind: 'captions',
        label: '',
        src: `https://s3.amazonaws.com/widget.jamyr.com/transcriptions/${props.testimonial?.external_id}.vtt`,
        srcLang: 'en',
        default: true,
      },
    ];
    return tracks;
  };

  return (
    <>
      {props.link && (
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' } }}
        >
          <Grid container justifyContent="center">
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-end"
              zIndex={0}
              sx={{ paddingRight: 10, paddingTop: 5 }}
            >
              <CustomIcon
                name="close"
                onClick={handleModalClose}
                fill="#FFFFFF"
              />
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Box
                justifyContent="center"
                alignItems="center"
                sx={
                  {
                    // position: "absolute" as "absolute",
                    // top: "50%",
                    // left: "50%",
                    // transform: "translate(-50%, -50%)"
                  }
                }
              >
                {props.testimonial && (
                  <>
                    <Grid container item xs={12} justifyContent="center">
                      <Typography
                        component="h1"
                        sx={{ color: 'white', fontSize: 32 }}
                      >
                        {props.testimonial.question_text}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="center"
                      sx={{ paddingTop: 3 }}
                    >
                      <Button
                        onClick={handleCustomize}
                        sx={{
                          backgroundColor: 'white',
                          color: 'black',
                          minWidth: 200,
                        }}
                      >
                        Customize
                      </Button>
                    </Grid>
                  </>
                )}
                <Grid container padding={2} justifyContent="center"></Grid>
                <Grid container padding={2} justifyContent="center">
                  {props.testimonial?.preview && (
                    <Grid item xs={12} sm={3} sx={{ mx: 2, maxHeight: 300 }}>
                      <img
                        src={props.testimonial?.preview}
                        crossOrigin="anonymous"
                        style={{
                          objectFit: 'contain',
                          width: '100%',
                          height: '100%',
                        }}
                        alt="preview"
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    xs={12}
                    sm={
                      props.testimonial?.preview && props.testimonial?.thumbnail
                        ? 3
                        : 12
                    }
                    sx={{ mx: 2 }}
                    justifyContent="center"
                  >
                    <ReactPlayer
                      url={props.link}
                      controls={true}
                      height="300px"
                      width="400px"
                      maxHeight="300px"
                      maxWidth="400px"
                      config={{
                        file: {
                          attributes: {
                            crossOrigin: 'true',
                          },
                          tracks: getTracks(),
                        },
                      }}
                    />
                  </Grid>
                  {props.testimonial?.thumbnail && (
                    <Grid item xs={12} sm={3} sx={{ mx: 2, maxHeight: 300 }}>
                      <img
                        src={props.testimonial?.thumbnail}
                        crossOrigin="anonymous"
                        style={{
                          objectFit: 'contain',
                          width: '100%',
                          height: '100%',
                        }}
                        alt="preview"
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container item xs={12} justifyContent="center">
                  <JamyrSelectField
                    name="status"
                    options={TestimonialStatus}
                    onChange={(res: any) => updateTestimonialField(res)}
                    value={getStatusOption()}
                    sx={{ minWidth: '50%', backgroundColor: 'white' }}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Modal>
      )}
      {!props.link && (
        <Box
          justifyContent="center"
          alignItems="center"
          onClick={props.onClick}
          sx={{
            cursor: 'pointer',
          }}
        >
          <CustomIcon name="play" fontSize="large" />
        </Box>
      )}
    </>
  );
};
