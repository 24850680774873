import React, { useState, useEffect } from 'react';
import { Box, Divider, List, ListItem, ListItemText } from '@mui/material';
import {
  JamyrCard,
  JamyrSwitch,
  JamyrMultiSelect,
  SelectOption,
} from '../atoms';
import { Testimonial, LandingPageFormat } from '../types/testimonial';
import { Department } from '../types/greenhouse';
import { CustomerSetting } from '../types/user';
import {
  useGlobalState,
  useCurrentCompany,
} from '../utils/GlobalStateProvider';
import useAPI from '../utils/useApi';
import { RequestInput } from '../types/config';
import { APIRequests } from '../config/api';

interface FormatOption {
  id: string;
  title: string;
  isATS: boolean;
  enabled?: boolean;
  description?: string;
}

export interface PageSelectProps {
  testimonial: Testimonial;
  changehandler?: (field: string, value: string) => void;
}

export const PageFormatSelect = (props: PageSelectProps): JSX.Element => {
  const ats = useAPI(APIRequests.GreenHouseDeptsList, true);
  const customer = useAPI<CustomerSetting>(APIRequests.CustomerBrandingGet);
  const [departments, setDepartments] = useState<SelectOption[]>([]);
  const [selectedDepts, setSelectedDepts] = useState<SelectOption[]>([]);
  const context = useGlobalState(true);
  const company = useCurrentCompany();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>(
    props.testimonial.page_style || LandingPageFormat.JamOne
  );

  useEffect(() => {
    if (company || context.state.company) {
      const req: RequestInput = {
        pathParams: {
          customer: company || context.state.company,
        },
      };
      customer.execute(req);
    }
  }, [context.state.company]);

  useEffect(() => {
    if (customer.data) {
      const cust = customer.data;
      if (cust.greenhouse_jobboard_token) {
        const req: RequestInput = {
          pathParams: {
            boardToken: cust.greenhouse_jobboard_token,
          },
        };
        ats.execute(req);
      }
    }
  }, [customer.data]);

  useEffect(() => {
    if (ats.data) {
      const d = ats.data as any;
      const depts = d.departments as Department[];
      if (depts.length > 0) {
        const res: SelectOption[] = [];
        depts.forEach((item: Department) => {
          res.push({ id: item.id, value: item.name });
        });
        const filters = props.testimonial.department_filter?.split(';');
        const sel: SelectOption[] = [];
        if (filters) {
          depts.forEach((dept) => {
            if (filters.includes(String(dept.id))) {
              sel.push({ id: dept.id, value: dept.name });
            }
          });
        }
        setSelectedDepts(sel);
        setDepartments(res);
      }
      setLoaded(true);
    }
  }, [ats.data]);

  const options: FormatOption[] = [
    { title: 'Portrait', isATS: false, id: LandingPageFormat.JamOne },
    // { title: "Portrait (ATS)", isATS: true, id: LandingPageFormat.JamATS },
    {
      title: 'Landscape',
      isATS: false,
      id: LandingPageFormat.JamLand,
    },
    // {
    //   title: 'Portrait with Transcription',
    //   isATS: false,
    //   id: LandingPageFormat.JamTrans,
    // },
  ];

  const onPageSelect = (id: string) => {
    setSelected(id);
    if (props.changehandler) {
      props.changehandler('page_style', id);
    }
  };

  const onDepartmentSelect = (data: SelectOption[]) => {
    let vals: string[] = [];
    data.forEach((option) => {
      vals.push(option.id);
    });
    const val: string = vals.join(';');
    if (props.changehandler) {
      props.changehandler('department_filter', val);
    }
  };

  return (
    <JamyrCard title="Page Format">
      <List>
        <ListItem>
          <ListItemText secondary="Options" />
          {/* <ListItemText secondary="Enabled" />
          <ListItemText secondary="Preview" /> */}
        </ListItem>
        <Divider />
        {options.map((option: FormatOption) => {
          return (
            <div key={option.title}>
              <ListItem>
                <ListItemText
                  primary={option.title}
                  secondary={option.description}
                />
                <JamyrSwitch
                  label=""
                  onChange={() => onPageSelect(option.id)}
                  checked={selected === option.id}
                />
                {/* <CustomIcon name="preview" /> */}
              </ListItem>
              <Divider />
            </div>
          );
        })}
        {options.filter((option) => option.id === selected)[0].isATS &&
          loaded && (
            <Box sx={{ mt: 2 }}>
              <JamyrMultiSelect
                name="department-list"
                placeholder="Select department to filter jobs"
                options={departments}
                selected={selectedDepts}
                handlechange={onDepartmentSelect}
              />
            </Box>
          )}
      </List>
    </JamyrCard>
  );
};
