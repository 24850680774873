import React from 'react';
import { useLocation, useSearchParams, Navigate } from 'react-router-dom';

export const Redirect = () => {
  const location = useLocation();
  let [params] = useSearchParams();
  const landingpages = [
    '/video/jamone/',
    '/video/jamats/',
    '/video/jamland/',
    '/video/jamtrans/',
  ];
  const collpages = ['/video/video-guide/', '/video/jamcoll/'];
  const embeds = ['/video/jamjobembed/', '/video/jamlandembed/'];
  if (collpages.includes(location.pathname)) {
    return (
      <Navigate to={`/collection/${params.get('collection')}`} replace={true} />
    );
  } else if (landingpages.includes(location.pathname)) {
    return <Navigate to={`/video/${params.get('video')}`} replace={true} />;
  } else if (location.pathname === '/responses/') {
    return (
      <Navigate to={`/campaign/${params.get('campaign')}`} replace={true} />
    );
  } else if (embeds.includes(location.pathname)) {
    return (
      <Navigate to={`/video/embed/${params.get('video')}`} replace={true} />
    );
  }
  return <></>;
};
