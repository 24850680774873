import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { isMobile } from '../../utils/global';
import { JamyrTextField } from '../../atoms';
import { VideoCard, VideoCardList } from '../../components';
import { useTestimonialList } from '../../config/api/testimonials';
import {
  useGlobalState,
  useCurrentCompany,
} from '../../utils/GlobalStateProvider';
import { Testimonial } from '../../types';
import { TestimonialStatus } from '../../config/layouts/selectLists';

export const Studio = (): JSX.Element => {
  const context = useGlobalState();
  const company = useCurrentCompany();
  const testimonials = useTestimonialList(
    { company: company || context.state.company },
    { status: 'approved' }
  );
  const [selections, setSelections] = useState<Testimonial[]>([]);
  const [searchInput, setSearchInput] = useState<string | undefined>();
  const [searchError, setSearchError] = useState<string>('');

  const handleSelectedVideo = (testimonial: Testimonial) => {
    setSelections([...selections, testimonial]);
  };

  const handleSearch = (_: string, value: string) => {
    if (value && value.length <= 3) {
      setSearchError('enter at least 4 characters');
    } else {
      setSearchError('');
      setSearchInput(value.replaceAll("'", ''));
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            ml: isMobile() ? 2 : 10,
            mt: 10,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: 24,
              fontWeight: 'bold',
            }}
          >
            Studio
          </Typography>
          <Grid
            item
            xs={12}
            sx={{ mr: isMobile() ? 2 : 15, ml: isMobile() ? 2 : 5, mt: 5 }}
          >
            <JamyrTextField
              name="search"
              placeholder="Search"
              fullWidth
              changehandler={handleSearch}
            />
            {searchError && (
              <Typography sx={{ color: 'red' }}>{searchError}</Typography>
            )}
          </Grid>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 'bold',
              mt: 4,
            }}
          >
            Select Videos
          </Typography>
          <Box sx={{ width: '95%' }}>
            {testimonials.data?.records && (
              <VideoCardList
                onClick={handleSelectedVideo}
                testimonials={testimonials.data?.records}
              />
            )}
          </Box>
          <Box
            sx={{
              backgroundColor: '',
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'space-between',
              ml: 10,
              mr: 20,
            }}
          >
            {[...Array(5).keys()].map((idx: number) => {
              return (
                <Box key={idx}>
                  {idx <= selections.length - 1 ? (
                    <VideoCard
                      testimonial={selections[idx]}
                      onClick={() => console.log('clicked')}
                    />
                  ) : (
                    <Box
                      sx={{
                        border: '1px dashed black',
                        width: 150,
                        height: 275,
                        maxHeight: 275,
                        borderRadius: 5,
                      }}
                    >
                      {' '}
                      Select Video{' '}
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Button sx={{ minWidth: 200 }}> Create </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
