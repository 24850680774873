import { useEffect, useState } from 'react';

export function useDebounce(disabled = false, delay = 1000) {
  const [value, setValue] = useState<any>(null);
  const [inputValue, setInputValue] = useState<any>(null);

  useEffect(() => {
    if (!disabled) {
      const delayFn = setTimeout(() => setValue(inputValue), delay);
      return () => clearTimeout(delayFn);
    } else {
      setValue(inputValue);
    }
  }, [inputValue, delay]);

  return [value, setInputValue];
}
