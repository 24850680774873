import React, { useState } from 'react';
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { toast } from 'react-toastify';
import {
  CustomIcon,
  Loading,
  JamyrCard,
  JamyrList,
  BoxSelection,
  PopupModal,
} from '../../atoms';
import { Campaign, CampaignIn } from '../../types/campaign';
import { Question, QuestionIn, QuestionLinkIn } from '../../types/testimonial';
import {
  useGlobalState,
  useCurrentCompany,
} from '../../utils/GlobalStateProvider';
import { useNavigate } from 'react-router-dom';
import { CampaignCreate, QuestionCreate } from '../../components';
import { motion } from 'framer-motion';
import { useModal } from 'mui-modal-provider';
import {
  useCampaignList,
  useCampaignGetQuestions,
} from '../../config/api/campaigns';
import {
  useQuestionCreateAndLink,
  useQuestionLinkCreate,
  useQuestionList,
} from '../../config/api/question';

export const CampaignCreateFlow = (): JSX.Element => {
  const context = useGlobalState();
  const company = useCurrentCompany();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [selectedQuestions, setSelectedQuestions] =
    useState<{ [name: string]: string }[]>();
  const [selectedAIQuestions, setSelectedAIQuestions] =
    useState<{ [name: string]: string }[]>();
  const [campaign, setCampaign] = useState<Campaign>();
  const [selectedTemplate, setSelectedTemplate] = useState<number>();
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const { showModal } = useModal();

  const templates = useCampaignList(
    { company: company || context.state.company },
    { q: 'is_template=true' }
  );
  const questions = useQuestionList(
    {
      company: `${company || context.state.company}`,
    },
    {}
  );
  const questionCreateAndLink = useQuestionCreateAndLink({
    company: `${company || context.state.company}`,
    campaign_id: `${campaign?.id}`,
  });
  const questionLinkCreate = useQuestionLinkCreate({
    company: `${company || context.state.company}`,
    campaign: `${campaign?.id}`,
  });
  const questionLinkList = useQuestionList(
    {
      company: `${company || context.state.company}`,
    },
    { campaign: `${selectedTemplate ? selectedTemplate : -1}` }
  );
  const generatedQuestions = useCampaignGetQuestions(
    { company: company || context.state.company, id: campaign?.id },
    {}
  );

  const createQuestion = () => {
    const modal = showModal(PopupModal, {
      children: (
        <QuestionCreate
          onComplete={() => {
            modal.destroy();
            questions.refetch();
          }}
        />
      ),
      size: 'small',
    });
  };

  const handleQuestionSelected = (items: string[] | number[]) => {
    const selected: { [name: string]: string }[] = [];
    if (questions.data) {
      items.forEach((item: string | number) => {
        selected.push(
          questions.data.records.filter((i: Question) => i.id === item)[0]
        );
      });
    }
    setSelectedQuestions(selected);
  };

  const handleAIQuestionSelected = (items: string[] | number[]) => {
    const selected: { [name: string]: string }[] = [];
    if (generatedQuestions.data) {
      items.forEach((item: string | number) => {
        const i = `${item}`;
        selected.push(generatedQuestions.data[parseInt(i)] as any);
      });
    }
    setSelectedAIQuestions(selected);
  };

  const createAndLinkNewQuestion = (text: string, prompt: string) => {
    const customer_id = company || context.state.company;
    const data: QuestionIn = {
      customer_id: `${customer_id}`,
      text: text,
      prompt: prompt || '',
    };
    questionCreateAndLink.mutate(data);
  };

  const createQuestionLinks = () => {
    setIsCreating(true);
    const customer_id = company || context.state.company;
    const selections: { [name: string]: string }[] = selectedTemplate
      ? questionLinkList.data?.records
      : selectedQuestions;
    selections?.forEach((question) => {
      const data: QuestionLinkIn = {
        customer_id: customer_id,
        campaign_id: campaign?.id!,
        question_id: selectedTemplate ? question.question_id : question.id,
      };
      questionLinkCreate.mutate(data);
    });
    selectedAIQuestions?.forEach((question) => {
      createAndLinkNewQuestion(question.text, question.prompt);
    });
    setTimeout(() => {
      toast('🦄 Campaign Created & questions added');
      navigate(`/dashboard/campaigns/${campaign?.id}`);
    }, 3000);
  };

  // Step Content
  const stepContentTitle: { [idx: number]: JSX.Element } = {
    0: (
      <motion.div layout style={{ cursor: step === 1 ? 'pointer' : 'inherit' }}>
        {/* motion.div prevents stretching of text during transition*/}
        <Typography variant="h5">1. Select Type</Typography>
      </motion.div>
    ),
    1: (
      <motion.div layout>
        <Typography variant="h5">2. Add Details</Typography>
      </motion.div>
    ),
    2: (
      <motion.div layout>
        <Typography variant="h5">3. Select Questions</Typography>
      </motion.div>
    ),
  };
  const stepContent: { [idx: number]: JSX.Element } = {
    0: (
      <BoxSelection
        title="1. Select Type"
        onSelect={(value: string) => {
          setSelectedTemplate(parseInt(value));
          setStep(step + 1);
        }}
        options={
          templates.data?.records
            ? [
                ...[
                  {
                    id: '',
                    value: 'Custom Campaign',
                    detail:
                      'Create your own campaign that you can use one time or save as a template',
                  },
                ].concat([
                  ...templates.data?.records.map((c: Campaign) => {
                    return { id: c.id, value: c.title, detail: c.description };
                  }),
                ]),
              ]
            : [
                {
                  id: '',
                  value: 'Custom Campaign',
                  detail:
                    'Create your own campaign that you can use one time or save as a template',
                },
              ]
        }
      />
    ),
    1: (
      <CampaignCreate
        data={
          selectedTemplate
            ? (templates.data?.records.filter(
                (t: Campaign) => t.id === selectedTemplate
              )[0] as CampaignIn)
            : undefined
        }
        onComplete={(data: any) => {
          setCampaign(data as Campaign);
          setStep(step + 1);
        }}
      />
    ),
    2: (
      <>
        <Grid container justifyContent="space-between">
          {isCreating && <Loading />}
          {!isCreating && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5">3. Select Questions</Typography>
                {!selectedTemplate && (
                  <Box display="flex">
                    <Typography sx={{ pr: 1, fontWeight: 'bold' }}>
                      Select questions or create new
                    </Typography>
                    <CustomIcon
                      name="add"
                      sx={{ cursor: 'pointer' }}
                      onClick={createQuestion}
                    />
                  </Box>
                )}
                <JamyrList
                  title=""
                  data={
                    selectedTemplate
                      ? questionLinkList.data?.records || []
                      : questions?.data?.records || []
                  }
                  onItemSelect={handleQuestionSelected}
                  width="80%"
                  searchFilter={true}
                  readOnly={selectedTemplate ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontWeight: 'bold' }}>
                  AI Generated Questions
                  <ClickAwayListener onClickAway={() => setShowToolTip(false)}>
                    <Tooltip
                      onClose={() => setShowToolTip(false)}
                      open={showToolTip}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="Our AI leverages information we have about your company, your campaign title and details, and questions from your question bank to make recommendations for new questions to ask you team."
                    >
                      <IconButton
                        size="small"
                        onClick={() => setShowToolTip(!showToolTip)}
                      >
                        <InfoIcon sx={{ fontSize: 16 }} />
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                </Typography>
                <JamyrList
                  title=""
                  data={
                    generatedQuestions.data
                      ? generatedQuestions.data?.map((q, idx) => {
                          q.id = idx;
                          return q;
                        })
                      : []
                  }
                  onItemSelect={handleAIQuestionSelected}
                  width="80%"
                />
              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => createQuestionLinks()}>Finish</Button>
              </Grid>
            </>
          )}
        </Grid>
      </>
    ),
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            component="h2"
            sx={{
              position: 'relative',
              top: { xs: 10, sm: 30 },
              left: { xs: 20, sm: 40 },
              textAlign: 'left',
              fontWeight: 700,
              fontSize: { xs: 16, sm: 24 },
            }}
          >
            Create Campaign
          </Typography>
          {Object.keys(stepContent).map((val: string, idx: number) => {
            return (
              <motion.div
                key={val}
                layout
                transition={{ layout: { type: 'ease' } }}
                onClick={() =>
                  // allow user to go back to first step before creating campaign
                  idx === 0 && step === 1 ? setStep(0) : undefined
                }
              >
                <JamyrCard
                  title=""
                  children={
                    step === idx ? stepContent[step] : stepContentTitle[idx]
                  }
                  height={step === idx ? '400px' : '100px'}
                />
              </motion.div>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};
