import React, { useState, useEffect } from 'react';
import { Testimonial } from '../types';
import { useTestimonialVersionsGet } from '../config/api/testimonials';
import { Box, Typography } from '@mui/material';
import { VideoCard } from './VideoCard';
import {
  useGlobalState,
  useCurrentCompany,
} from '../utils/GlobalStateProvider';

export interface VideoCardListProps {
  onClick: (testimonial: Testimonial) => void;
  parent?: string;
  testimonials?: Testimonial[];
}

export const VideoCardList = (props: VideoCardListProps) => {
  const context = useGlobalState();
  const company = useCurrentCompany();
  const [videos, setVideos] = useState<Testimonial[]>(props.testimonials || []);
  const versions = useTestimonialVersionsGet(
    { company: company || context.state.company, id: props.parent },
    {}
  );

  useEffect(() => {
    if (versions.data?.records) {
      setVideos(versions.data.records);
    }
  }, [versions.data]);

  return (
    <Box
      sx={{
        display: 'flex',
        overflowY: 'hidden',
        // overflowX: 'scroll',
      }}
    >
      {videos.map((v: Testimonial) => {
        return (
          <VideoCard
            testimonial={v}
            key={v.external_id}
            onClick={props.onClick}
          />
        );
      })}
      {videos.length == 0 && (
        <Typography>No versions of this video have been created</Typography>
      )}
    </Box>
  );
};
