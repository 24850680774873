import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import useAPI from "../../utils/useApi";
import { APIRequests } from "../../config/api";
import { RequestInput } from "../../types/config";
import {
  useGlobalState,
  useCurrentCompany,
} from "../../utils/GlobalStateProvider";
import { isMobile } from "../../utils/global";
import { Typography } from "@mui/material";

export interface TVProps {
  recordid: string;
}

export const TestimonialViews = (props: TVProps) => {
  const context = useGlobalState(true);
  const company = useCurrentCompany();
  const analytics = useAPI<any>(APIRequests.AnalyticsVideoSummary);
  const [chartData, setChartData] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const req: RequestInput = {
      pathParams: {
        company: company || context.state.company,
        id: props.recordid,
      },
    };
    analytics.execute(req);
  }, [context.state.company]);

  useEffect(() => {
    if (analytics.data) {
      console.log(analytics.data);
      setChartData(analytics.data.data);
      const incrementer = setInterval(() => {
        setTotal((c) => {
          if (c < analytics.data.total) return c + 1;
          clearInterval(incrementer);
          return c;
        });
      }, 100);
    }
  }, [analytics.data]);
  return (
    <>
      <Typography variant="h4" sx={{ pl: { xs: 0, sm: 10 }, pb: 3, fontSize: { xs: 18, sm: 36 } }}>
        Total views: {total}
      </Typography>
      {chartData.length > 0 && (
        <ResponsiveContainer width="100%" aspect={isMobile() ? 1 : 3}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="views"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
