import React, { useState, useEffect } from "react";
import useAPI from "../../utils/useApi";
import {
  useGlobalState,
  useCurrentCompany,
} from "../../utils/GlobalStateProvider";
import { APIRequests } from "../../config/api";
import { Form, FieldConfig } from "../../atoms/Form";
import { User } from "../../types/user";
import { RequestInput, CreateFormProps } from "../../types/config";
import { toast } from "react-toastify";

export const UserCreate = (props: CreateFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const context = useGlobalState();
  const company = useCurrentCompany();

  const fields: FieldConfig[] = [
    {
      name: "first_name",
      label: "First Name",
      type: "text",
      placeholder: "eg. Michael",
      validation: { required: true, errormessage: "First name is required" },
    },
    {
      name: "last_name",
      label: "Last Name",
      type: "text",
      placeholder: "eg. Jackson",
      validation: { required: true, errormessage: "Last name is required" },
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      placeholder: "eg. example@address.com",
      validation: { required: true, errormessage: "Email is required" },
      columnSize: 12,
    },
    // { TODO: add back in when we actually use these
    //   name: "scopes",
    //   label: "Permissions",
    //   type: "select",
    //   placeholder: "Choose One",
    //   options: [{ Admin: "admin", User: "user" }]
    // }
  ];
  const user = useAPI(APIRequests.UserCreate);

  useEffect(() => {
    if (user.data) {
      toast("🦄 User Created!");
      props.onComplete();
      setLoading(false);
    }
    if (user.error) {
      toast.error(String(user.error));
      setLoading(false);
    }
  }, [user.data, user.error]);

  const handleSubmit = (data: any) => {
    setLoading(true);
    const userdata = data as User;
    userdata.scopes = ["user_admin"];
    userdata.customer_id = company || context.state.company;
    const req: RequestInput = {
      pathParams: { company: company || context.state.company },
      body: userdata,
    };
    user.execute(req);
  };

  return (
    <Form
      title="Create User"
      submitLabel="Create"
      fields={fields}
      callback={handleSubmit}
      isLoading={loading}
    />
  );
};
