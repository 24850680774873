import React, { useState } from 'react';
import { Form, FieldConfig } from '../../atoms/Form';
import { Customer } from '../../types/user';
import { RequestInput, CreateFormProps } from '../../types/config';
import { toast } from 'react-toastify';

export const EasyApplyExperience = (props: CreateFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);

  const fields: FieldConfig[] = [
    {
      name: 'resume',
      label: 'Upload Resume',
      type: 'upload',
      columnSize: 12,
    },
    {
      name: 'linkedin',
      label: 'LinkedIn',
      type: 'text',
      placeholder: 'linkedin.com/in/firstnameish',
      columnSize: 12,
    },
  ];

  const handleSubmit = (data: any) => {
    setLoading(true);
    props.onComplete();
  };

  return (
    <Form
      title="Add your resume or LinkedIn"
      submitLabel="Submit"
      fields={fields}
      callback={handleSubmit}
      isLoading={loading}
    />
  );
};
