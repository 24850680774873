import React, { useState, useEffect } from 'react';
import useAPI from '../../utils/useApi';
import { APIRequests } from '../../config/api';
import { Form, FieldConfig } from '../../atoms/Form';
import { Customer } from '../../types/user';
import { RequestInput, CreateFormProps } from '../../types/config';
import { toast } from 'react-toastify';

export const EasyApplyCustom = (props: CreateFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);

  const fields: FieldConfig[] = [
    {
      name: 'years_exp',
      label: 'How many years of experience do you have?',
      type: 'text',
      placeholder: 'eg. 4',
      validation: {
        required: true,
        errormessage: 'Years of experience is required',
      },
      columnSize: 12,
    },
    {
      name: 'salary_range',
      label: 'What is your expected salary range?',
      type: 'text',
      placeholder: 'eg. $18.00/hr',
      validation: { required: true, errormessage: 'Salary range is required' },
      columnSize: 12,
    },
    {
      name: 'relocate',
      label: 'Are you willing to relocate?',
      type: 'text',
      placeholder: 'eg. Yes',
      validation: { required: true, errormessage: 'Relocation is required' },
      columnSize: 12,
    },
  ];

  const handleSubmit = (data: any) => {
    setLoading(true);
    props.onComplete();
  };

  return (
    <Form
      title="Tell us more about what you are looking for"
      submitLabel="Continue"
      fields={fields}
      callback={handleSubmit}
      isLoading={loading}
    />
  );
};
