import React, { useEffect, useState } from 'react';
import { Testimonial } from '../types/testimonial';
import { CustomerSetting } from '../types/user';
import { RequestInput } from '../types/config';
import { Box, Typography } from '@mui/material';
import { isMobile, track } from '../utils/global';
import { useParams } from 'react-router-dom';
import useAPI from '../utils/useApi';
import { APIRequests, PublicGet } from '../config/api';
import { JamyrPlayer } from '../components';

const cardStyle = {
  background: 'transparent',
  position: 'relative',
  textDecoration: 'none',
  width: '200px',
  height: '325px',
  maxHeight: '325px',
  borderRadius: '10px',
  boxShadow: 'none',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  margin: isMobile() ? 'auto' : '0px 4px 0px 4px',
  marginBottom: isMobile() ? '40px' : 'auto',
};

export const VideoEmbed = () => {
  const { videoId } = useParams();
  const video = useAPI<PublicGet>(APIRequests.TestimonialGetPublic);
  const [testimonial, setTestimonial] = useState<{
    record: Testimonial;
    settings: CustomerSetting;
  }>();

  useEffect(() => {
    if (videoId) {
      const req: RequestInput = {
        pathParams: { id: videoId, company: 0 },
      };
      video.execute(req);
    }
  }, [videoId]);

  useEffect(() => {
    if (video.data) {
      const t = video.data.data as Testimonial;
      track('page-view', `${t.customer_id}`, { video: t.external_id });
      setTestimonial({ record: t, settings: video.data.settings });
    }
  }, [video.data]);

  const portraitStyle = {
    border: 'none',
    minHeight: '360px',
    maxWidth: '300px',
    height: '400px',
    width: '200px',
  };

  const landscapeStyle = {
    border: 'none',
    maxWidth: '500px',
    minHeight: '300px',
    height: '300px',
    width: '510px',
  };

  return (
    <>
      {testimonial && testimonial.record && (
        <Box sx={cardStyle}>
          <Box
            sx={{
              position: 'absolute',
              borderRadius: '15px 15px 0px 0px',
              height: '30%',
              width: testimonial.record.page_style?.startsWith('jamland')
                ? landscapeStyle.width
                : portraitStyle.width,
              bottom: 'auto',
              backgroundImage:
                'linear-gradient(180deg, rgba(0, 0, 0, 0.7), transparent 76%)',
              color: '#fff',
            }}
          >
            <Typography
              sx={{
                fontSize: 12,
                pl: 1,
                pt: 1,
                borderRadius: '10px',
                fontWeight: 'bold',
              }}
            >
              {testimonial.record.question_text}
            </Typography>
            <Typography
              component="span"
              sx={{
                color: 'white',
                fontSize: 12,
                pl: 1,
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              {testimonial.record.first_name}
            </Typography>
            <Typography
              component="span"
              sx={{
                color: 'white',
                fontSize: 12,
                pl: 1,
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              {` | ${testimonial.record.title}`}
            </Typography>
          </Box>
          <JamyrPlayer
            videoSrc={testimonial.record.final_video}
            videoId={testimonial.record.external_id}
            alt={testimonial.record.question_text}
            customerId={`${testimonial.record.customer_id}`}
            height="auto"
            width={
              testimonial.record.page_style?.startsWith('jamland')
                ? landscapeStyle.width
                : portraitStyle.width
            }
            languages={testimonial?.settings.languages}
          />
        </Box>
      )}
    </>
  );
};
