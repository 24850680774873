import React, { HTMLInputTypeAttribute, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { JamyrTextField } from './JamyrTextField';
import { JamyrSelectField } from './JamyrSelectField';
import { Loading } from './Loading';
import { FileUpload } from '../components/FileUpload';
import { useCurrentCompany } from '../utils/GlobalStateProvider';

export interface FieldValidation {
  errormessage: string;
  required?: boolean;
  min?: string | number;
  max?: string | number;
}

export interface FieldConfig {
  name: string;
  label: string;
  type: HTMLInputTypeAttribute;
  columnSize?: number;
  placeholder?: string;
  validation?: FieldValidation;
  hideLabel?: boolean;
  options?: { [label: string]: string }[];
  helpText?: string;
  checked?: boolean;
}

export interface FormProps {
  title: string;
  submitLabel: string;
  callback: (data: any) => void;
  fields: FieldConfig[];
  isLoading: boolean;
  currentData?: any;
  embedded?: boolean;
}

type ErrorSet = {
  name: string;
  message: string;
};

export const Form = (props: FormProps): JSX.Element => {
  const [errors, setErrors] = useState<ErrorSet[]>([]);
  const [formState, setFormState] = useState<{ [name: string]: string }>({});
  const company = useCurrentCompany();
  const generateError = (field: FieldConfig): ErrorSet => {
    return {
      name: field.name,
      message: field?.validation?.errormessage || '',
    };
  };

  const handleChange = (field: string, value: string) => {
    const data = formState;
    data[field] = value;
    setFormState(data);
  };

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setErrors([]);
    const record: { [name: string]: string } = props.currentData || {};
    let formerrors: ErrorSet[] = [];
    props.fields.forEach((field) => {
      const value =
        field.name in formState
          ? formState[field.name]
          : field.name in record
          ? record[field.name]
          : undefined;
      record[field.name] = value || (field.type === 'checkbox' ? 'false' : '');
      if (field.validation?.required && !value) {
        formerrors.push(generateError(field));
      } else {
        if (value) {
          if (
            field.validation?.min &&
            value.length < Number(field?.validation?.min)
          ) {
            formerrors.push(generateError(field));
          }
          if (
            field.validation?.max &&
            value.length > Number(field?.validation?.max)
          ) {
            formerrors.push(generateError(field));
          }
        }
      }
    });
    if (formerrors.length === 0) {
      props.callback(record);
    } else {
      setErrors(formerrors);
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Grid
        container
        spacing={2}
        sx={{ padding: { xs: props.embedded ? 0 : 5, sm: 5 } }}
      >
        <Grid item xs={12}>
          <Typography
            component="h1"
            sx={{ tabIndex: 0, fontSize: 24, fontWeight: 'bold' }}
          >
            {props.title}
          </Typography>
        </Grid>
        {Object.values(props.fields).map((field: FieldConfig, idx: number) => {
          const fieldError = errors.filter((err) => err.name === field.name);
          return (
            <Grid item xs={12} md={field.columnSize || 6} key={idx}>
              {field.type === 'select' && (
                <>
                  <JamyrSelectField
                    options={field.options}
                    name={field.name}
                    onChange={(data: any) =>
                      handleChange(field.name, data as string)
                    }
                    label={field.hideLabel ? undefined : field.label}
                    placeholder={field.placeholder}
                    aria-label={field.label}
                    sx={{ minWidth: '100%', color: '#717171' }}
                  />
                  {fieldError.length > 0 && (
                    <Typography
                      sx={{ color: 'red', fontSize: 12, textAlign: 'center' }}
                    >
                      {fieldError[0].message}
                    </Typography>
                  )}
                </>
              )}
              {field.type === 'upload' && (
                <>
                  <FileUpload
                    label={field.label}
                    value=""
                    uploadDir={`uploads/direct/${company}`}
                    showSuccess={true}
                    changehandler={(url: string) =>
                      handleChange(field.name, url)
                    }
                    filetypes="video/*"
                    disableDisplay={true}
                  />
                  {fieldError.length > 0 && (
                    <Typography
                      sx={{ color: 'red', fontSize: 12, textAlign: 'center' }}
                    >
                      {fieldError[0].message}
                    </Typography>
                  )}
                </>
              )}
              {(field.type === 'text' || field.type === 'longtext') && (
                <JamyrTextField
                  name={field.name}
                  type={field.type}
                  changehandler={handleChange}
                  label={field.hideLabel ? undefined : field.label}
                  currentvalue={
                    props.currentData && props.currentData[field.name]
                  }
                  placeholder={field.placeholder}
                  aria-label={field.label}
                  error={fieldError.length > 0}
                  helperText={fieldError.length > 0 && fieldError[0].message}
                  sx={{ minWidth: '100%', color: '#717171' }}
                  disableDebounce
                  helpText={field.helpText}
                />
              )}
              {field.type === 'checkbox' && (
                <FormControlLabel
                control={
                  <Checkbox
                  defaultChecked={
                     'checked' in field ? field.checked : ((props.currentData && (props.currentData[field.name])) || false)
                  }
                  />
                }
                label={field.label}
                sx={{ color: '#717171' }}
                //@ts-ignore
                onChange={(e) => handleChange(field.name, e.target.checked)}
                />
              )}
            </Grid>
          );
        })}
        <Grid container item xs={12} justifyContent="center" sx={{ mt: 5 }}>
          {!props.isLoading && (
            <Button
              type="submit"
              sx={{
                borderRadius: '12px',
                backgroundColor: '#5570F1',
                minWidth: '100%',
                py: 1,
                px: 1,
                fontSize: 20,
              }}
            >
              {props.submitLabel}
            </Button>
          )}
          {props.isLoading && <Loading />}
        </Grid>
      </Grid>
    </form>
  );
};
