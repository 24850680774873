import { SCardProps, PageNavItem } from '../../components';

export const brandingNavItems: PageNavItem[] = [
  { title: 'Default', link: 'default' },
  { title: 'Video Widget', link: 'video-widget' },
  { title: 'Landing Pages', link: 'landing-pages' },
  // { title: 'ATS Integration', link: 'ats-integration' },
  { title: 'Transcriptions', link: 'transcriptions' },
  // { title: 'Digital Product', link: 'digital-product' },
];

export const branding: { [name: string]: SCardProps } = {
  Default: {
    title: 'Default',
    colors: [
      // { label: "Navigation", value: settings?.default_bg },
      {
        label: 'Banner',
        code: 'default_bg',
      },
      {
        label: 'Text',
        code: 'default_text_color',
      },
      {
        label: 'Buttons',
        code: 'default_btn_color',
      },
    ],
    uploads: [],
    inputs: [
      {
        label: 'Approved content is visible',
        code: 'site_enabled',
        type: 'boolean',
      },
      {
        label: 'Consent Agreement Link',
        code: 'consent_link',
        type: 'text',
      },
    ],
  },
  'Video Widget': {
    title: 'Video Widget',
    colors: [
      {
        label: 'Banner',
        code: 'gallery_nav_bg',
      },
      {
        label: 'Text',
        code: 'gallery_text_color',
      },
      {
        label: 'Mobile Border',
        code: 'video_mobile_bg',
      },
    ],
    uploads: [
      { label: 'Image', code: 'gallery_logo' },
      {
        label: 'Company Logo',
        code: 'default_company_logo',
      },
    ],
    inputs: [
      {
        label: 'Header',
        code: 'gallery_header',
        type: 'text',
      },
      {
        label: 'Subtext',
        code: 'gallery_text',
        type: 'longtext',
      },
      {
        label: 'Video Card Text',
        code: 'video_card_text',
        type: 'text',
      },
    ],
  },
  'Landing Pages': {
    title: 'Landing Pages',
    colors: [
      {
        label: 'Banner',
        code: 'landing_nav_color',
      },
      {
        label: 'Text',
        code: 'landing_text_color',
      },
      {
        label: 'Button',
        code: 'landing_btn_color',
      },
      {
        label: 'Button Text',
        code: 'landing_btn_txt_color',
      },
    ],
    uploads: [
      {
        label: 'Company Logo',
        code: 'landing_company_logo',
      },
    ],
    inputs: [
      {
        label: 'Button Text',
        code: 'landing_btn_text',
        type: 'text',
      },
      {
        label: 'Button Link',
        code: 'landing_btn_link',
        type: 'text',
      },
      {
        label: 'Subtext',
        code: 'landing_text',
        type: 'longtext',
      },
    ],
  },
  // 'ATS Integration': {
  //   title: 'ATS Integration',
  //   colors: [],
  //   uploads: [],
  //   inputs: [],
  // },
  Transcriptions: {
    title: 'Transcriptions',
    colors: [],
    uploads: [],
    inputs: [],
  },
  // 'Digital Product': {
  //   title: 'Digital Product',
  //   colors: [],
  //   uploads: [],
  //   inputs: [
  //     {
  //       label: 'ATS Public Jobs URL',
  //       code: 'ats_jobs_url',
  //       type: 'text',
  //     },
  //     {
  //       label: 'Host URL Domain',
  //       code: 'digital_domain',
  //       type: 'text',
  //     },
  //   ],
  // },
};
