import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { FileUpload } from './FileUpload';
import { PageFormatSelect } from './PageFormatSelect';
import { TranscriptionUpdate } from './TranscriptionUpdate';
import { Loading, CustomIcon } from '../atoms';
import { Testimonial } from '../types/testimonial';
import { CreateFormProps } from '../types/config';
import useAPI from '../utils/useApi';
import { APIRequests } from '../config/api';
import { RequestInput } from '../types/config';
import { toast } from 'react-toastify';

export const ProcessVideo = (props: CreateFormProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [testimonial, setTestimonial] = useState<Testimonial>(
    props.data as Testimonial
  );
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);
  const testimonialUpdate = useAPI(APIRequests.TestimonialUpdate);
  const filepath =
    window.btoa(testimonial.campaign_id) + '/' + window.btoa(testimonial.email);

  const updateTestimonialField = (field: string, value: string) => {
    const data = testimonial as any;
    data[field] = value;
    setTestimonial(data);
    if (forceUpdate) {
      handleSubmit();
    }
  };

  const onChange = (field: string, value: string) => {
    updateTestimonialField(field, value);
  };

  useEffect(() => {
    if (testimonialUpdate.data) {
      toast('🦄 Video Updated!');
      if (props.onComplete && !forceUpdate) {
        props.onComplete();
      }
      setForceUpdate(false);
    }
    if (testimonialUpdate.error) {
      toast.error(String(testimonialUpdate.error));
    }
  }, [testimonialUpdate.data, testimonialUpdate.error]);

  const handleSubmit = () => {
    const req: RequestInput = {
      pathParams: {
        company: testimonial.customer_id,
        id: testimonial.id,
      },
      body: testimonial,
    };
    testimonialUpdate.execute(req);
  };

  const renderUploadCard = (name: string, label: string, value: string) => {
    console.log(name, label, value);
    return (
      <Grid item xs={3} sx={{ ml: 1, pt: 2 }} container justifyContent="center">
        {value && (
          <Box
            sx={{
              border: 1,
              borderColor: 'info.main',
              borderStyle: 'dashed',
              borderRadius: 3,
              height: 300,
              width: 200,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <Grid xs={12} item container justifyContent="center">
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-end"
                sx={{ position: 'relative', top: 10 }}
              >
                <CustomIcon
                  name="delete"
                  onClick={() => {
                    setForceUpdate(true);
                    updateTestimonialField(name, '');
                  }}
                  sx={{ zIndex: 10 }}
                />
                <Grid item xs={12} sx={{ position: 'relative', top: -10 }}>
                  {name === 'final_video' && (
                    <Typography sx={{ textAlign: 'center' }}>
                      <a href={testimonial.video}>Original Video</a>
                    </Typography>
                  )}
                  <Typography sx={{ textAlign: 'center' }}>
                    <a href={value}>{label}</a>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  sx={{ position: 'relative', top: -10 }}
                >
                  {/* <CheckCircleIcon sx={{ color: "green", fontSize: 40 }} /> */}
                  {value.endsWith('mp4') ? (
                    <video
                      controls={true}
                      src={value}
                      style={{ maxHeight: 200, maxWidth: 200 }}
                    />
                  ) : (
                    <img
                      src={value}
                      alt="video"
                      style={{ maxHeight: 200, maxWidth: 200 }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
        {!value && (
          <FileUpload
            label={label}
            value={value}
            showSuccess={true}
            changehandler={(url: string) => updateTestimonialField(name, url)}
            filetypes={name === 'final_video' ? 'video/*' : 'image/*'}
            disableDisplay={true}
          />
        )}
      </Grid>
    );
  };

  return (
    <Grid container justifyContent="center" alignItems="center" direction="row">
      <Grid item xs={12} container justifyContent="center">
        <Typography variant="h5">Editing Instructions</Typography>
      </Grid>
      <Grid item xs={12} sm={6} container justifyContent="center">
        <Stack>
          <Typography component="p">{`Company: ${testimonial.company_name}`}</Typography>
          <Typography component="p">{`Employee: ${testimonial.first_name} ${testimonial.last_name}`}</Typography>
          <Typography component="p">{`Question: ${testimonial.question_text}`}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} container justifyContent="center">
        <Typography>
          {testimonial.editing_instructions ||
            'Use default video editing instructions'}
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Typography component="p" sx={{ fontSize: 32 }}>
          Video Content
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        container
        justifyContent="center"
        sx={{ minWidth: '100%' }}
      >
        {renderUploadCard('final_video', 'Video', testimonial.final_video)}
        {renderUploadCard('preview', 'GIF', testimonial.preview)}
        {renderUploadCard('thumbnail', 'Image', testimonial.thumbnail)}
      </Grid>
      <Grid item xs={12} sx={{ width: '100%', pt: 5, px: 20 }}>
        <TranscriptionUpdate testimonial={testimonial} />
      </Grid>
      <Grid item xs={12} sx={{ pl: 15 }}>
        <PageFormatSelect testimonial={testimonial} changehandler={onChange} />
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Button type="submit" sx={{ minWidth: '200px' }} onClick={handleSubmit}>
          {!isLoading && 'Submit'}
          {isLoading && <Loading />}
        </Button>
      </Grid>
    </Grid>
  );
};
