import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { HexColorPicker } from "react-colorful";
import { JamyrTextField } from "../atoms/JamyrTextField";

export interface ColorSelectProps {
  title: string;
  code: string;
  onChange: (field: string, value: string) => void;
  color?: string;
}

export const ColorSelect = (props: ColorSelectProps): JSX.Element => {
  const [color, setColor] = useState<string>(props.color || "#FFF");
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const pushColor = (color: string) => {
    setColor(color);
  };
  const handlePickerDisplay = () => {
    setShowPicker(!showPicker);
    props.onChange(props.code, color);
  };

  const handleChange = (field: string, value: string) => {
    setColor(value);
    props.onChange(field, value);
  };

  return (
    <>
      <Typography>{props.title}</Typography>
      <Box
        sx={{
          backgroundColor: color,
          borderColor: "black",
          border: 1,
          borderRadius: 20,
          minWidth: 100,
          minHeight: 20,
          maxWidth: 100,
          maxHeight: 20,
          cursor: "pointer"
        }}
        onClick={() => setShowPicker(true)}
      ></Box>
      {!showPicker && (
        <JamyrTextField
          name={props.code}
          currentvalue={color}
          sx={{ width: "100px", paddingTop: 1 }}
          changehandler={handleChange}
        />
      )}

      {showPicker && (
        <>
          <HexColorPicker color={color} onChange={pushColor} />
          <Button
            onClick={handlePickerDisplay}
            sx={{ backgroundColor: color, maxWidth: 200, maxHeight: 20 }}
          >
            Save
          </Button>
        </>
      )}
    </>
  );
};
