import { SCardProps, PageNavItem } from '../../components';
import { isMobile } from '../../utils/global';

export const collectionNavItems: PageNavItem[] = [
  { title: 'Details', link: 'details' },
  { title: 'Page Format', link: 'format' },
  { title: 'Videos', link: 'videos' },
];

export const collectionCardLayout: { [name: string]: SCardProps } = {
  Details: {
    title: 'Details',
    colors: [],
    uploads: [],
    inputs: [
      {
        label: 'Collection Name',
        code: 'title',
        type: 'text',
      },
      {
        label: 'Collection Description',
        code: 'heading_text',
        type: 'longtext',
      },
      {
        label: 'CTA Title',
        code: 'cta_title',
        type: 'text',
      },
      {
        label: 'Primary CTA Text',
        code: 'cta_btn_text',
        type: 'text',
        gridSize: isMobile() ? 12 : 6,
        characterLimit: 32,
      },
      {
        label: 'Primary CTA Link',
        code: 'cta_link',
        type: 'text',
        gridSize: isMobile() ? 12 : 6,
      },
      {
        label: 'Secondary CTA Text',
        code: 'secondary_cta_btn_text',
        type: 'text',
        gridSize: isMobile() ? 12 : 6,
        characterLimit: 32,
      },
      {
        label: 'Secondary CTA Link',
        code: 'secondary_cta_link',
        type: 'text',
        gridSize: isMobile() ? 12 : 6,
      },
      {
        label: 'Hide Question Text',
        code: 'hide_question_text',
        type: 'boolean',
        gridSize: isMobile() ? 12 : 6,
      },
    ],
  },
  'Page Format': {
    title: 'Page Format',
    colors: [],
    uploads: [],
    inputs: [],
  },
  Videos: {
    title: 'Videos',
    colors: [],
    uploads: [],
    inputs: [],
  },
};
