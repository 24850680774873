import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { IconButton, ListItem, ListItemText } from '@mui/material';
import { CustomIcon } from './CustomIcon';
import { JamyrTextField } from './JamyrTextField';

export type DraggableListItemProps = {
  item: { [name: string]: string } | any;
  index: number;
  onItemRemove: (idx: number) => void;
  onItemEdit?: (item: { [name: string]: string } | any) => void;
  onVideoEdit?: (item: { [name: string]: string } | any) => void;
};

const DraggableListItem = ({
  item,
  index,
  onItemRemove,
  onItemEdit,
  onVideoEdit,
}: DraggableListItemProps) => {
  const [editPrompt, setEditPrompt] = React.useState<boolean>(false);
  const promptUpdate = (_: string, value: string) => {
    let i = item;
    i.detail = value;
    if (onItemEdit) {
      onItemEdit(i);
    }
  };
  return (
    <Draggable draggableId={item.value} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          key={`${item.value}-${index}`}
          disablePadding
          divider
          secondaryAction={
            <>
              <IconButton aria-label="edit">
                {onItemEdit && (
                  <CustomIcon
                    name="edit"
                    onClick={() => setEditPrompt(!editPrompt)}
                  />
                )}
              </IconButton>
              <IconButton aria-label="edit">
                {onVideoEdit && (
                  <CustomIcon name="edit" onClick={() => onVideoEdit(item)} />
                )}
              </IconButton>
              <IconButton aria-label="delete">
                <CustomIcon
                  name="delete"
                  onClick={() => onItemRemove(item.id)}
                />
              </IconButton>
            </>
          }
          sx={{
            background: snapshot.isDragging
              ? 'linear-gradient(146deg, rgba(249, 225, 255, 0.4) 17%, rgba(225, 244, 255, 0.5))'
              : 'white',
          }}
          className={snapshot.isDragging ? '' : ''}
        >
          {!editPrompt && (
            <ListItemText
              id={item.id}
              primary={item.value}
              secondary={item.detail}
              sx={{ maxWidth: '90%' }}
            />
          )}
          {editPrompt && (
            <JamyrTextField
              name="prompt"
              placeholder="Add a prompt"
              currentvalue={item.detail}
              sx={{ width: '70%' }}
              changehandler={promptUpdate}
            />
          )}
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
