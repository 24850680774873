import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  InputLabel,
  TextField,
  TextFieldProps,
  Typography,
  TextareaAutosize,
  Popover,
  ClickAwayListener,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { useDebounce } from '../utils/useDebounce';
export type JTextFieldProps = TextFieldProps & {
  currentvalue?: string;
  changehandler?: (field: string, value: string) => void;
  reset?: boolean;
  disableDebounce?: boolean;
  inputHeight?: string;
  expandable?: boolean;
  helpText?: string;
  characterLimit?: number;
};
export const JamyrTextField = (props: JTextFieldProps): JSX.Element => {
  const [fieldValue, setFieldValue] = useState<string>(
    props.currentvalue || ''
  );
  const [value, setInputValue] = useDebounce(props.disableDebounce);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const showToolTip = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (value !== null && props.changehandler && props.name) {
      props.changehandler(props.name, value || '');
    }
  }, [value]);

  useEffect(() => {
    if (props.reset) {
      setFieldValue('');
    }
  }, [props.reset]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputValue(e.target.value);
    setFieldValue(e.target.value);
  };
  return (
    <>
      <InputLabel htmlFor={props.id}>
        <Typography
          sx={{
            fontSize: 16,
            textAlign: 'left',
          }}
        >
          {props.label}
          {props.helpText && (
            <ClickAwayListener onClickAway={handlePopoverClose}>
              <>
                <IconButton
                  size="small"
                  onClick={() =>
                    showToolTip ? handlePopoverClose : handlePopoverOpen
                  }
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  aria-owns={showToolTip ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                >
                  <InfoIcon sx={{ fontSize: 16 }} />
                </IconButton>
                <Popover
                  id="mouse-over-popover"
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={showToolTip}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Box
                    sx={{
                      borderRadius: 1,
                      backgroundColor: 'rgba(0,0,0,0.3)',
                      minHeight: 100,
                      maxWidth: 400,
                      p: 2,
                    }}
                  >
                    <Typography>{props.helpText}</Typography>
                  </Box>
                </Popover>
              </>
            </ClickAwayListener>
          )}
        </Typography>
      </InputLabel>
      {!props.expandable && (
        <TextField
          {...props}
          label=""
          value={fieldValue}
          multiline={props.type === 'longtext'}
          minRows={3}
          maxRows={5}
          onChange={handleInputChange}
          InputLabelProps={{
            style: { display: props.placeholder ? 'block' : 'none' },
            shrink: false,
          }}
          InputProps={{
            style: {
              borderColor: 'grey',
              borderRadius: 8,
            },
          }}
          inputProps={{
            maxLength: props.characterLimit,
            style: {
              border: 0,
              fontSize: 16,
              maxHeight:
                !props.inputHeight && props.type === 'longtext' ? 100 : 12,
              minHeight: props.inputHeight && props.inputHeight,
            },
          }}
        />
      )}
      {props.expandable && (
        <TextareaAutosize
          name={props.name}
          value={fieldValue}
          minRows={3}
          maxRows={10}
          onChange={handleInputChange}
          style={{ width: '100%' }}
        />
      )}
    </>
  );
};
